import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot, NavigationEnd, UrlTree } from '@angular/router';
import { AuthenticationService } from '../shared/service/authentication.service';
import { AlertService } from '../shared/service/alert.service';
import { MatDialog } from '@angular/material';
import { PageAuthenticationDialogComponent } from '../content/master/permission-master/page-authentication-dialog/page-authentication-dialog.component';
import { CommonService } from '../shared/service/common.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuardService implements CanActivate {
  defaultRedirectUrl = '/dashboard';

  constructor(private readonly _authService: AuthenticationService,
    private readonly _alertService: AlertService,
    private readonly _dialog: MatDialog,
    private readonly _router: Router,
    private readonly _commonService: CommonService) {
  }


  // canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
  //   if (state.url !== this.defaultRedirectUrl) {
  //     const isAccessible = true;  //this._authService.IsAccessibleUrl(state.url);
  //     if (isAccessible) {
  //       return true;
  //     } else {
  //       this._alertService.error("Access Denied!!");
  //       this._router.navigate([this.defaultRedirectUrl]);
  //       return false;
  //     }
  //   } else {
  //     return true;
  //   }
  // }


  // canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
  //   if (this._authService.IsAuthentication)
  //     return true;
  //   this._router.navigate(['login']);
  //   return false;
  // }
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
    | boolean {
    if (this._authService.IsAuthentication) {
      let urls = route.url.join('/');

      let per = this._authService.LoggedInUserDetail.AssignedUserPermissionViewModelList.filter(x => (x.PermissionUrl == urls || x.PermissionUrl == state.url) || (x.PermissionUrl.includes(route.routeConfig.path.split('/:')[0])));

      if (per.length > 0 && this._commonService.GetSessionStorage("AuthPagePermission") != undefined && this._commonService.GetSessionStorage("AuthPagePermission") == state.url) {
        this._commonService.RemoveSessionStorage("AuthPagePermission");

        return true;
      } else {

        if (per.length > 0 && per[0].HasExtraAuthentication) {

          this._dialog.open(PageAuthenticationDialogComponent, {
            width: "500px",
            height: "250px",
            data: { Id: per[0].PermissionCode },
            disableClose: true
          }).afterClosed().toPromise().then((result: boolean) => {

            if (result) {
              this._commonService.SetSessionStorage("AuthPagePermission", state.url);

              this._router.navigate([state.url]);
              return true;
            }
            else {
              this._router.navigate(['dashboard']);
              return false;
            }
          });
        }
        else if (per.length > 0 && !per[0].HasExtraAuthentication) {
          return true;
        }
        else {
          // return false;
          return true;
        }
        //else { return true; }
      }
    }
    else {
      this._router.navigate(['login']);
      return false;
    }
  }

  verifyPass(permissionCode): any {
    this._dialog.open(PageAuthenticationDialogComponent, {
      width: "500px",
      height: "250px",
      data: { Id: permissionCode },
      disableClose: true
    }).afterClosed().toPromise().then((result: boolean) => {

      if (result) {

        // this._router.navigate([urls]);
        // this._router.navigate([state.url]);
        return true;
      }
      else {
        this._router.navigate(['dashboard']);
        return false;
      }
    });

  }


  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): any {
    return this.canActivate(route, state);
  }

}
