import { Component, OnInit } from '@angular/core';
import { MatDialog, MatTableDataSource } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { ActionHistoryPopupComponent } from 'src/app/content/communication/action-history-popup/action-history-popup.component';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DDLModel, DdlItemModel } from 'src/app/shared/model/commonddl.model';
import { ProjectWiseCommunicationFilterModel } from 'src/app/shared/model/report/project-wise-communication-filter.model';
import { ProjectWiseCommunicationListViewModel } from 'src/app/shared/model/report/project-wise-communication-list-view.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ProjectWiseCommunicationReportService } from 'src/app/shared/service/report/project-wise-communication-report.service';

@Component({
  selector: 'app-project-wise-communication-report',
  templateUrl: './project-wise-communication-report.component.html',
  styleUrls: ['./project-wise-communication-report.component.scss'],
  providers: [ProjectWiseCommunicationReportService]
})
export class ProjectWiseCommunicationReportComponent implements OnInit {
  public ddlList: DDLModel;
  public userTypeEnum = UserTypeEnum;
  public loginUserDetail: UserViewModel;
  public filterModel: ProjectWiseCommunicationFilterModel = new ProjectWiseCommunicationFilterModel();
  public model: ProjectWiseCommunicationListViewModel[];
  public dataSource: any;
  public totalRecords: number;
  public displayedColumns: string[] = [];
  public columnsToDisplay: string[] = [];
  public loginBaseUserType: string;
  public loginData: UserViewModel;
  public permission: PermissionModel = this._authService.GetPagePermission("/reports/project-wise-communication-report", "", "/reports/project-wise-communication-report/detail", "", "");

  ViewdisplayedColumns: { Text: string, Value: string }[] = [

    { Text: 'Communication Name', Value: 'CommunicationName' },

    { Text: 'Project Name', Value: 'ProjectName' },

    { Text: 'Communication Type Name', Value: 'CommunicationTypeName' },


  ];

  public SearchdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "CommunicationTypeOption", Text: "Communication Type Option" }
  ];

  constructor(
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _projectWiseCommunicationService: ProjectWiseCommunicationReportService,
    private readonly _authService: AuthenticationService,
    private _dialog: MatDialog,
    private readonly appComponnet: AppComponent,
  ) {
    this.displayedColumns = ["index",
      "CommunicationName",
      "ProjectName",
      "CommunicationTypeName",
      "Action"
    ];
    this.ddlList = new DDLModel();
    this.columnsToDisplay = this.displayedColumns.slice();
    this.loginBaseUserType = _authService.BaseUserType;
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    this.appComponnet.setPageLayout("Project Wise Communication Report :", "", "", "");
    this.loginData = _authService.GetCurrentUserDetail().UserViewModel;
  }

  ngOnInit() {
    this.getDropdownList();
    this.getProjectList();
    this.getList();
  }

  //#region Public Method

  public onSearch() {
    this.getList();
  }

  public onReset() {
    this.filterModel = new ProjectWiseCommunicationFilterModel();
    this.filterModel.ProjectCode = 0;
    this.getList();
    this.getProjectList();
  }

  public OpenDialog(communicationCode: any, enumValue: any) {

    const _dialogRef = this._dialog.open(ActionHistoryPopupComponent, {
      width: "1000px",
      height: "1000px",
      data: { communicationCode },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {

      if (result) {
        this.getList();
      }
    });
  }

  public getProjectDDLList() {

    this._commonService.GetDDL(AppSetting.ddlProjectWiseCommunication, this.filterModel.CommunicationTypeOption).subscribe(
      data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlProjectWiseCommunication = ddl.ddlProjectWiseCommunication;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  //#endregion Public Method

  //#region Private Method

  private getList() {

    this._projectWiseCommunicationService.GetProjectWiseCommunicationReport(this.filterModel).subscribe(
      data => {
        if (data.Data && data.Data.Data) {

          this.model = <ProjectWiseCommunicationListViewModel[]>data.Data.Data;
          this.dataSource = new MatTableDataSource<ProjectWiseCommunicationListViewModel>(this.model);
          this.totalRecords = data.Data.Data.length;
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  private getProjectList() {
    this._commonService.GetDDL(AppSetting.ddlProjectWiseCommunication, 0, this.loginData.UserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlProjectWiseCommunication = ddl.ddlProjectWiseCommunication;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  private getDropdownList() {

    this._commonService.GetAllDDL(AppSetting.CommunicationTypeDropdownKeys).subscribe(
      data => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;

          if (this.ddlList) {

            this.ddlList.ddlUserType = this.ddlList.ddlUserType.filter(i => i.Value == UserTypeEnum.DREC || i.Value == UserTypeEnum.VNDR || i.Value == UserTypeEnum.HOD);

            this.ddlList.ddlUserType.map(item => {
              item.Text = (item.Value == this.userTypeEnum.DREC ? ("Department to Vendor") : (item.Value == this.userTypeEnum.VNDR ? "Vendor to Department" : "Department to Department"));
            });

            if (this.loginUserDetail.BaseUserType != UserTypeEnum.VNDR.toString() && this.loginUserDetail.BaseUserType != UserTypeEnum.VNDRUSER.toString()) {
              let newItem = new DdlItemModel();
              newItem.Text = "Internal to Department";
              newItem.Value = this.userTypeEnum.HOO;
              this.ddlList.ddlUserType.push(newItem);
            }
          }

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  //#endregion Private Method

}
