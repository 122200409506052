
export class SearchCriteriaModel {
  FromDate: string = '';
  ToDate: string = '';
  AdminDepartments: string[];
  Departments: string[];
  Districts: string[];
  Offices: string[];
  Vendors: string[];
  Projects: string[];
  ProjectsOIC: string[];
  UserTypes: string[];
  Users: string[];
  WorkOrderCodes: string[];
  CommunicationInitiatedBy: string;
  CommunicationTypes: string[];
  Status: string[];

  AdminDepartmentNames: string = '';
  DepartmentNames: string = '';
  DistrictNames: string = '';
  OfficeNames: string = '';
  VendorNames: string = '';
  ProjectNames: string = '';
  ProjectsOICName: string = '';
  UserTypeNames: string = '';
  UserNames: string = '';
  WorkOrderNames: string = '';
  CommunicationInitiatedByName: string = '';
  CommunicationTypeNames: string = '';
  StatusName: string = '';
}


export class StatisticalFilterModel {
  DataSource: string;
  ColumnValue: string;
  RowValue: string;
  FromDate: string;
  ToDate: string;
  AdminDepartments: string;
  Departments: string;
  Districts: string;
  Offices: string;
  Vendors: string;
  Projects: string;
  ProjectsOIC: string;
  UserTypes: string;
  Users: string;
  WorkOrderCodes: string;
  CommunicationInitiatedBy: string;
  CommunicationTypes: string;
  Status: string;
}


export class SearchCriteriaUIManagementModel {
  isSearchClick: boolean = false;
  isDepartmentLock: boolean = false;
  isOfficeLock: boolean = false;
  isVendorLock: boolean = false;
  DistrictColor: string = '';
  DepartmentColor: string = '';
  OfficeColor: string = '';
  VendorColor: string = '';
  ProjectColor: string = '';
  ProjectOICColor: string = '';
  WorkTypeColor: string = '';
  UserColor: string = '';
  CommunicationTypeColor: string = '';
}

export class SearchCriteriaDepartmentFilterModel {
  AdminDepartments: string;
  Offices: string;
  Vendors: string;
  Projects: string;
  constructor(adminDepartments, offices, vendors, projects) {
    this.AdminDepartments = adminDepartments;
    this.Offices = offices;
    this.Vendors = vendors;
    this.Projects = projects;
  }
}

export class SearchCriteriaProjectFilterModel {
  Offices: string;
  Departments: string;
  Districts: string;
  Vendors: string;
  constructor(offices, departments, districts, vendors) {
    this.Offices = offices;
    this.Departments = departments;
    this.Districts = districts;
    this.Vendors = vendors;
  }
}

export class SearchCriteriaOfficeFilterModel {
  Departments: string;
  District: string;
  Vendor: string;
  constructor(departments, district, vendor) {
    this.Departments = departments;
    this.District = district;
    this.Vendor = vendor;
  }
}

export class SearchCriteriaDistrictFilterModel {
  Departments: string;
  Offices: string;
  constructor(departments, offices) {
    this.Departments = departments;
    this.Offices = offices;
  }
}

export class SearchCriteriaWorkOrderFilterModel {
  Projects: string;
  Offices: string;
  constructor(projects, offices) {
    this.Projects = projects;
    this.Offices = offices;
  }
}

export class SearchCriteriaVendorFilterModel {
  Departments: string;
  Districts: string;
  Projects: string;
  Office: string;
  constructor(departments, districts, projects, office) {
    this.Departments = departments;
    this.Districts = districts;
    this.Projects = projects;
    this.Office = office;
  }
}

export class SearchCriteriaUserFilterModel {
  Departments: string;
  Districts: string;
  UserType: string;
  Offices: string;

  constructor(departments, districts, userType, offices) {
    this.Departments = departments;
    this.Districts = districts;
    this.UserType = userType;
    this.Offices = offices;
  }
}
