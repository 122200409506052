import { Injectable } from '@angular/core';
import { ProjectWiseCommunicationFilterModel } from '../../model/report/project-wise-communication-filter.model';
import { AppSetting } from '../../model/appsetting.model';
import { BaseService } from '../base.service';

@Injectable()
export class ProjectWiseCommunicationReportService {


  constructor(
    private readonly _baseService: BaseService
  ) { }

  GetProjectWiseCommunicationReport(model: ProjectWiseCommunicationFilterModel) {
    return this._baseService.post(AppSetting.GetProjectWiseCommunicationReport, model);
  }

}
