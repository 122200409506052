import { Component, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { mode } from 'crypto-js';
import { AppComponent } from 'src/app/app.component';
import { PermissionModel } from 'src/app/shared/model/common.model';
import { FileUploaderModel } from 'src/app/shared/model/file-uploader.model';
import { ChiefMinisterDocumentModel } from 'src/app/shared/model/master/chief-minister-profile/chief-minister-document.model';
import { ChiefMinisterProfileUpdateRequestModel } from 'src/app/shared/model/master/chief-minister-profile/chief-minister-profile-update-request.model';
import { ChiefMinisterProfileModel } from 'src/app/shared/model/master/chief-minister-profile/chief-minister-profile.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ChiefMinisterProfileService } from 'src/app/shared/service/master/chiefminister-profile.service';

@Component({
  selector: 'app-add-update-chiefminister-profile',
  templateUrl: './add-update-chiefminister-profile.component.html',
  styleUrls: ['./add-update-chiefminister-profile.component.scss'],
  providers: [ChiefMinisterProfileService]
})
export class AddUpdateChiefMinisterProfileComponent implements OnInit {

  private id: number = 0;
  private codeOfConductFromDateMinValue: Date = new Date();
  public file: FileUploaderModel[] = [new FileUploaderModel()];
  public fileDetails: FileUploaderModel | null;
  public filePathUrl: string | null = "";
  public isFileDeleted: boolean = false;
  public model: ChiefMinisterProfileUpdateRequestModel = new ChiefMinisterProfileUpdateRequestModel();
  public PDFFile: SafeResourceUrl;
  public fileValidationMsg: string = "";

  public Permission: PermissionModel = this._authService.GetPagePermission("/master/chief-minister-profile", "chief-minister-profile/add", "chief-minister-profile/detail", "chief-minister-profile/update");

  constructor(
    private readonly _chiefMinisterProfileService: ChiefMinisterProfileService,
    private readonly _appComponnet: AppComponent,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _parentApi: AppComponent,
    private sanitizer: DomSanitizer,

  ) {
    this._appComponnet.setPageLayout("Chief Minister Profile :", "keyboard_backspace", "Back", "master/chief-minister-profile");
  }

  public ngOnInit() {
    this.id = Number(this._activatedRoute.snapshot.params.id);

    if (this.id > 0) {
      this.getById(this.id);
    }
  }

  //#region Properties
  get Form() {
    return this._chiefMinisterProfileService.Form;
  }

  get IsCodeOfConduct() {
    return this._chiefMinisterProfileService.IsCodeOfConduct;
  }

  get CodeOfConductFromDate() {
    return this._chiefMinisterProfileService.CodeOfConductFromDate;
  }

  get CodeOfConductToDate() {
    return this._chiefMinisterProfileService.CodeOfConductToDate;
  }

  get CodeOfConductFromDateMinValue() {
    return this.codeOfConductFromDateMinValue;
  }

  get PdfFIleName() {
    return this._chiefMinisterProfileService.PdfFIleName;
  }
  //#endregion Properties

  //#region Events
  public onSubmit() {

    this.Form.markAllAsTouched();

    if (this.Form.invalid) {
      return;
    }

    const model = this.getUpdateRequestModel();

    this.update(model);
  }



  public onIsCodeOfConductChange() {

    let isCodeOfConduct = Boolean(this.IsCodeOfConduct.value);

    if (isCodeOfConduct == true) {
      return;
    }

    this.CodeOfConductFromDate.setValue("");
    this.CodeOfConductFromDate.updateValueAndValidity();

    this.CodeOfConductToDate.setValue("");
    this.CodeOfConductToDate.updateValueAndValidity();
  }

  public downloadPdf(Url, name) {
    const linkSource = Url;
    const downloadLink = document.createElement("a");
    const fileName = name;

    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.target = "blank";
    downloadLink.click();
  }

  public onFileSelected(files: FileUploaderModel[]) {
    this.fileDetails = null;

    if (files && files.length > 0) {
      this.isFileDeleted = false;
      this.fileDetails = files[0];
    }
  }

  public getDeleteStatus(status: boolean) {
    this.isFileDeleted = status;
  }

  public handleFileInput(event: any) {

    if (event.target.files.item(0).type.match("application/pdf")) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(
          event.target.result
        );
        this.model.PdfFIleName = event.target.result;
      };
      reader.readAsDataURL(event.target.files.item(0));
      this.fileValidationMsg = "";
    } else if (event.target.files.item(0).type.match("application/x-zip-compressed")) {
      var reader = new FileReader();
      reader.onload = (event: any) => {
        this.PDFFile = this.sanitizer.bypassSecurityTrustResourceUrl(
          event.target.result
        );
        this.model.PdfFIleName = event.target.result;
      };
      reader.readAsDataURL(event.target.files.item(0));
      this.fileValidationMsg = "";
    }
    else {
      this.fileValidationMsg = "only *pdf file accepted ";
      this.model.PdfFIleName = undefined;
    }

  }

  public removePDFFile() {
    this.model.PdfFIleName = undefined;
  }
  //#endregion Events

  //#region Private Methods
  private getById(id: number) {
    this._chiefMinisterProfileService.getById(id, true).subscribe({
      next: response => {
        this.updateFormValue(response.Data);
      },
      error: error => console.log(error.error.error)
    })
  }

  private updateFormValue(model: ChiefMinisterProfileModel) {
    let codeOfConductFromDate = model.CodeOfConductFromDate == "" ? "" : new Date(model.CodeOfConductFromDate);
    let codeOfConductToDate = model.CodeOfConductToDate == "" ? "" : new Date(model.CodeOfConductToDate);

    this.IsCodeOfConduct.setValue(model.IsCodeOfConduct);
    this.IsCodeOfConduct.updateValueAndValidity();

    this.CodeOfConductFromDate.setValue(codeOfConductFromDate);
    this.CodeOfConductFromDate.updateValueAndValidity();

    this.CodeOfConductToDate.setValue(codeOfConductToDate);
    this.CodeOfConductToDate.updateValueAndValidity();

    if (model.PdfFIleName && model.PdfFIleName !== "") {
      this.PdfFIleName.setValue(model.PdfFIleName);
      this.model.PdfFIleName = model.PdfFIleName;
    }
  }

  private getUpdateRequestModel(): ChiefMinisterProfileUpdateRequestModel {
    const output = new ChiefMinisterProfileUpdateRequestModel();

    output.Id = this.id;
    output.IsCodeOfConduct = this.IsCodeOfConduct.value;
    output.CodeOfConductFromDate = this.CodeOfConductFromDate.value == "" ? "" : `${this.CodeOfConductFromDate.value.getFullYear()}-${this.CodeOfConductFromDate.value.getMonth() + 1}-${this.CodeOfConductFromDate.value.getDate()}`;
    output.CodeOfConductToDate = this.CodeOfConductToDate.value == "" ? "" : `${this.CodeOfConductToDate.value.getFullYear()}-${this.CodeOfConductToDate.value.getMonth() + 1}-${this.CodeOfConductToDate.value.getDate()}`;
    output.PdfFIleName = this.model.PdfFIleName;

    return output;
  }


  private update(model: ChiefMinisterProfileUpdateRequestModel) {
    this._chiefMinisterProfileService.update(model).subscribe({
      next: response => {
        if (response.IsSuccess == true) {
          this._router.navigate(['/master', 'chief-minister-profile']);
        }
      }
    })
  }
  //#endregion Private Methods
}
