import { environment } from "src/environments/environment";

export class AppSetting {
  //#region  <Common>
  static BaseApiUrl = environment.ApiBaseUrl;
  static ReadApiUrl = environment.ReadApiBaseUrl;
  static orderByDscAsc = "asc";
  static orderByAsc = 1;
  static orderByDsc = 0;

  static FileSizeLimit = {
    PDF: 5000000,
    PDFErrorMSG: "File size must be less than 5 MB...!",
    IMG: 5000000,
    IMGErrorMSG: "File size must be less than 5 MB...!",
    DEFAULT: 5000000,
    DEFAULTErrorMSG: "File size must be less than 5 MB...!",
  }

  static DateFormat = "MM/dd/yyyy";
  static editorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    width: 'auto',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' }
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText'
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['bold', 'italic'],
      ['fontSize']
    ]
  }
  static GetDDlUrl = AppSetting.BaseApiUrl + "api/Common/GetAllDropDown?keys=";
  static GetFilterSSOIDsUrl =
    AppSetting.BaseApiUrl + "api/Common/GetFilterSSOIds";
  static GetAllSSOIDByDepartmentUserTypeUrl =
    AppSetting.BaseApiUrl + "api/Common/GetAllSSOIDByDepartment_UserType";
  static GetProjectByDepartment =
    AppSetting.BaseApiUrl + "api/Common/GetProjectByDepartment?codes=";
  static GetSingleDDlUrl =
    AppSetting.BaseApiUrl + "api/Common/GetDropDown";
  static GetDepartmentDistrictListUrl =
    AppSetting.BaseApiUrl + "api/Common/GetDepartmentDistrictList?Code=";
  static GetMultipleDDlUrl =
    AppSetting.BaseApiUrl + "api/Common/GetDropDownMultiple?key=";
  static GetDepartmentsByAdminDepartment =
    AppSetting.BaseApiUrl + "api/Common/GetDepartmentsByAdminDepartment";
  static GetOfficesBy =
    AppSetting.BaseApiUrl + "api/Common/GetOfficesBy";

  static GetUserByUserTypeUrl = AppSetting.BaseApiUrl + "api/Common/GetUserByUserType";
  static GetUserByUserTypeAndOfficeCode = AppSetting.BaseApiUrl + "api/Common/GetUserByUserTypeAndOfficeCode";
  static GetFilterdDDlUrl = AppSetting.BaseApiUrl + "api/Common/GetAllDropDown";
  static DefaultProfilePic = "assets/images/profile.png";
  static DefaultStartDate: Date = new Date("2018-12-17T00:00:00");
  static DefaultEndDate: Date = new Date();
  static DDlKeyForUserType = "ddlUserTypetemplogin,ddlComplaintType,ddlOfficeDepartment,ddlDistrict,ddlOfficeByLoginUserId,ddlDepartmentByLoginUserId";
  static DDLKeyForTempLogin = "ddlRajDistrict,ddlDepartmentByLoginUserId,ddlOfficeByLoginUserId,ddlUserTypetemplogin"

  static GetUserByDepartmentUrl = AppSetting.BaseApiUrl + "api/Common/GetUserByDepartment?departmentCode=";
  //#endregion

  //#region  <Dropdown Enum>
  static ddlBankBranch = "ddlBankBranch";
  static ddlBank = "ddlBank";
  static ddlDepartmentOffice = "ddlDepartmentOffice";
  static ddlDepartmentOfficeHavingStaff = "ddlDepartmentOfficeHavingStaff";
  static ddlOfficeDistrict = "ddlOfficeDistrict";
  static ddlAllOfficeDepartment = "ddlAllOfficeDepartment";
  static ddlOfficeByLoginUserId = "ddlOfficeByLoginUserId";
  static ddlOfficeHavingStaffByLoginUserId = "ddlOfficeHavingStaffByLoginUserId";
  static ddlProjectSubCategory = "ddlProjectSubCategory";
  static ddlCommunicationType = "ddlCommunicationType";
  static ddlCommunicationAttachmentType = "ddlCommunicationAttachmentType";
  static ddlOfficeReceptionUsers = "ddlOfficeReceptionUsers";
  static ddlOfficeStaff = "ddlOfficeStaff";
  static ddlProject = "ddlProject";
  static ddlOfficeProject = "ddlOfficeProject"
  static ddlWorkOrder = "ddlWorkOrder";
  static ddlDistrict = "ddlDistrict";
  static ddlVendorBank = "ddlVendorBank";
  static ddlVendorBankAccount = "ddlVendorBankAccount";
  static ddlVendorUser = "ddlVendorUser";
  static ddlAllOfficeDistrict = "ddlAllOfficeDistrict";
  static ddlAllOffice = "ddlAllOffice";
  static ddlProjectWiseCommunication = "ddlProjectWiseCommunication";

  //#endregion

  //#region Project Mapping
  static ProjectMappingListUrl = AppSetting.ReadApiUrl + "admin/ProjectMapping/Get";
  static ProjectAssignUrl = AppSetting.BaseApiUrl + "admin/ProjectMapping/AssignProject";
  static ProjectMappingKeys = "ddlUserType"
  static ProjectMappingDetailUrl = AppSetting.ReadApiUrl + "admin/ProjectMapping/Get/";
  //#endregion

  //#region  <user>
  static UserListUrl = AppSetting.BaseApiUrl + "admin/User/Get";
  static OfficeStaffUserListUrl =
    AppSetting.BaseApiUrl + "admin/User/GetOfficeStaff";
  static UserAddUpdateUrl = AppSetting.BaseApiUrl + "admin/User/Post";
  static B2GUserCreationUrl = AppSetting.BaseApiUrl + "admin/User/B2GUserCreation";
  static LoginUserCreationAddUpdateUrl = AppSetting.BaseApiUrl + "admin/User/AddUpdateLoginUserCreation";
  static SelfRegisterUrl = AppSetting.BaseApiUrl + "admin/Account/SelfRegister";
  static GetApproverUsersUrl = AppSetting.BaseApiUrl + "admin/Account/GetContactUserForRegApproval";

  static UserDetailUrl = AppSetting.BaseApiUrl + "admin/User/Get/";
  static B2GUserByIdUrl = AppSetting.BaseApiUrl + "admin/User/GetB2GUserById/";
  static LocateUserUrl = AppSetting.BaseApiUrl + "admin/User/LocateUser/";

  static UserDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/User/UpdateDeleteStatus/";
  static UserActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/User/UpdateActiveStatus/";
  static UserSSOIDExistUrl =
    AppSetting.BaseApiUrl + "admin/User/IsUserSSOIdExist";
  static GetSSODetailFromSSO =
    AppSetting.BaseApiUrl + "admin/User/GetSSODetail?id=";
  static GetUserDepartmentDDlUrl =
    AppSetting.BaseApiUrl + "admin/User/GetUserDepartment";
  static GetUserDepartmentForUserOfficeCreationUrl = AppSetting.BaseApiUrl + "admin/User/GetUserDepartmentForUserOfficeCreation";
  static GetUserAdminDepartmentDDlUrl =
    AppSetting.BaseApiUrl + "admin/User/GetUserAdminDepartment";
  static GetUserAdminDepartmentForUserOfficeCreationUrl = AppSetting.BaseApiUrl + "admin/User/GetUserAdminDepartmentForUserOfficeCreation";
  static GetUserDistrictDDlUrl =
    AppSetting.BaseApiUrl + "admin/User/GetUserDistrict";
  static GetUserDistrictForUserOfficeCreationUrl = AppSetting.BaseApiUrl + "admin/User/GetUserDistrictForUserOfficeCreation";
  static GetUserOfficeDDlUrl =
    AppSetting.BaseApiUrl + "admin/User/GetUserOffice";
  static GetUserOfficeHavingStaffDDlUrl =
    AppSetting.BaseApiUrl + "admin/User/GetUserOfficeHavingStaff";

  static GetProjectGroupsUrl =
    AppSetting.BaseApiUrl + "admin/User/GetProjectGroups";

  static GetVenderOfficeDDlUrl =
    AppSetting.BaseApiUrl + "admin/User/GetVenderOffice";
  static GetUserOfficeForUserCreationUrl = AppSetting.BaseApiUrl + "admin/User/GetUserOfficeForUserCreation";
  static CheckHODUserSSOId =
    AppSetting.BaseApiUrl + "admin/User/CheckHODUserSSOId?id=";
  static UserLogUrl = AppSetting.BaseApiUrl + "api/Account/SaveLoginUserLog";
  static UserDropdownKeys =
    "ddlUserType,ddlTitle,ddlGender,ddlOfficeDepartment,ddlDistrict,ddlUser,ddlOfficeByLoginUserId,ddlDepartmentByLoginUserId";
  static AdminUserCreationDropdownKeys =
    "ddlUserType,ddlTitle,ddlGender,ddlOfficeDepartment,ddlDistrict,ddlOfficeByLoginUserId";
  static VendorUserDropdownKeys =
    "ddlUserType,ddlDisplayUserTypeTitle,ddlDepartmentByVendorPermission,ddlOfficeDepartment,ddlDistrict,ddlTitle,ddlGender,ddlProject,ddlState";
  static VendorUserProfileDropdownKeys =
    "ddlTitle,ddlGender";
  static VendorUserListUrl =
    AppSetting.BaseApiUrl + "admin/User/GetVendorUserList";
  static OfficeStaffPDFDownloadUrl = AppSetting.BaseApiUrl + "admin/User/GetOfficeStaffPDFDownload";
  static AdminLoginUserCreationListUrl = AppSetting.ReadApiUrl + "admin/user/GetAdminCreationUser";
  static ExportUserDataUrl = AppSetting.BaseApiUrl + "admin/user/ExportUserData";
  static GetVendorUserBySearchUrl = AppSetting.ReadApiUrl + "admin/User/GetVendorUserBySearch";

  static AddUpdateAdditionalTypeUrl = AppSetting.BaseApiUrl + "admin/User/AddUpdateAdditionalType";
  static GetAdditionalUserTypeByIdUrl = AppSetting.BaseApiUrl + "admin/User/GetAdditionalUserTypeById";
  static UpdateCurrentUserTypeUrl = AppSetting.BaseApiUrl + "admin/User/UpdateCurrentUserType";


  //#region SMS
  // static DDlKeyUserNotification = "ddlOffice,ddlDepartment";
  static DDlKeyUserNotification = "ddlOfficeDepartment,ddlOfficeDistrict,ddlSMSTemplate";
  static GetUseForNotificationUrl =
    AppSetting.ReadApiUrl + "admin/user/GetUseForNotification/";
  static SendNotificationToUserUrl =
    AppSetting.BaseApiUrl + "admin/user/SendNotificationToUser/";
  static VendorUserFilterDropdownKeys = "ddlOfficeDepartment,ddlProject";
  //#endregion


  //#region <user-authentication>
  static LogInUrl = environment.ApiBaseUrl + "api/Account/LogIn?ssoId=";
  static LogOutUrl = environment.ApplicationBaseUrl + "ssointegration.aspx?operation=logout";
  static BackToSSOUrl = environment.ApplicationBaseUrl + "ssointegration.aspx?operation=backtosso";
  static EmitraLoginOTP = environment.ApiBaseUrl + "api/Account/GenerateOTPForLogin";
  static EmitraLoginByOTP = environment.ApiBaseUrl + "api/Account/EmitraLoginByOTP";
  //#endregion

  //#region <Vendor Type Master>
  static VendorTypeListUrl = AppSetting.BaseApiUrl + "admin/VendorType/Get";
  static PDFDownloadUrl = AppSetting.BaseApiUrl + "admin/VendorType/GetVendorPDFDownload";
  static VendorTypeAddUpdateUrl =
    AppSetting.BaseApiUrl + "admin/VendorType/Post";
  static VendorTypeDetailUrl = AppSetting.BaseApiUrl + "admin/VendorType/Get/";
  static VendorTypeDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/VendorType/UpdateDeleteStatus/";
  static VendorTypeActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/VendorType/UpdateActiveStatus/";
  static IsVendorTypeExistUrl =
    AppSetting.BaseApiUrl + "admin/VendorType/IsVendorTypeExist/";
  //#endregion

  //#region <Communication Type Master>
  static CommunicationTypeListUrl =
    AppSetting.ReadApiUrl + "admin/CommunicationType/Get";
  static CommunicationTypeAddUpdateUrl =
    AppSetting.BaseApiUrl + "admin/CommunicationType/Post";
  static CommunicationTypeDetailUrl =
    AppSetting.ReadApiUrl + "admin/CommunicationType/Get/";
  static CommunicationTypeDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/CommunicationType/UpdateDeleteStatus/";
  static CommunicationTypeActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/CommunicationType/UpdateActiveStatus/";
  static IsCommunicationTypeExistUrl =
    AppSetting.BaseApiUrl + "admin/CommunicationType/IsCommunicationTypeExist";
  static ActiveCommunicationTypeUrl =
    AppSetting.BaseApiUrl +
    "admin/CommunicationType/GetActiveCommunicationType";
  static CommunicationTypeDropdownKeys = "ddlCommunicationAttachmentType,ddlUserType";
  static CommunicationTypePDFDownloadUrl =
    AppSetting.BaseApiUrl + "admin/CommunicationType/GetCommunicationTypePDFDownload";
  //#endregion

  //#region <Communication Attachment Type Master>
  static CommunicationAttachmentTypeListUrl =
    AppSetting.BaseApiUrl + "admin/CommunicationAttachmentType/Get";
  static CommunicationAttachmentTypeAddUpdateUrl =
    AppSetting.BaseApiUrl + "admin/CommunicationAttachmentType/Post";
  static CommunicationAttachmentTypeDetailUrl =
    AppSetting.BaseApiUrl + "admin/CommunicationAttachmentType/Get/";
  static CommunicationAttachmentTypeDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl +
    "admin/CommunicationAttachmentType/UpdateDeleteStatus/";
  static CommunicationAttachmentTypeActiveStatusChangeUrl =
    AppSetting.BaseApiUrl +
    "admin/CommunicationAttachmentType/UpdateActiveStatus/";
  static IsCommunicationAttachmentTypeExistUrl =
    AppSetting.BaseApiUrl +
    "admin/CommunicationAttachmentType/IsCommunicationAttachmentTypeExist/";
  static CommunicationAttachmentTypePDFDownloadUrl = AppSetting.BaseApiUrl + "admin/CommunicationAttachmentType/GetCommunicationAttachmentTypePDFDownload";
  static AttachementTypeListByCommunicationTypeUrl = AppSetting.BaseApiUrl + "admin/CommunicationAttachmentType/GetAttachementTypeListByCommunicationType"
  static GetUserTypeByCommunicationTypeUrl = AppSetting.BaseApiUrl + "admin/CommunicationType/GetUserTypebyCommunicationType"

  //#endregion

  //#region <Vendor>
  static VendorListUrl = AppSetting.ReadApiUrl + "user/Vendor/Get";
  static VendorSearchListUrl = AppSetting.BaseApiUrl + "user/Vendor/GetSearch";

  static VendorAddUpdateUrl = AppSetting.BaseApiUrl + "user/Vendor/Post";
  static NewVendorRegisterUrl =
    AppSetting.BaseApiUrl + "admin/Account/AddVendor";
  static VendorDetailUrl = AppSetting.BaseApiUrl + "user/Vendor/Get/";
  static VendorActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "user/Vendor/UpdateActiveStatus/";
  static IsVendorSSOIdExistUrl =
    AppSetting.BaseApiUrl + "user/Vendor/IsVendorSSOIdExist/";
  static GetVendorWorkOrderDDlUrl =
    AppSetting.BaseApiUrl + "user/Vendor/GetVendorWorkOrder";
  static VendorDropdownKeys = "ddlVendorType,ddlState,ddlDistrictbyState";
  static VendorVerifiedStatusChangeUrl =
    AppSetting.BaseApiUrl + "user/Vendor/UpdateVerifiedStatus/";
  static VendorPDFDownloadUrl =
    AppSetting.BaseApiUrl + "user/Vendor/GetVendorListPDFDownload";
  static FilterVendorAdmin = AppSetting.BaseApiUrl + "user/Vendor/GetFilterVendorAdmins";
  static FilterDepartment = AppSetting.BaseApiUrl + "user/DepartmentMaster/GetFilterDepartments";
  //#endregion

  //#region <Vendor Bank Account>
  static BankAccountListUrl =
    AppSetting.BaseApiUrl + "user/BankAccount/Get?id=";
  static BankAccountAddUpdateUrl =
    AppSetting.BaseApiUrl + "user/BankAccount/Post";
  static BankAccountDetailUrl = AppSetting.BaseApiUrl + "user/BankAccount/Get/";
  static BankAccountActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "user/BankAccount/UpdateActiveStatus/";
  static BankAccountDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "user/BankAccount/UpdateDeleteStatus/";
  static IsVendorBankAccountExistUrl =
    AppSetting.BaseApiUrl + "user/BankAccount/IsVendorBankAccountExist?id=";
  static BankBranchDetailByBranchCodeUrl =
    AppSetting.BaseApiUrl + "user/BankAccount/GetBankBranchDetailByBranchCode/";
  static BankBranchDetailByIFSCUrl =
    AppSetting.BaseApiUrl + "user/BankAccount/GetBankBranchDetailByIFSC/";
  static BankAccountDropdownKeys = "ddlBank";
  //#endregion

  //#region <Work Order>
  static WorkOrderListUrl = AppSetting.ReadApiUrl + "user/WorkOrder/Get";
  static WorkOrderSearchListUrl = AppSetting.BaseApiUrl + "user/WorkOrder/GetSearch";
  static WorkOrderAddUpdateUrl = AppSetting.BaseApiUrl + "user/WorkOrder/Post";
  static WorkOrderDetailUrl = AppSetting.ReadApiUrl + "user/WorkOrder/Get/";
  static WorkOrderActiveStatusChangeUrl = AppSetting.BaseApiUrl + "user/WorkOrder/UpdateActiveStatus/";
  static WorkOrderChangeAcceptMethodChangeUrl = AppSetting.BaseApiUrl + "user/WorkOrder/WorkOrderChangeAcceptstatus/";
  static WorkOrderDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "user/WorkOrder/UpdateDeleteStatus/";
  static IsWorkOrderNoExistUrl = AppSetting.BaseApiUrl + "user/WorkOrder/IsWorkOrderNoExist/";
  static WorkOrderDropdownKeys = "ddlProjectDepartment,ddlOfficeDistrict,ddlProject,ddlWorkOrderCategory,ddlPaymentType";
  static OICAcceptedChangeUrl = AppSetting.BaseApiUrl + "user/WorkOrder/UpdateOICAccepted/";
  static WorkOrderListPDFDownloadUrl = AppSetting.BaseApiUrl + "user/WorkOrder/GetWorkOrderPDFDownload";
  static GetWorkOrderBySearchUrl = AppSetting.ReadApiUrl + "user/WorkOrder/GetWorkOrderBySearch";
  static WorkOrderFilterDropdownKeys = "ddlOfficeDepartment,ddlProject,ddlWorkOrderCategory,ddlWorkOrderSubCategory";
  static WorkOrderClear = AppSetting.BaseApiUrl + "user/WorkOrder/WorkOrderClear";

  //#endregion

  //#region <Communication>
  static CommunicationListUrl = AppSetting.BaseApiUrl + "user/Communication/Get";
  static CommunicationAddUpdateUrl = AppSetting.BaseApiUrl + "user/Communication/Post";
  static CommunicationDetailUrl = AppSetting.ReadApiUrl + "user/Communication/Get";
  static CommunicationActiveStatusChangeUrl = AppSetting.BaseApiUrl + "user/Communication/UpdateActiveStatus/";
  static CommunicationDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "user/Communication/UpdateDeleteStatus/";
  static ChangeCommunicationOfficeUrl = AppSetting.BaseApiUrl + "user/Communication/ChangeCommunicationOffice/";
  static PullBackInternalTransferUrl = AppSetting.BaseApiUrl + "user/Communication/PullBack_InternalTransfer/";
  static CommunicationInternalTransferDropdownKeys = "ddlOfficeDepartment,ddlDistrict,ddlDepartmentOffice,ddlOfficeStaff,ddlForwordToAction";

  static CommunicationDropdownKeys = "ddlDepartmentByLoginUserId,ddlOfficeByLoginUserId,ddlOfficeDepartment,ddlDistrict,ddlCommunicationType,ddlProject,ddlReason,ddlStatus,ddlVendor,ddlUserType,ddOfficeStaff,ddlForwordToAction,ddlWorkOrderCategory,ddlPaymentType";

  static CommunicationNewOrOldListUrl = AppSetting.BaseApiUrl + "user/Communication/GetNewOldCommunicationReport";
  static ExportCommunicationReportUrl = AppSetting.BaseApiUrl + "user/communication/exportcommunicationreport/";
  static CommunicationChangeOfficeDropdownKeys = "ddlOfficeDepartment,ddlOfficeDistrict"
  static CommunicationMoveDepartmentAndOfficeListUrl = AppSetting.BaseApiUrl + "user/Communication/GetCommunicationListMovedtodepartmentAndOfficeReport/";

  static CommunicationForwardListUrl = AppSetting.BaseApiUrl + "user/Communication/GetForwardedCommunicationReport/";
  static CommunicationAgeWiseListUrl = AppSetting.BaseApiUrl + "user/Communication/GetAgeWiseCommunicationReport/";


  static CommunicationActionDropdownKeys = "ddlReason,ddlStatus,ddlRejectReason,ddlAcceptReason,ddlDisposedReason,ddlCloseReason,ddlReOpenReason";

  static CommunicationReOpenActionDropdownKeys = "ddlReOpenReason";

  static CommunicationSearchListUrl = AppSetting.ReadApiUrl + "user/Communication/GetAllBySearch";
  static VendorLocateCommunicationDdl = "ddlVendor";


  static AllGlobalListSearchUrl = AppSetting.ReadApiUrl + "user/Communication/GetAllGlobalListSearch";

  static CommunicationListByAdvanceSearchUrl =
    AppSetting.ReadApiUrl + "user/Communication/GetCommunicationListBySearch";

  static PreviousCommunicationListUrl =
    AppSetting.BaseApiUrl + "user/Communication/GetPreviousCommunicationListByCode";

  static CommunicationClear = AppSetting.BaseApiUrl + "user/Communication/CommunicationClear";

  static GetCommunicationDrilldownListUrl =
    AppSetting.BaseApiUrl + "user/Dashboard/GetCommunicationDrilldownListData";

  //#endregion

  //#region <Communication>
  static InvoiceCommunicationListUrl =
    AppSetting.BaseApiUrl + "user/InvoiceCommunication/Get";
  static InvoiceCommunicationAddUpdateUrl =
    AppSetting.BaseApiUrl + "user/InvoiceCommunication/Post";
  static InvoiceCommunicationDetailUrl =
    AppSetting.BaseApiUrl + "user/InvoiceCommunication/Get/";
  static InvoiceCommunicationActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "user/InvoiceCommunication/UpdateActiveStatus/";
  static InvoiceCommunicationDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "user/InvoiceCommunication/UpdateDeleteStatus/";
  static InvoiceChangeCommunicationOfficeUrl =
    AppSetting.BaseApiUrl +
    "user/InvoiceCommunication/ChangeCommunicationOffice/";
  static InvoiceCommunicationDropdownKeys =
    "ddlOfficeDepartment,ddlDistrict,ddlCommunicationType";
  //#endregion
  //#region <General - Communication>
  static GeneralCommunicationListUrl =
    AppSetting.BaseApiUrl + "user/GeneralCommunication/Get";
  static GeneralCommunicationAddUpdateUrl =
    AppSetting.BaseApiUrl + "user/GeneralCommunication/Post";
  static GeneralCommunicationDetailUrl =
    AppSetting.BaseApiUrl + "user/GeneralCommunication/Get/";
  static GeneralCommunicationDropdownKeys =
    "ddlConcern,ddlOfficeDepartment,ddlDistrict,ddlOfficeByLoginUserId,ddlDepartmentByLoginUserId";
  //#endregion

  //#region <Complaint - Communication>
  static ComplaintCommunicationListUrl =
    AppSetting.BaseApiUrl + "user/ComplaintCommunication/Get";
  static ComplaintCommunicationAddUpdateUrl =
    AppSetting.BaseApiUrl + "user/ComplaintCommunication/Post";
  static ComplaintCommunicationDetailUrl =
    AppSetting.BaseApiUrl + "user/ComplaintCommunication/Get/";
  static ComplaintCommunicationDropdownKeys =
    "ddlOfficeDepartment,ddlRajDistrict,ddlOfficeByLoginUserId,ddlDepartmentByLoginUserId";
  static ComplaintCommunicationPDFDownloadUrl =
    AppSetting.BaseApiUrl + "user/ComplaintCommunication/GetComplaintCommunicationPDFDownload";

  static D2VCommunicationDropdownKeys =
    "ddlLoggedUserVendor,ddlProject,ddlDepartmentByLoginUserId";

  static VendorCommunicationDropdownKeys =
    "ddlOfficeDepartment,ddlRajDistrict,ddlDepartmentByLoginUserId,ddlDepartmentByVendorPermission";

  static D2DCommunicationDropdownKeys =
    "ddlOfficeDepartment,ddlRajDistrict,ddlAllDepartment,ddlDepartmentByLoginUserId,ddlOfficeHavingStaffByLoginUserId";

  // ddlOfficeByLoginUserId
  // ddlOfficeByLoginUserId

  //#endregion

  //#region <EMDReturn - Communication>
  static EMDReturnCommunicationListUrl =
    AppSetting.BaseApiUrl + "user/EMDReturnCommunication/Get";
  static EMDReturnCommunicationAddUpdateUrl =
    AppSetting.BaseApiUrl + "user/EMDReturnCommunication/Post";
  static EMDReturnCommunicationDetailUrl =
    AppSetting.BaseApiUrl + "user/EMDReturnCommunication/Get/";
  static EMDReturnCommunicationDropdownKeys = "ddlEMDReturnType,ddlOfficeDepartment,ddlDistrict,ddlBank,ddlRefundRequestReason,ddlInForrmOf,ddlOfficeByLoginUserId,ddlDepartmentByLoginUserId";
  static EMDReturnCommunicationVendorBankDropdownKeys = "ddlEMDReturnType,ddlOfficeDepartment,ddlDistrict,ddlRefundRequestReason,ddlInForrmOf,ddlOfficeByLoginUserId,ddlDepartmentByLoginUserId";

  //#endregion

  //#region <Suggestion - Communication>
  static SuggestionCommunicationListUrl =
    AppSetting.BaseApiUrl + "user/SuggestionCommunication/Get";
  static SuggestionCommunicationAddUpdateUrl =
    AppSetting.BaseApiUrl + "user/SuggestionCommunication/Post";
  static SuggestionCommunicationDetailUrl =
    AppSetting.BaseApiUrl + "user/SuggestionCommunication/Get/";
  static SuggestionCommunicationDropdownKeys =
    "ddlSuggestion,ddlOfficeDepartment,ddlDistrict,ddlOfficeByLoginUserId,ddlDepartmentByLoginUserId";
  //#endregion

  //#region <Communication Action>
  static ActionListUrl =
    AppSetting.BaseApiUrl + "user/Communication/GetActionHistory";
  static InternalTransferHistoryListUrl =
    AppSetting.BaseApiUrl + "user/Communication/GetInternalTransferHistory";
  static ActionAddUpdateUrl =
    AppSetting.BaseApiUrl + "user/Communication/AddUpdateAction";
  //#endregion

  //#region <Dashboard>
  static CommunicationLisByStatustUrl = AppSetting.BaseApiUrl + "user/Dashboard/GetCommunicationByStatus";
  static ConversationNotificationListUrl = AppSetting.BaseApiUrl + "user/Dashboard/GetConversationNotification";
  static GetUserDashboardStatsDataUrl = AppSetting.BaseApiUrl + "user/Dashboard/GetUserDashboardStatsData";
  static GetUserDashboardDataUrl = AppSetting.BaseApiUrl + "user/Dashboard/GetUserDashboardData";
  static FilterDropdownKeys =
    "ddlOfficeDepartment,ddlVendor,ddlOfficeStaff,ddlDistrict,ddlDepartmentOffice,ddlWorkOrder,ddlCommunicationType,ddlStatus,ddlUser";
  static ReportFilterDropdownKeys =
    "ddlOfficeDepartment,ddlUserTypeByParent,ddlStatus,ddlVendor,ddlDepartmentOffice,ddlCommunicationType,ddlAllOffice";//,ddlProject,ddlWorkOrder";
  static GlobalFilterKey = "ddlCommunicationType";
  //static GetDashboardStatusData = AppSetting.BaseApiUrl + "user/Dashboard/GetDashboardStatusData";

  static DashboardStatusDataUrl = AppSetting.BaseApiUrl + "user/Dashboard/GetDepartmentDashboardCountData";
  static DepartmentToDoDataUrl = AppSetting.BaseApiUrl + "user/Dashboard/GetDepartmentToDoData";
  static VendorStatusCountDataUrl = AppSetting.BaseApiUrl + "user/Dashboard/GetVendorStatusCountData";
  static DepartmentStatuCountDataUrl = AppSetting.BaseApiUrl + "user/Dashboard/GetDepartmentStatuCountData";
  //#endregion


  //#region <Project>
  static ProjectListUrl = AppSetting.ReadApiUrl + "user/Project/Get";
  static ProjectAddUpdateUrl = AppSetting.BaseApiUrl + "user/Project/Post";
  static ProjectDetailUrl = AppSetting.ReadApiUrl + "user/Project/Get/";
  static ProjectActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "user/Project/UpdateActiveStatus/";
  static ChangeAcceptMethodChangeUrl =
    AppSetting.BaseApiUrl + "user/Project/ChangeAcceptStatus/";
  static ProjectDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "user/Project/UpdateDeleteStatus/";
  static IsProjectNameExistUrl =
    AppSetting.BaseApiUrl + "user/Project/IsProjectNameExist/";
  static ProjectDropDownKeys = "ddlDepartmentByVendorPermission,ddlProjectCategory,ddlProjectGroup";
  static ProjectDropDownDEPTKeys = "ddlDepartmentByVendorPermission,ddlOfficeDepartment,ddlProjectCategory,ddlProjectGroup,ddlLoggedUserVendor,ddlOfficeDistrict";

  static ActivityLogDropDownKeys = "ddlOfficeDepartment,ddlUserTypetemplogin,ddlVendor,ddlMenuActivityLog";
  static ActivityLogDropDownDEPTKeys = "ddlOfficeDepartment,ddlLoggedUserVendor,ddlOfficeDistrict,ddlUserTypetemplogin,ddlVendor,ddlMenuActivityLog";


  static GetProjectPDFDownload = AppSetting.BaseApiUrl + "user/Project/GetProjectPDFDownload";
  static NotAssignedProjectListUrl = AppSetting.BaseApiUrl + "user/Project/GetAllNotAssignedProjects";
  static ProjectListySearchUrl = AppSetting.ReadApiUrl + "user/Project/GetProjectBySearch";
  static ProjectClear = AppSetting.BaseApiUrl + "user/Project/ProjectClear";
  //#endregion

  //#region <Inbox>
  static InboxListUrl = AppSetting.ReadApiUrl + "user/Inbox/Get";
  static InboxDetailUrl = AppSetting.ReadApiUrl + "user/Inbox/Get/";
  static InboxDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "user/Inbox/UpdateDeleteStatus/";
  static InboxListByStatusUrl = AppSetting.BaseApiUrl + "user/Inbox/Get";
  static InboxCounterUrl = AppSetting.BaseApiUrl + "user/Inbox/GetCounter";

  //#endregion

  //#region <Outbox>
  static OutboxListUrl =
    AppSetting.ReadApiUrl + "user/Outbox/Get";
  static OutboxDetailUrl =
    AppSetting.BaseApiUrl + "user/Outbox/Get/";
  static OutboxDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "user/Outbox/UpdateDeleteStatus/";
  //#endregion

  //#region <Reason Type Master>
  static ReasonTypeListUrl = AppSetting.BaseApiUrl + "admin/ReasonType/Get";
  static ReasonTypeAddUpdateUrl =
    AppSetting.BaseApiUrl + "admin/ReasonType/Post";
  static ReasonTypeDetailUrl = AppSetting.BaseApiUrl + "admin/ReasonType/Get/";
  static ReasonTypeDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/ReasonType/UpdateDeleteStatus/";
  static ReasonTypeActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/ReasonType/UpdateActiveStatus/";
  static IsReasonTypeExistUrl =
    AppSetting.BaseApiUrl + "admin/ReasonType/IsReasonTypeExist/";
  static ReasonTypePDFDownloadUrl =
    AppSetting.BaseApiUrl + "admin/ReasonType/GetReasonTypePDFDownload";
  //#endregion

  //#region <Office >
  static OfficeListUrl = AppSetting.BaseApiUrl + "admin/Office/Get";
  static OfficeAddUrl = AppSetting.BaseApiUrl + "admin/Office/post";
  static OfficeDetailUrl = AppSetting.BaseApiUrl + "admin/Office/Get/";
  static OfficeEditUrl = AppSetting.BaseApiUrl + "admin/Office/Put/";
  static OfficeDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/Office/UpdateDeleteStatus/";
  static OfficeActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/Office/UpdateActiveStatus/";
  static IsOfficeNameExistUrl = AppSetting.BaseApiUrl + "admin/Office/IsOfficeExist/";
  static DDlKeyForOffice = "ddlDepartment,ddlDistrict,ddlOffice";
  static DDlKeyForDepartment = "ddlDepartment,ddlDistrict";
  static CheckDRECUserSSOId =
    AppSetting.BaseApiUrl + "admin/User/CheckDRECUserSSOId?id=";
  static OfficePDFDownloadUrl = AppSetting.BaseApiUrl + "admin/Office/GetOfficeReportPDFDownload";
  static CheckHOOUserSSOId =
    AppSetting.BaseApiUrl + "admin/User/CheckHOOUserSSOId?id=";
  //#endregion


  //#region <Office Master>
  static DepartmentOfficeMasterListUrl = AppSetting.BaseApiUrl + "admin/OfficeMaster/Getall";
  static DepartmentOfficeMasterPDFListUrl = AppSetting.BaseApiUrl + "admin/OfficeMaster/GetOfficeReportPDFDownload";
  static OfficeMasterListUrl = AppSetting.BaseApiUrl + "admin/OfficeMaster/Get";
  static OfficeMasterAddUrl = AppSetting.BaseApiUrl + "admin/OfficeMaster/post";
  static OfficeMasterDetailUrl = AppSetting.ReadApiUrl + "admin/OfficeMaster/Get/";

  static OfficeMasterDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/OfficeMaster/UpdateDeleteStatus/";
  static OfficeMasterActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/OfficeMaster/UpdateActiveStatus/";
  static DDlKeyForOfficeMaster = "ddlState,ddlTehsil,ddlBlock,ddlAreaType,ddlGramPanchayat,ddlVillage,ddlOfficeLevel,ddlOfficelookupType1,ddlOfficelookupType2";
  static DdlKeyForOfficeListFilter = "ddlAdminDepartment";
  static GetOfficeListByIdsUrl = AppSetting.BaseApiUrl + "admin/OfficeMaster/GetOfficeListByIds/";
  //#endregion

  //#region  <Department master>
  static GetDepartmentPDFDownload = AppSetting.BaseApiUrl + "admin/Department/GetDepartmentPDFDownload";
  static DepartmentListUrl = AppSetting.BaseApiUrl + "admin/DepartmentMaster/Get";
  static DepartmentAddUpdateUrl = AppSetting.BaseApiUrl + "admin/Department/Post";
  static DepartmentDetailUrl = AppSetting.BaseApiUrl + "admin/Department/Get/";
  static DepartmentDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/Department/UpdateDeleteStatus/";
  static DepartmentActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/Department/UpdateStatus/";
  static IsDepartmentExistUrl = AppSetting.BaseApiUrl + "admin/Department/IsDepartmentExist/";
  static DepartmentUpdateUrl = AppSetting.BaseApiUrl + "Admin/Department/Put/";
  static DepartmentDropdownKeys = "ddlAdminDepartment";
  static DepartmentbyVendorListUrl = AppSetting.BaseApiUrl + "admin/DepartmentMaster/GetDepartmentByVendor/";
  //#endregion

  //#region <Complaint Type Master>
  static ComplaintTypeListUrl = AppSetting.BaseApiUrl + "admin/ComplaintType/Get";
  static ComplaintTypeAddUpdateUrl = AppSetting.BaseApiUrl + "admin/ComplaintType/Post";
  static ComplaintTypeDetailUrl = AppSetting.BaseApiUrl + "admin/ComplaintType/Get/";
  static ComplaintTypeDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/ComplaintType/UpdateDeleteStatus/";
  static ComplaintTypeActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/ComplaintType/UpdateActiveStatus/";
  static IsComplaintTypeExistUrl = AppSetting.BaseApiUrl + "admin/ComplaintType/IsComplaintTypeExist/";
  static ComplaintTypePDFDownloadUrl = AppSetting.BaseApiUrl + "admin/ComplaintType/GetComplaintTypePDFDownload";
  //#endregion

  //#region <Refund Request Reason Master>
  static RefundRequestReasonListUrl = AppSetting.BaseApiUrl + "admin/RefundRequestReason/Get";
  static RefundRequestReasonAddUpdateUrl = AppSetting.BaseApiUrl + "admin/RefundRequestReason/Post";
  static RefundRequestReasonDetailUrl = AppSetting.BaseApiUrl + "admin/RefundRequestReason/Get/";
  static RefundRequestReasonDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/RefundRequestReason/UpdateDeleteStatus/";
  static RefundRequestReasonActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/RefundRequestReason/UpdateActiveStatus/";
  static IsRefundRequestReasonExistUrl = AppSetting.BaseApiUrl + "admin/RefundRequestReason/IsRefundRequestReasonExist/";
  static RefundRequestReasonPDFDownloadUrl =
    AppSetting.BaseApiUrl + "admin/RefundRequestReason/GetRefundRequestReasonPDFDownload";
  //#endregion

  //#region <Concern Master>
  static ConcernListUrl = AppSetting.BaseApiUrl + "admin/Concern/Get";
  static ConcernAddUpdateUrl = AppSetting.BaseApiUrl + "admin/Concern/Post";
  static ConcernDetailUrl = AppSetting.BaseApiUrl + "admin/Concern/Get/";
  static ConcernDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/Concern/UpdateDeleteStatus/";
  static ConcernActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/Concern/UpdateActiveStatus/";
  static IsConcernExistUrl = AppSetting.BaseApiUrl + "admin/Concern/IsConcernExist/";
  static ConcernPDFDownloadUrl = AppSetting.BaseApiUrl + "admin/Concern/GetConcernPDFDownload";
  //#endregion

  //#region <Suggestion Master>
  static SuggestionListUrl = AppSetting.BaseApiUrl + "admin/Suggestion/Get";
  static SuggestionAddUpdateUrl = AppSetting.BaseApiUrl + "admin/Suggestion/Post";
  static SuggestionDetailUrl = AppSetting.BaseApiUrl + "admin/Suggestion/Get/";
  static SuggestionDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/Suggestion/UpdateDeleteStatus/";
  static SuggestionActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/Suggestion/UpdateActiveStatus/";
  static IsSuggestionExistUrl = AppSetting.BaseApiUrl + "admin/Suggestion/IsSuggestionExist/";
  static SuggestionPDFDownloadUrl =
    AppSetting.BaseApiUrl + "admin/Suggestion/GetSuggestionPDFDownload";
  //#endregion

  //#region  <HelpDocument>
  static UserManualListUrl = AppSetting.ReadApiUrl + "Admin/UserManual/Get";
  static UserManualAddUrl = AppSetting.BaseApiUrl + "Admin/UserManual/Post";
  static UserManualUrlById = AppSetting.ReadApiUrl + "Admin/UserManual/Get/";
  static UserManualEditUrl = AppSetting.BaseApiUrl + "Admin/UserManual/Put";
  static GetHelpDocumentUrlById = AppSetting.ReadApiUrl + "Admin/UserManual/GetHelpDocument/";

  static UserManualDeleteUrl =
    AppSetting.BaseApiUrl + "Admin/UserManual/Delete/";

  //#endregion



  static GetManualDocUrl =
    AppSetting.BaseApiUrl + "api/Common/GetManualDocument?module=";

  static SaveLoginUserLog =
    AppSetting.BaseApiUrl + "api/Account/SaveLoginUserLog";

  //#region <Master Data Master>
  static MasterDataListUrl =
    AppSetting.ReadApiUrl + "admin/MasterData/Get";
  static MasterDataPDFDownloadUrl =
    AppSetting.BaseApiUrl + "admin/MasterData/GetVendorPDFDownload";
  static MasterDataAddUpdateUrl =
    AppSetting.BaseApiUrl + "admin/MasterData/Post";
  static MasterDataDetailUrl = AppSetting.ReadApiUrl + "admin/MasterData/Get/";
  static MasterDataDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/MasterData/UpdateDeleteStatus/";
  static MasterDataActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/MasterData/UpdateActiveStatus/";
  static IsMasterDataExistUrl =
    AppSetting.BaseApiUrl + "admin/MasterData/IsMasterDataTypeExist/";
  static MasterDataDropdownKeys = "ddlMasterType";
  //#endregion

  //#region <Communication Type Fields Master>
  static CommunicationTypeFieldsMasterListUrl = AppSetting.BaseApiUrl + "admin/CommunicationTypeFieldsMaster/Get?id=";
  static CommunicationTypeFieldsMasterAddUpdateUrl = AppSetting.BaseApiUrl + "admin/CommunicationTypeFieldsMaster/Post";
  static CommunicationTypeFieldsMasterDetailUrl = AppSetting.BaseApiUrl + "admin/CommunicationTypeFieldsMaster/Get/";
  static CommunicationTypeFieldsMasterDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/CommunicationTypeFieldsMaster/UpdateDeleteStatus/";
  static CommunicationTypeFieldsMasterHiddenStatusChangeUrl = AppSetting.BaseApiUrl + "admin/CommunicationTypeFieldsMaster/UpdateHiddenStatus/";

  static CommunicationTypeFieldsMasterActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/CommunicationTypeFieldsMaster/UpdateActiveStatus/";
  static IsCommunicationTypeFieldsMasterExistUrl = AppSetting.BaseApiUrl + "admin/CommunicationTypeFieldsMaster/IsCommunicationTypeFieldExist/";
  static CommunicationTypeFieldsDropdownKeys = "ddlFieldType,ddlMasterType,ddlCSSClass";
  static FieldsForCommunicationListUrl = AppSetting.BaseApiUrl + "admin/CommunicationTypeFieldsMaster/GetFieldsForCommunication";
  static DynamicFieldsByTypeCodeListUrl = AppSetting.BaseApiUrl + "admin/CommunicationTypeFieldsMaster/GetDynamicFieldsByTypeCode";


  static FieldValueMasterListByFieldCodeUrl = AppSetting.BaseApiUrl + 'admin/CommunicationTypeFieldsMaster/GetAllFieldValueByFieldCode';
  static DefaultFieldsForCommunicationListUrl = environment.ApiBaseUrl + 'api/CommunicationTypeFieldsMaster/GetDefaultFieldsForCommunication';
  static CommunicationTypeDefaultFieldsMasterDeleteStatusChangeUrl = environment.ApiBaseUrl + "api/CommunicationTypeFieldsMaster/UpdateDeleteDefaultFieldStatus";
  static CommunicationTypeDefaultFieldsDropdownKeys = "ddlDefaultField";
  static CommunicationTypeDefaultFieldsMasterAddUpdateUrl = AppSetting.BaseApiUrl + "api/CommunicationTypeFieldsMaster/AddDefaultFieldForCommunication";
  static GetddlDefaultFields = AppSetting.BaseApiUrl + "api/Common/GetddlDefaultFields"
  static GetCommunicationTypeFieldsValidations = AppSetting.BaseApiUrl + "api/CommunicationTypeFieldsMaster/GetFieldsValidations";
  static SetDefaultFieldSortOrderUrl = AppSetting.BaseApiUrl + "api/CommunicationTypeFieldsMaster/SetDefaultFieldSortOrder";

  static ConfigureAssignFieldUrl = AppSetting.BaseApiUrl + "api/CommunicationTypeFieldsMaster/ConfigureAssignField";

  //#endregion

  //#region Designation Master
  static DesignationListUrl =
    AppSetting.ReadApiUrl + "Admin/DesignationMaster/GetAll";
  static DesignationAddUrl =
    AppSetting.BaseApiUrl + "Admin/DesignationMaster/Post";
  static DesignationUpdateUrl =
    AppSetting.BaseApiUrl + "Admin/DesignationMaster/Put/";
  static DesignationGetByIdUrl =
    AppSetting.ReadApiUrl + "Admin/DesignationMaster/Get/";
  static DesignationUpdateStatusUrl =
    AppSetting.BaseApiUrl + "Admin/DesignationMaster/UpdateStatus/";
  //#endregion

  //#region  <Admin Department Master>
  static AdminDepartmentListUrl =
    AppSetting.ReadApiUrl + "api/AdminDepartmentMaster/Get";
  static AdminDepartmentAddUrl =
    AppSetting.BaseApiUrl + "api/AdminDepartmentMaster/Post";
  static AdminDepartmentUpdateUrl =
    AppSetting.BaseApiUrl + "api/AdminDepartmentMaster/Put/";
  static AdminDepartmentGetByIdUrl =
    AppSetting.ReadApiUrl + "api/AdminDepartmentMaster/Get/";
  static AdminDepartmentUpdateStatusUrl =
    AppSetting.BaseApiUrl + "api/AdminDepartmentMaster/UpdateStatus/";
  static IsAdminDepartmentMasterExistUrl = AppSetting.BaseApiUrl + "admin/AdminDepartmentMaster/IsAdminDepartmentExist";

  //#endregion

  //#region User Type
  static UserTypeListUrl = AppSetting.ReadApiUrl + "admin/userType/Get";
  static UserTypeDetailUrl = AppSetting.ReadApiUrl + "admin/userType/Get/";
  static UserTypeDetailByUserTypeUrl = AppSetting.BaseApiUrl + "admin/userType/GetDetailByUserType/";
  static UserTypeAddUrl = AppSetting.BaseApiUrl + "admin/userType/Post";
  static UserTypeEditUrl = AppSetting.BaseApiUrl + "admin/userType/Put/";
  static UserTypeDeleteUrl = AppSetting.BaseApiUrl + "admin/userType/delete/";
  static UserTypeUpdateIsActiveUrl = AppSetting.BaseApiUrl + "admin/UserType/UpdateIsActive/";
  static AddUpdateChildUserTypeUrl = AppSetting.BaseApiUrl + "admin/UserType/AddUpdateChildUserType";
  static IsUserTypeExistUrl = AppSetting.BaseApiUrl + "admin/UserType/IsUserTypeExist/";

  static UserTypeGetDownLevelUrl = AppSetting.BaseApiUrl + "admin/userType/GetDownLevelUserType";
  static DDlKeyForUser = "ddlState,ddlTitle,ddlGender,ddlDesignation,ddlADLCUserCategory,ddlDepartment,ddlAllDepartment";
  static DDlKeyForUserList = "ddlDesignation";
  static DDlADLCUserCategoryList = "ddlADLCUserCategory";
  static GetOfficeList =
    AppSetting.BaseApiUrl + "api/Common/GetOffice/";
  static GetDistrictByOfficeUrl =
    AppSetting.BaseApiUrl + "api/Common/GetDistrictByOffice?code=";
  static UserDepartmentUrl =
    AppSetting.BaseApiUrl + "admin/user/GetDepartment/";
  static UserGeneralEntrySubCategoryUrl =
    AppSetting.BaseApiUrl + "admin/user/GetGeneralEntrySubCategoryByUserCode/";

  static DDlKeyForUserProfile = "ddlAdminDepartment,ddlState,ddlTitle,ddlGender,ddlDesignation,ddlDepartment";
  static DDlKeyForUpdateUserOffice = "ddlAllOfficeDepartment,ddlAllOfficeState";
  static DDlKeyForSelfRegister = "ddlTitle,ddlGender,ddlDesignation,ddlAllDepartment,ddlAllOfficeAdminDepartment,ddlAllOfficeState";
  static DDlKeyForUserCategory = "ddlUserCategory"
  //#endregion

  //#region  <Department master>
  static DepartmentMasterListUrl =
    AppSetting.ReadApiUrl + "Admin/DepartmentMaster/Get";
  static DepartmentMasterAddUrl =
    AppSetting.BaseApiUrl + "Admin/DepartmentMaster/Post";
  static DepartmentMasterUpdateUrl =
    AppSetting.BaseApiUrl + "Admin/DepartmentMaster/Put/";
  static DepartmentMasterGetByIdUrl =
    AppSetting.ReadApiUrl + "Admin/DepartmentMaster/Get/";
  static DepartmentMasterUpdateStatusUrl =
    AppSetting.BaseApiUrl + "Admin/DepartmentMaster/UpdateStatus/";
  static IsDepartmentMasterExistUrl = AppSetting.BaseApiUrl + "admin/DepartmentMaster/IsDepartmentExist";

  static IsDepartmentMasterShortTitleExistUrl = AppSetting.BaseApiUrl + "admin/DepartmentMaster/IsDepartmentShortTitleExist";


  static DDlKeyForDepartmentMaster =
    "ddlUserGroup,ddlAdminDepartment,ddlRajDistrict,ddlDepartmentCategory,ddlCMOOfficers,RadioDepartmentDistrict";

  static DepartmentReportUrl =
    AppSetting.BaseApiUrl + "Admin/DepartmentMaster/GetDepartmentReport";
  static DepartmentSchemeReportUrl =
    AppSetting.BaseApiUrl + "Admin/DepartmentMaster/GetDepartmentSchemeReport";

  static GetLoginUserDepartmentListUrl =
    AppSetting.BaseApiUrl + "Admin/DepartmentMaster/GetLoginUserDepartmentList";
  static UpdateLoginUserDepartmentUrl =
    AppSetting.BaseApiUrl + "Admin/DepartmentMaster/UpdateLoginUserDepartment";

  static GetDepartmentProfileListUrl =
    AppSetting.BaseApiUrl + "Admin/DepartmentMaster/GetDepartmentProfileList";
  static GetDepartmentProfileByIdUrl =
    AppSetting.BaseApiUrl + "Admin/DepartmentMaster/GetDepartmentProfileById/";
  static DepartmentProfileAddUpdateUrl =
    AppSetting.BaseApiUrl + "Admin/DepartmentMaster/DepartmentProfileAddUpdate";
  static UpdateDepartmentProfileStatusUrl =
    AppSetting.BaseApiUrl +
    "Admin/DepartmentMaster/UpdateDepartmentProfileStatus/";
  static IsDepartmentProfileExistUrl =
    AppSetting.BaseApiUrl +
    "Admin/DepartmentMaster/IsDepartmentProfileExist";
  static DepartmentProfileDDLKey = "ddlEntryTypeMaster";

  //#endregion

  //#region <Department Contact Details>

  static DepartmentContactDetailsGetAllUrl =
    AppSetting.ReadApiUrl + "Admin/DepartmentContactDetails/GetAll";
  static DepartmentContactDetailsGetByIdUrl =
    AppSetting.ReadApiUrl + "Admin/DepartmentContactDetails/GetById/";
  static DepartmentContactDetailsAddUpdateUrl =
    AppSetting.BaseApiUrl + "Admin/DepartmentContactDetails/Post";
  static DepartmentContactDetailsUpdateStatusUrl =
    AppSetting.BaseApiUrl + "Admin/DepartmentContactDetails/UpdateStatus/";
  static GetDepartmentByCodeUrl =
    AppSetting.BaseApiUrl +
    "Admin/DepartmentContactDetails/GetDepartmentByCode/";
  static DepartmentContactDetailsDDLKey = "ddlDptContactDesignation";

  static DepartmentOfficerByDepartmentUrl =
    AppSetting.BaseApiUrl +
    "Admin/DepartmentContactDetails/GetDepartmentOfficerByDepartment?departmentCode=";

  //#endregion

  //#region <District Master>
  static DistrictDropdownKeys = "ddlState";
  static DistrictListUrl = AppSetting.ReadApiUrl + "Admin/District/Get";
  static DistrictAddUpdateUrl = AppSetting.BaseApiUrl + "Admin/District/Post";
  static DistrictDetailUrl = AppSetting.ReadApiUrl + "Admin/District/GetById";
  static DistrictDeleteUrl = AppSetting.BaseApiUrl + "Admin/District/Delete";
  static DistrictUpdateActiveStatusUrl = AppSetting.BaseApiUrl + "Admin/District/UpdateActiveStatus";
  static DivisionDistrictListUrl = AppSetting.BaseApiUrl + "Admin/District/GetDistrictByDivisionIds";
  static DistrictIsExistUrl = AppSetting.BaseApiUrl + "Admin/District/IsExist";
  //#endregion

  //#region <ChiefMinisterProfile Master>
  static ChiefMinisterProfileListUrl = AppSetting.ReadApiUrl + "Admin/ChiefMinisterProfile/Get";
  static ChiefMinisterProfileAddUrl = AppSetting.BaseApiUrl + "Admin/ChiefMinisterProfile/Post";
  static ChiefMinisterProfileEditUrl = AppSetting.BaseApiUrl + "Admin/ChiefMinisterProfile/Update";
  static ChiefMinisterProfileDetailUrl = AppSetting.ReadApiUrl + "api/ChiefMinisterProfile/GetById";

  //#region  <Notification Template Type>
  static NotificationTypeListUrl =
    AppSetting.ReadApiUrl + "Admin/NotificationTemplateType/Get";
  static NotificationTypeAddUrl =
    AppSetting.BaseApiUrl + "Admin/NotificationTemplateType/Post";
  static NotificationTypeByIdUrl =
    AppSetting.ReadApiUrl + "Admin/NotificationTemplateType/GetById/";
  static NotificationTypeEditUrl =
    AppSetting.BaseApiUrl + "Admin/NotificationTemplateType/Put";
  static NotificationTypeDropdownKeys = "ddlNotificationTransactionType";
  static GetNotificationContentKeyUrl =
    AppSetting.BaseApiUrl + "Admin/NotificationTemplateType/GetNotificationContentKey";
  static IsExistNotificationTypeEnumValueUrl = AppSetting.BaseApiUrl + "Admin/NotificationTemplateType/IsExistNotificationTypeEnumValue";
  //#endregion

  //#region <Email Template>
  static EmailTemplateListUrl =
    AppSetting.ReadApiUrl + "Admin/NotificationEmailTemplates/GetAll";
  static EmailTemplateAddUrl =
    AppSetting.BaseApiUrl + "Admin/NotificationEmailTemplates/Create";
  static EmailTemplateUpdateUrl =
    AppSetting.BaseApiUrl + "Admin/NotificationEmailTemplates/Edit";
  static EmailTemplateGetByIdUrl =
    AppSetting.ReadApiUrl +
    "Admin/NotificationEmailTemplates/GetById?id=";
  static EmailTemplateDeleteUrl =
    AppSetting.BaseApiUrl + "Admin/NotificationEmailTemplates/Delete?id=";
  static NotificationTemplateTypeKey = "ddlNotificationTemplateType";


  static SMSTemplateMappingDDlKey = "ddAvailableSMSNotificationTransactionType,ddlAvailableSMSTemplate"
  //#endregion

  //#region <SMS Template>
  static SMSTemplateListUrl =
    AppSetting.ReadApiUrl + "Admin/NotificationSMSTemplates/GetAll";
  static SMSTemplateAddUrl =
    AppSetting.BaseApiUrl + "Admin/NotificationSMSTemplates/Create";
  static SMSTemplateUpdateUrl =
    AppSetting.BaseApiUrl + "Admin/NotificationSMSTemplates/Edit";
  static SMSTemplateGetByIdUrl =
    AppSetting.ReadApiUrl + "Admin/NotificationSMSTemplates/GetById?id=";
  static SMSTemplateDeleteUrl =
    AppSetting.BaseApiUrl + "Admin/NotificationSMSTemplates/Delete?id=";

  static IsExistSMSTemplateUrl =
    AppSetting.BaseApiUrl + "Admin/NotificationSMSTemplates/IsExistSMSTemplate";

  static SMSTemplateActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/NotificationSMSTemplates/UpdateActiveStatus/";

  static MapSMSTemplateWithTransactionUrl =
    AppSetting.BaseApiUrl + "Admin/NotificationSMSTemplates/MapSMSTemplateWithTransaction";

  static GetMapSMSTemplateByIdUrl =
    AppSetting.ReadApiUrl + "Admin/NotificationSMSTemplates/GetMapSMSTemplateById";

  static GetAllMapSMSTemplateListUrl =
    AppSetting.ReadApiUrl + "Admin/NotificationSMSTemplates/GetAllMapSMSTemplate";


  static ChangeMapedSMSTemplateActiveStatusUrl = AppSetting.BaseApiUrl + "admin/NotificationSMSTemplates/UpdateMapedSMSTemplateActiveStatus/";


  //#endregion

  //#region <PermissionMaster>
  static PermissionMasterListUrl = AppSetting.ReadApiUrl + "Admin/PermissionMaster/Get";
  static PermissionMasterAddUrl = AppSetting.BaseApiUrl + "Admin/PermissionMaster/Post";
  static PermissionMasterEditUrl = AppSetting.BaseApiUrl + "Admin/PermissionMaster/Put/";
  static PermissionMasterDetailUrl =
    AppSetting.ReadApiUrl + "Admin/PermissionMaster/Get?id=";
  static PermissionMasterDeleteUrl =
    AppSetting.BaseApiUrl + "Admin/PermissionMaster/Delete?id=";
  static PermissionMasterDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "Admin/PermissionMaster/UpdateDeleteStatus/";
  static PermissionMasterActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "Admin/PermissionMaster/UpdateActiveStatus/";
  static SavePageAuthenticationDetailUrl = AppSetting.BaseApiUrl + "Admin/PermissionMaster/SavePageAuthenticationDetail";
  static PermissionMasterAuthenticationUrl =
    AppSetting.BaseApiUrl + "Admin/PermissionMaster/IsPageAuthenticationValid?id=";
  static DDlKeyForPermissionMaster =
    "ddlApplicationType,ddlPermissionType,ddlApplicationMenu,ddlURLType";


  static DDlKeyForAdditionalUser = 'ddlAdditionalUser';
  static DDlKeyForAddUpdateAdditionalUser = 'ddlUser';


  static UserAdditionalPermissionListUrl = AppSetting.ReadApiUrl + "Admin/user/GetAllUserAdditionalPermission";
  static UserAdditionalPermissionDetailUrl = AppSetting.BaseApiUrl + "Admin/user/GetUserAdditionalPermission";
  static UserAdditionalPermissionActiveUrl = AppSetting.BaseApiUrl + "Admin/user/UpdateActiveStatusUserAdditionalPermission";
  static UserAdditionalPermissionLockedUrl = AppSetting.BaseApiUrl + "Admin/user/UpdateLockedStatusUserAdditionalPermission";
  static UserAddPermissionAddUpdateUrl = AppSetting.BaseApiUrl + "Admin/user/AddUpdateUserAdditionalPermission";

  static DefaultPermissionUserTypesUrl = AppSetting.BaseApiUrl + "admin/permission/GetDefaultPermissionUserTypes";
  static SpecificPermissionUsersUrl = AppSetting.BaseApiUrl + "admin/permission/GetSpecificPermissionUsers";
  static ResetUserSpecificPermissionUrl = AppSetting.BaseApiUrl + "admin/permission/ResetUserSpecificPermissions";
  static UserSpecificPagePermissionListUrl = AppSetting.BaseApiUrl + "admin/permission/GetUserSpecificPagePermissions";
  //#endregion

  //#region <MenuMaster>
  static MenuMasterListUrl = AppSetting.BaseApiUrl + "Admin/Menu/Get";
  static MenuMasterAddUrl = AppSetting.BaseApiUrl + "Admin/Menu/Post";
  static MenuMasterEditUrl = AppSetting.BaseApiUrl + "Admin/Menu/Put/";
  static MenuMasterDetailUrl = AppSetting.BaseApiUrl + "Admin/Menu/Get?id=";
  static MenuMasterDeleteUrl = AppSetting.BaseApiUrl + "Admin/Menu/Delete?id=";
  static MenuMasterByUserIdUrl =
    AppSetting.BaseApiUrl + "Admin/Menu/GetAllByUserId/";
  static MenuMasterDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "Admin/Menu/UpdateDeleteStatus/";
  static MenuMasterActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "Admin/Menu/UpdateActiveStatus/";
  static DDlKeyForMenuMaster = "ddlApplicationType";
  //#endregion

  //#region  Complaint Module
  static ComplaintAdd = AppSetting.BaseApiUrl + "user/Complaint/Create";
  static ComplaintAddAction = AppSetting.BaseApiUrl + "user/Complaint/CreateAction";
  static ComplaintList = AppSetting.ReadApiUrl + "user/Complaint/GetAll";
  static ExportComplaintList = AppSetting.BaseApiUrl + "user/Complaint/exportcomplaintdata";
  static ComplaintDetailUrl = AppSetting.ReadApiUrl + "admin/complaint/Get/";
  static ComplaintFilterDDLKey = "ddlCompliantFilter,ddlApplicationType,ddlEntryTypeModule,ddlCompliantStatus";

  static GenerateComplaintDDLKey =
    "ddlModuleName,ddlPriorityModule,ddlEntryTypeModule,ddlCompliantAction,ddlCompliantFilter,ddlApplicationType";
  static PageMasterByPageTypeCodeUrl =
    AppSetting.BaseApiUrl + "api/Common/GetPageMasterByPageTypeCode?pageTypeCode=";
  //#endregion

  //#region  UserActivityLog
  static UserActivityLogList = AppSetting.ReadApiUrl + "admin/useractivitylog/GetAll";
  //#endregion

  //#region <user-permission>
  static GetDefaultPermissionUrl = AppSetting.ReadApiUrl + "admin/Permission/GetDefaultPermission";
  static SaveDefaultPermissionUrl = AppSetting.BaseApiUrl + "admin/Permission/SaveDefaultPermission";
  static DDlKeyForDefaultPermission = "ddlApplicationType,ddlUserType";
  static GetUserPermissionByApplicationUrl = AppSetting.ReadApiUrl + "api/Permission/GetUserPermissionByApplication";
  static GetUserDefaultPermissionByApplicationUrl = AppSetting.BaseApiUrl + "api/Permission/GetUserPermissionByApplicationGetDefault";
  static GetUserByApplicationUrl = AppSetting.BaseApiUrl + "api/Common/GetUserListByApplication";

  static SaveUserPermissionUrl = AppSetting.BaseApiUrl + "api/Permission/SaveUserPermission";
  static DDlKeyForUserPermission = "ddlApplicationType,ddlUserType,ddlDepartment,ddlUser,ddlOffice";
  static GetAssignedUserPermissionUrl = AppSetting.BaseApiUrl + "api/Permission/GetAssignedUserPermission";
  static DDlKeyForSpecificUserPermission = "ddlApplicationType,ddlUserType,ddlDepartment";
  //#endregion

  //#region Import Data from excel
  static ImportExcelUserAndOffice = AppSetting.BaseApiUrl + "admin/user/ImportExeclUserAndOffice/";
  static DDlKeyForUserImport = "ddlUserType";
  //added by Pravesh dt 25022021
  //#region  Master Reports
  static MasterTypeReportUrl = AppSetting.BaseApiUrl + "admin/MasterReport/Get?masterType=";
  static MasterTypeReportPDFDownloadUrl = AppSetting.BaseApiUrl + "admin/MasterReport/GetMasterReportPDFDownload?masterType="
  //#endregion
  //#endregion


  //#region Master Type

  static MasterTypeListUrl = AppSetting.ReadApiUrl + "admin/MasterType/GetAll";
  static MasterTypeDetailUrl = AppSetting.ReadApiUrl + "admin/MasterType/Get/";
  static MasterTypeAddUpdateUrl = AppSetting.BaseApiUrl + "admin/MasterType/AddUpdate";
  static MasterTypeDeleteUrl = AppSetting.BaseApiUrl + "admin/MasterType/delete/";
  static MasterTypeUpdateIsActiveUrl = AppSetting.BaseApiUrl + "admin/MasterType/UpdateStatus/";
  static MasterTypeCheckExistMasterTypeUrl = AppSetting.BaseApiUrl + "admin/MasterType/CheckExistMasterType/";
  //#endregion

  //#region <Project Group Master>
  static ProjectGroupListUrl = AppSetting.ReadApiUrl + "admin/ProjectGroup/Get";
  static ProjectGroupAddUpdateUrl = AppSetting.BaseApiUrl + "admin/ProjectGroup/Post";
  static ProjectGroupDetailUrl = AppSetting.ReadApiUrl + "admin/ProjectGroup/Get/";
  static ProjectGroupDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/ProjectGroup/UpdateDeleteStatus/";
  static ProjectGroupActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/ProjectGroup/UpdateActiveStatus/";
  static IsProjectGroupExistUrl = AppSetting.BaseApiUrl + "admin/ProjectGroup/IsProjectGroupExist";
  static ProjectGroupPDFDownloadUrl = AppSetting.BaseApiUrl + "admin/ProjectGroup/GetProjectGroupPDFDownload";
  static ProjectGroupListySearchUrl = AppSetting.ReadApiUrl + "user/ProjectGroup/GetProjectGroupListBySearch";
  //#endregion

  //#region <Project Category Master>
  static ProjectCategoryListUrl = AppSetting.ReadApiUrl + "admin/ProjectCategory/Get";
  static ProjectCategoryAddUpdateUrl = AppSetting.BaseApiUrl + "admin/ProjectCategory/Post";
  static ProjectCategoryDetailUrl = AppSetting.ReadApiUrl + "admin/ProjectCategory/Get/";
  static ProjectCategoryDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/ProjectCategory/UpdateDeleteStatus/";
  static ProjectCategoryActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/ProjectCategory/UpdateActiveStatus/";
  static IsProjectCategoryExistUrl = AppSetting.BaseApiUrl + "admin/ProjectCategory/IsProjectCategoryExist";
  static ProjectCategoryPDFDownloadUrl = AppSetting.BaseApiUrl + "admin/ProjectCategory/GetProjectCategoryPDFDownload";
  //#endregion

  //#region <Project Sub Category Master>
  static ProjectSubCategoryListUrl = AppSetting.ReadApiUrl + "admin/ProjectSubCategory/Get";
  static ProjectSubCategoryAddUpdateUrl = AppSetting.BaseApiUrl + "admin/ProjectSubCategory/Post";
  static ProjectSubCategoryDetailUrl = AppSetting.ReadApiUrl + "admin/ProjectSubCategory/Get/";
  static ProjectSubCategoryDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/ProjectSubCategory/UpdateDeleteStatus/";
  static ProjectSubCategoryActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/ProjectSubCategory/UpdateActiveStatus/";
  static IsProjectSubCategoryExistUrl = AppSetting.BaseApiUrl + "admin/ProjectSubCategory/IsProjectSubCategoryExist";
  static ProjectSubCategoryPDFDownloadUrl = AppSetting.BaseApiUrl + "admin/ProjectSubCategory/GetProjectSubCategoryPDFDownload";
  //#endregion

  //#region <Workorder Category Master>
  static WorkOrderCategoryListUrl = AppSetting.ReadApiUrl + "admin/WorkOrderCategory/Get";
  static WorkOrderCategoryAddUpdateUrl = AppSetting.BaseApiUrl + "admin/WorkOrderCategory/Post";
  static WorkOrderCategoryDetailUrl = AppSetting.ReadApiUrl + "admin/WorkOrderCategory/Get/";
  static WorkOrderCategoryDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/WorkOrderCategory/UpdateDeleteStatus/";
  static WorkOrderCategoryActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/WorkOrderCategory/UpdateActiveStatus/";
  static IsWorkOrderCategoryExistUrl = AppSetting.BaseApiUrl + "admin/WorkOrderCategory/IsWorkOrderCategoryExist/";
  static WorkOrderCategoryPDFDownloadUrl = AppSetting.BaseApiUrl + "admin/WorkOrderCategory/GetWorkOrderCategoryPDFDownload";
  static WorkOrderCategoryDropDownKeys = "ddlWorkOrderCategory";

  //#endregion

  //#region <Workorder Sub Category Master>
  static WorkOrderSubCategoryListUrl = AppSetting.ReadApiUrl + "admin/WorkOrderSubCategory/Get";
  static WorkOrderSubCategoryAddUpdateUrl = AppSetting.BaseApiUrl + "admin/WorkOrderSubCategory/Post";
  static WorkOrderSubCategoryDetailUrl = AppSetting.ReadApiUrl + "admin/WorkOrderSubCategory/Get/";
  static WorkOrderSubCategoryDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/WorkOrderSubCategory/UpdateDeleteStatus/";
  static WorkOrderSubCategoryActiveStatusChangeUrl = AppSetting.BaseApiUrl + "admin/WorkOrderSubCategory/UpdateActiveStatus/";
  static IsWorkOrderSubCategoryExistUrl = AppSetting.BaseApiUrl + "admin/WorkOrderSubCategory/IsWorkOrderSubCategoryExist/";
  static WorkOrderSubCategoryPDFDownloadUrl = AppSetting.BaseApiUrl + "admin/WorkOrderSubCategory/GetWorkOrderSubCategoryPDFDownload";
  //#endregion

  //#region <User Notification>
  static UserNotificationListUrl = AppSetting.BaseApiUrl + "user/UserNotification/Get";
  static UserNotificationDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "user/UserNotification/UpdateDeleteStatus/";
  static UserNotificationDeleteAllStatusChangeUrl = AppSetting.BaseApiUrl + "user/UserNotification/UpdateDeleteAllStatus/";
  //#endregion

  //#region
  static GetUserByUserRoleVender = AppSetting.BaseApiUrl + "api/Common/GetUserListByUserRole";
  //#
  //#region Department Report PDF

  static DepartmentMasterReportPDFDownloadUrl = AppSetting.BaseApiUrl + "admin/DepartmentMaster/GetDepartmentReportPDFDownload"
  //#endregion


  //#region <GeneralEntryCategoryMaster>
  static GeneralEntryCategoryMasterListUrl =
    AppSetting.ReadApiUrl + "admin/GeneralEntryCategoryMaster/Get";
  static GeneralEntryCategoryMasterGetByIdUrl =
    AppSetting.ReadApiUrl + "admin/GeneralEntryCategoryMaster/Get/";
  static GeneralEntryCategoryMasterAddUrl =
    AppSetting.BaseApiUrl + "admin/GeneralEntryCategoryMaster/Post";
  static GeneralEntryCategoryMasterEditUrl =
    AppSetting.BaseApiUrl + "admin/GeneralEntryCategoryMaster/Put/";
  static GeneralEntryCategoryMasterActiveStatusUrl =
    AppSetting.BaseApiUrl + "admin/GeneralEntryCategoryMaster/UpdateActiveStatus/";
  static DDLKeyForGeneralEntryCategoryMaster = "ddlClassification,ddlClassificationPageType,RadioDepartmentDistrict";

  //#endregion

  //#region <GeneralEntrySubCategoryMaster>
  static GeneralEntrySubCategoryMasterListUrl =
    AppSetting.ReadApiUrl + "admin/GeneralEntrySubCategoryMaster/Get";
  static GeneralEntrySubCategoryMasterGetByIdUrl =
    AppSetting.ReadApiUrl + "admin/GeneralEntrySubCategoryMaster/Get/";
  static GeneralEntrySubCategoryMasterAddUrl =
    AppSetting.BaseApiUrl + "admin/GeneralEntrySubCategoryMaster/Post";
  static GeneralEntrySubCategoryMasterEditUrl =
    AppSetting.BaseApiUrl + "admin/GeneralEntrySubCategoryMaster/Put/";
  static GeneralEntrySubCategoryMasterDeleteUrl =
    AppSetting.BaseApiUrl + "admin/GeneralEntrySubCategoryMaster/Delete/";
  static GeneralEntrySubCategoryMasterActiveStatusUrl =
    AppSetting.BaseApiUrl + "admin/GeneralEntrySubCategoryMaster/UpdateActiveStatus/";
  static GetsubcategoryList = AppSetting.BaseApiUrl + "api/Common/GetGeneralEntrySubcategorybydeptandcat?";

  static DDlKeyForGeneralEntrySubCategoryMaster = "ddlGeneralEntryCategory,ddlDepartment";

  static UserGeneralEntrySubcategoryList =
    AppSetting.BaseApiUrl + "api/Common/GetGeneralEntrySubCategoryByCategoryCode?";
  //#endregion

  //#region  <GeneralEntry>
  static GeneralEntryListUrl =
    AppSetting.BaseApiUrl + "Admin/GeneralEntry/Get";
  static GeneralEntryAddUrl =
    AppSetting.BaseApiUrl + "Admin/GeneralEntry/Post";
  static GeneralEntryUpdateUrl =
    AppSetting.BaseApiUrl + "Admin/GeneralEntry/Put/";
  static GeneralEntryDetailUrl =
    AppSetting.ReadApiUrl + "Admin/GeneralEntry/Get/";
  static GeneralEntryUpdateActiveStatusUrl =
    AppSetting.BaseApiUrl + "Admin/GeneralEntry/UpdateActiveStatus/";
  static GeneralEntryUpdateDeleteStatusUrl =
    AppSetting.BaseApiUrl + "Admin/GeneralEntry/Delete/";
  static DDlKeyForGeneralEntry = "ddlGeneralEntryCategory,ddlDepartmentByLoginUserId,ddlRajDistrict,ddlTrainingTime";
  static GeneralEntryFilterListUrl =
    AppSetting.ReadApiUrl + "Admin/GeneralEntry/GetGeneralEntryFilter";

  static GeneralEntrySearhListUrl =
    AppSetting.BaseApiUrl + "Admin/GeneralEntry/GetFilterList";

  static DDLKeyForGeneralEntrySearch =
    "ddlGeneralEntryCategory,ddlGeneralEntrySubCategory,ddlDepartment";
  static GetGeneralEntryCategoryByCodeUrl = AppSetting.BaseApiUrl + "api/Common/GetGeneralEntryCategoryByCode?code=";

  //#endregion


  //#region B2GUser Manual
  static B2GUserManualGetAllUrl =
    AppSetting.ReadApiUrl + "Admin/B2GUserManual/Get";
  static B2GUserManualAddUrl =
    AppSetting.BaseApiUrl + "Admin/B2GUserManual/Post";
  static B2GUserManualGetByIdUrl =
    AppSetting.ReadApiUrl + "Admin/B2GUserManual/Get/";
  static B2GUserManualEditUrl =
    AppSetting.BaseApiUrl + "Admin/B2GUserManual/Put";
  static B2GUserManualUpdateStatusUrl =
    AppSetting.BaseApiUrl + "Admin/B2GUserManual/UpdateStatus/";
  static B2GUserManualDDLKey = "ddlB2GUserManualCategory,ddlPermissionType,ddlMasterType,ddlCommunicationType,ddlApplicationType";

  static PageDetailByPageCodeUrl =
    AppSetting.BaseApiUrl + "Admin/B2GUserManual/GetPageDetailByPageCode/";

  static PageListByMenuNameUrl =
    AppSetting.BaseApiUrl + "Admin/B2GUserManual/GetPageListByMenuName";

  static GetCountForUserManualUrl =
    AppSetting.BaseApiUrl + "Admin/B2GUserManual/GetCountForUserManual";

  static ReportUserListUrl = AppSetting.BaseApiUrl + "admin/User/GetUserReport";


  //#endregion B2GUser Manual

  static KPIByDepartmentCodeUrl =
    AppSetting.BaseApiUrl + "api/Common/GetKPIByDepartmentCode?dptCode=";

  static ProjectAdvSearchDropdownKeys =
    "ddlOfficeDepartment,ddlProjectCategory,ddlLoggedUserVendor,ddlProjectGroup";

  //#region <Search Criteria DDL service>

  static SearchCriteriaDepartmentListUrl =
    AppSetting.BaseApiUrl + "api/Common/GetSearchCriteriaDepartmentList";

  static SearchCriteriaProjectListUrl =
    AppSetting.BaseApiUrl + "api/Common/GetSearchCriteriaProjectList";

  static SearchCriteriaOfficeListUrl =
    AppSetting.BaseApiUrl + "api/Common/GetSearchCriteriaOfficeList";

  static SearchCriteriaDistrictListUrl =
    AppSetting.BaseApiUrl + "api/Common/GetSearchCriteriaDistrictList";

  static SearchCriteriaWorkOrderListUrl =
    AppSetting.BaseApiUrl + "api/Common/GetSearchCriteriaWorkOrderList";

  static SearchCriteriaVendorListUrl =
    AppSetting.BaseApiUrl + "api/Common/GetSearchCriteriaVendorList";

  static SearchCriteriaUserListUrl =
    AppSetting.BaseApiUrl + "api/Common/GetSearchCriteriaUserList";
  static GetUserListForAdditionalPermissionUrl = AppSetting.BaseApiUrl + "api/Common/GetUserListForAdditionalPermission2";

  static SearchCriteriaProjectOICListUrl =
    AppSetting.BaseApiUrl + "api/Common/GetSearchCriteriaProjectOICList?projectCodes=";

  static SearchCriteriaCommunicationTypeListUrl =
    AppSetting.BaseApiUrl + "api/Common/GetSearchCriteriaCommunicationTypeList?userType=";

  static SearchCriteriaDDLKey = "ddlCommunicationType";

  static StatisticalReportDDLKey = "ddlDataSource,ddlColumnValue,ddlRowValueEnum";
  //#endregion

  //#region <Status Master>
  static StatusListUrl = AppSetting.ReadApiUrl + "admin/Status/Get";
  static StatusAddUpdateUrl =
    AppSetting.BaseApiUrl + "admin/Status/Post";
  static StatusDetailUrl = AppSetting.ReadApiUrl + "admin/Status/Get/";
  static StatusDeleteStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/Status/UpdateDeleteStatus/";
  static StatusActiveStatusChangeUrl =
    AppSetting.BaseApiUrl + "admin/Status/UpdateActiveStatus/";
  static IsVendorDisplayNameExistUrl =
    AppSetting.BaseApiUrl + "admin/Status/IsVendorDisplayNameExist/";
  static IsDepartmentDisplayNameExistUrl =
    AppSetting.BaseApiUrl + "admin/Status/IsDepartmentDisplayNameExist/";
  static GetStatusNamelUrl = AppSetting.BaseApiUrl + "admin/Status/GetByStatus/";

  //#endregion

  //#region <Statistical Report>

  static StatisticalReportDataUrl =
    AppSetting.ReadApiUrl + "api/StatisticalReport/GetStatisticalReportData";
  //#endregion <Statistical Report>

  //#region Preference
  static PreferenceDataDropdownKeys = "ddlOfficeDepartment,ddlVendor,ddlAllOfficeDepartment";
  static PreferenceVendorDataDropdownKeys = "ddlOfficeDepartment,ddlVendor";
  static PreferenceAddUpdateUrl = AppSetting.BaseApiUrl + "admin/UserPrefer/post";
  static PreferenceListUrl = AppSetting.ReadApiUrl + "admin/UserPrefer/Get";
  static PreferenceAllListUrl = AppSetting.ReadApiUrl + "admin/UserPrefer/GetAll";
  static PreferenceDeleteStatusChangeUrl = AppSetting.BaseApiUrl + "admin/UserPrefer/UpdateDeleteStatus/";
  static PreferedOfficeListUrl = AppSetting.BaseApiUrl + "admin/UserPrefer/GetPreferedOfficeList/";

  //#endregion

  //#region <Log Report>

  static LogReportDataUrl =
    AppSetting.BaseApiUrl + "Api/LogReport/GetLogReport";
  //#endregion <Log Report>
  static ActivityLogFilterDDLKey = "ddlUserType,ddlDistrict,ddlUser,ddlVendor,ddlOfficeDepartment,ddlVendor,ddlMenuActivityLog";

  //#region ComplainEntryTypeMaster
  static ComplainEntryTypeListUrl = AppSetting.ReadApiUrl + "Admin/ComplainEntryTypeMaster/Get";
  static ComplainEntryTypeAddUrl = AppSetting.BaseApiUrl + "Admin/ComplainEntryTypeMaster/Post";
  static ComplainEntryTypeUpdateUrl = AppSetting.BaseApiUrl + "Admin/ComplainEntryTypeMaster/Put/";
  static ComplainEntryTypeGetByIdUrl = AppSetting.ReadApiUrl + "Admin/ComplainEntryTypeMaster/Get/";
  static ComplainEntryTypeUpdateStatusUrl = AppSetting.BaseApiUrl + "Admin/ComplainEntryTypeMaster/UpdateStatus/";
  //#endregion


  //#region ComplainStatusMasterViewModel
  static ComplainStatusListUrl = AppSetting.ReadApiUrl + "Admin/ComplainStatusMaster/Get";
  static ComplainStatusAddUrl = AppSetting.BaseApiUrl + "Admin/ComplainStatusMaster/Post";
  static ComplainStatusUpdateUrl = AppSetting.BaseApiUrl + "Admin/ComplainStatusMaster/Put/";
  static ComplainStatusGetByIdUrl = AppSetting.ReadApiUrl + "Admin/ComplainStatusMaster/Get/";
  static ComplainStatusUpdateStatusUrl = AppSetting.BaseApiUrl + "Admin/ComplainStatusMaster/UpdateStatus/";
  static ComplainStatusChangeStatusUrl = AppSetting.BaseApiUrl + "Admin/ComplainStatusMaster/ChangeStatus/";
  //#endregion

  //#region  <Contact Us Page>  
  static ContactUsListUrl = AppSetting.BaseApiUrl + "api/ContactUs/Get";
  static ContactUsDetailUrl = AppSetting.BaseApiUrl + "api/ContactUs/Get/";
  //#endregion <Contact Us Page>

  //#region <Utility>
  static DeleteDatabaseRecordsUrl = AppSetting.BaseApiUrl + "Admin/Utility/CleanDatabaseRecords?actionType=";
  //#endregion

  //#region vendor communication permission
  static VendorCommunicationPermissionDdl = "ddlVendor,ddlDepartment,ddlAdminDepartment,ddlDepartmentByVendorPermission,ddlProject,ddlUserType";
  static AddVendorCommunicationPermission = AppSetting.BaseApiUrl + "Admin/VendorCommunicationPermission/Add";
  static GetAllVendorCommunicationPermission = AppSetting.BaseApiUrl + "Admin/VendorCommunicationPermission/GetAll";
  static ApproveVendorCommunicationPermission = AppSetting.BaseApiUrl + "Admin/VendorCommunicationPermission/ApprovePermission";
  //#endregion

  //#region vendor permission delegation
  static AddVendorPermissionDelegation = AppSetting.BaseApiUrl + "admin/VendorPermissionDelegation/Add";
  static GetDataVendorPermissionDelegation = AppSetting.BaseApiUrl + "admin/VendorPermissionDelegation/GetAll";
  static GetByIdVendorPermissionDelegation = AppSetting.BaseApiUrl + "admin/VendorPermissionDelegation/Get/";
  //static IsDelegateUserExists = AppSetting.BaseApiUrl + "admin/VendorPermissionDelegation/IsDelegateUserExists?delegateId={delegateId}";
  static IsDelegateUserExists = AppSetting.BaseApiUrl + "admin/VendorPermissionDelegation/IsDelegateUserExists";
  static VendorPermissionDelegationDdl = 'ddlUserByDepartment';
  //#endregion

  //#region custom filter selector
  static DdlKeyForVendor = 'ddlVendor';
  static DdlKeyForDepartment = 'ddlDepartment';
  //#endregion


  //#region <Preferred List>
  static GetPreferredList = AppSetting.BaseApiUrl + "User/PreferredList/GetPreferredList";
  static GetAllOfficeList = AppSetting.BaseApiUrl + "User/PreferredList/GetAllOffice";
  static GetAllOfficerList = AppSetting.BaseApiUrl + "User/PreferredList/GetAllOfficer";
  static DDlKeyForOfficer = "ddlAllDepartment,ddlAllOffice,ddlAdminDepartment";
  static AddAllOfficer = AppSetting.BaseApiUrl + "User/PreferredList/AddAllOfficer";
  static GetAllVendorList = AppSetting.BaseApiUrl + "User/PreferredList/GetAllVendors";
  static AddAllVendor = AppSetting.BaseApiUrl + "User/PreferredList/AddAllVendor";
  static AddAllOffice = AppSetting.BaseApiUrl + "User/PreferredList/AddAllOffice";
  static DDlKeyForAllOffice = "ddlAllDepartment,ddlAdminDepartment,ddlDistrict";
  //#endregion

  static GetProjectWiseCommunicationReport = AppSetting.BaseApiUrl + "user/Project/GetProjectWiseCommunicationReport";

  //#region <Meeting>
  static MeetingListUrl = AppSetting.ReadApiUrl + "api/Meeting/GetAll";
  static MeetingAddUrl = AppSetting.BaseApiUrl + "api/Meeting/Add";
  static MeetingEditUrl = AppSetting.BaseApiUrl + "api/Meeting/Update";
  static MeetingGetByIdUrl = AppSetting.ReadApiUrl + "api/Meeting/GetById/{id}";
  static MeetingDeleteUrl = AppSetting.ReadApiUrl + "api/Meeting/Delete/{id}";
  static MeetingUpdateActiveStatusUrl = AppSetting.BaseApiUrl + "api/Meeting/UpdateActiveStatus/{id}";


  //#region External <RISL>
  static RISLListUrl = AppSetting.BaseApiUrl + "api/RISL/GetAll";
  static RISLAddUrl = AppSetting.BaseApiUrl + "api/RISL/Add";
  static RISLDetailUrl = AppSetting.BaseApiUrl + "api/RISL/GetById/{id}";
  static RISLUpdateUrl = AppSetting.BaseApiUrl + "api/RISL/Update";
  static RISLDeleteUrl = AppSetting.BaseApiUrl + "api/RISL/Delete/{id}";
  static RISLUpdateActiveStatusUrl = AppSetting.BaseApiUrl + "api/RISL/UpdateActiveStatus/{id}";
  static RISLDropdownKeys = "ddlState,ddlVendorType,ddlMSMECategory,ddlDealsIn,ddlAgencyClass,ddlLedgerGroup,ddlLedger";
  //#endregion

  //#region <PCMeeting>
  static PCMeetingListUrl = AppSetting.ReadApiUrl + "api/PCMeeting/GetAll";
  static PCMeetingAddUrl = AppSetting.BaseApiUrl + "api/PCMeeting/Add";
  static PCMeetingEditUrl = AppSetting.BaseApiUrl + "api/PCMeeting/Update";
  static PCMeetingGetByIdUrl = AppSetting.ReadApiUrl + "api/PCMeeting/GetById/{id}";
  static PCMeetingDeleteUrl = AppSetting.ReadApiUrl + "api/PCMeeting/Delete/{id}";
  static PCMeetingUpdateActiveStatusUrl = AppSetting.BaseApiUrl + "api/PCMeeting/UpdateActiveStatus/{id}";

  static PCMeetingDropdownKeys = "ddlPCMeetingType";
  //#endregion

  //#region <Scheme>
  static SchemeListUrl = AppSetting.ReadApiUrl + "api/SchemeMaster/GetAll";
  static SchemeAddUrl = AppSetting.BaseApiUrl + "api/SchemeMaster/Add";
  static SchemeUpdateUrl = AppSetting.BaseApiUrl + "api/SchemeMaster/Update";
  static SchemeDetailUrl = AppSetting.ReadApiUrl + "api/SchemeMaster/GetById/{id}";
  static SchemeDeleteUrl = AppSetting.ReadApiUrl + "api/SchemeMaster/Delete/{id}";
  static SchemeUpdateActiveStatusUrl = AppSetting.BaseApiUrl + "api/SchemeMaster/UpdateActiveStatus/{id}";
  static SchemeDropdownKeys = "ddlOrganization";
  //#endregion

  //Endregion

  //#region <NewProjectSoee>
  static NewProjectSoeeListUrl = AppSetting.ReadApiUrl + "api/NewProjectSoee/GetAll";
  static NewProjectSoeeAddUrl = AppSetting.BaseApiUrl + "api/NewProjectSoee/Add";
  static NewProjectSoeeEditUrl = AppSetting.BaseApiUrl + "api/NewProjectSoee/Update";
  static NewProjectSoeeGetByIdUrl = AppSetting.ReadApiUrl + "api/NewProjectSoee/GetById/{id}";
  static NewProjectSoeeDeleteUrl = AppSetting.ReadApiUrl + "api/NewProjectSoee/Delete/{id}";
  static NewProjectSoeeUpdateActiveStatusUrl = AppSetting.BaseApiUrl + "api/NewProjectSoee/UpdateActiveStatus/{id}";
  static NewProjectSoeeDropdownKeys = "ddlDepartment,ddlServiceType,ddlUnit";
  //Endregion

  //#region <Opening Balance>
  static OpeningBalanceListUrl = AppSetting.ReadApiUrl + "api/OpeningBalance/GetAll";
  static OpeningBalanceAddUrl = AppSetting.BaseApiUrl + "api/OpeningBalance/Add";
  static OpeningBalanceUpdateUrl = AppSetting.BaseApiUrl + "api/OpeningBalance/Update";
  static OpeningBalanceDetailUrl = AppSetting.ReadApiUrl + "api/OpeningBalance/GetById/{id}";
  static OpeningBalanceDeleteUrl = AppSetting.ReadApiUrl + "api/OpeningBalance/Delete/{id}";
  static OpeningBalanceUpdateActiveStatusUrl = AppSetting.BaseApiUrl + "api/OpeningBalance/UpdateActiveStatus/{id}";
  static OpeningBalanceDropdownKeys = "ddlProject,ddlFinancialYear";
  //#endregion

  //#region <Budget Head>
  static BudgetHeadListUrl = AppSetting.ReadApiUrl + "api/BudgetHead/GetAll";
  static BudgetHeadAddUrl = AppSetting.BaseApiUrl + "api/BudgetHead/Add";
  static BudgetHeadEditUrl = AppSetting.BaseApiUrl + "api/BudgetHead/Update";
  static BudgetHeadGetByIdUrl = AppSetting.ReadApiUrl + "api/BudgetHead/GetById/{id}";
  static BudgetHeadDeleteUrl = AppSetting.ReadApiUrl + "api/BudgetHead/Delete/{id}";
  static BudgetHeadUpdateActiveStatusUrl = AppSetting.BaseApiUrl + "api/BudgetHead/UpdateActiveStatus/{id}";

  static BudgetHeadDropdownKeys = "ddlProject,ddlDepartment";
  //#endregion

  //#region <Payment Head>
  static PaymentHeadListUrl = AppSetting.ReadApiUrl + "api/PaymentHead/GetAll";
  static PaymentHeadAddUrl = AppSetting.BaseApiUrl + "api/PaymentHead/Add";
  static PaymentHeadUpdateUrl = AppSetting.BaseApiUrl + "api/PaymentHead/Update";
  static PaymentHeadDetailUrl = AppSetting.ReadApiUrl + "api/PaymentHead/GetById/{id}";
  static PaymentHeadDeleteUrl = AppSetting.ReadApiUrl + "api/PaymentHead/Delete/{id}";
  static PaymentHeadUpdateActiveStatusUrl = AppSetting.BaseApiUrl + "api/PaymentHead/UpdateActiveStatus/{id}";
  static PaymentHeadDropdownKeys = "ddlProject,ddlDepartment,ddlBudgetPaymentHead,ddlBudgetHead";
  //#endregion


  //#region <Payment Initiate>
  static ProjectPaymentListUrl = AppSetting.ReadApiUrl + "api/ProjectPayment/GetAll";
  static ProjectPaymentGetByIdUrl = AppSetting.ReadApiUrl + "api/ProjectPayment/GetById/{id}";
  static ProjectPaymentDeleteUrl = AppSetting.ReadApiUrl + "api/ProjectPayment/Delete/{id}";
  static ProjectPaymentUpdateActiveStatusUrl = AppSetting.BaseApiUrl + "api/ProjectPayment/UpdateActiveStatus/{id}";

  static ProjectPaymentAddUrl = AppSetting.BaseApiUrl + "api/ProjectPayment/Add";
  static ProjectPaymentEditUrl = AppSetting.BaseApiUrl + "api/ProjectPayment/Update";

  static ProjectPaymentDropdownKeys = "ddlProject,ddlDepartment,ddlPaymentSubHead,ddlBudgetHead,ddlBankByVendor,ddlVendor,ddlBudgetPaymentHead,ddlFileNumber,ddlTaxType";


  //#endregion


  //#region <Fund Allocation>
  static FundAllocationListUrl = AppSetting.ReadApiUrl + "api/FundAllocation/GetAll";
  static FundAllocationAddUrl = AppSetting.BaseApiUrl + "api/FundAllocation/Add";
  static FundAllocationEditUrl = AppSetting.BaseApiUrl + "api/FundAllocation/Update";
  static FundAllocationGetByIdUrl = AppSetting.ReadApiUrl + "api/FundAllocation/GetById/{id}";
  static FundAllocationDeleteUrl = AppSetting.ReadApiUrl + "api/FundAllocation/Delete/{id}";
  static FundAllocationUpdateActiveStatusUrl = AppSetting.BaseApiUrl + "api/FundAllocation/UpdateActiveStatus/{id}";
  static FundAllocationDropdownKeys = "ddlProject,ddlDepartment,ddlFinancialYear,ddlBudgetPaymentHead,ddlPaymentSubHead,ddlBudgetHead";
  //#endregion

  //#region <Other Payment>
  static OtherPaymentAddUrl = AppSetting.BaseApiUrl + "api/OtherPayment/Add";
  static OtherPaymentDropdownKeys = "ddlProject,ddlDepartment,ddlPaymentSubHead,ddlBudgetHead,ddlBank,ddlVendor,ddlBudgetPaymentHead,ddlFileNumber,ddlTaxType";
  //#endregion <Other Payment>

  //#region <Payment Status>
  static PaymentStatusListUrl = AppSetting.ReadApiUrl + "api/PaymentStatus/GetAll";
  static PaymentStatusDropdownKeys = "ddlVendor";
  //#endregion

    //#region <ProjectWisePaymentReport>
    static ProjectWisePaymentReportListUrl = AppSetting.ReadApiUrl + "api/ProjectWisePaymentReport/GetAll";
    static ProjectWisePaymentReporDropdownKeys = "ddlProject";

    //#endregion

}


