import { Injectable } from '@angular/core';
import { AppSetting } from '../../model/appsetting.model';
import { IndexModel } from '../../model/common.model';
import { Dictionary } from '../../model/dictionary.model';
import { OfficeMasterPostModel } from '../../model/master/office-master.model';
import { BaseService } from '../base.service';
import { OfficeMasterFilterModel } from '../../model/master/office-master-filter.model';
import { DepartmentOfficeFilterSearchModel } from '../../model/master/department-office-filter-search.model';

@Injectable({
  providedIn: 'root'
})
export class OfficeMasterService {
  loginUserCode: number;

  constructor(private readonly _baseService: BaseService,) { }

  GetList(model: OfficeMasterFilterModel) {
    return this._baseService.post(AppSetting.OfficeMasterListUrl, model);
  }

  GetDepartmentOfficeList(loginUserCode: number, model: DepartmentOfficeFilterSearchModel) {
    return this._baseService.post(AppSetting.DepartmentOfficeMasterListUrl + "?loginUserCode=" + loginUserCode, model);
  }


  GetOfficeMasterPDFDownload(loginUserCode: number, model: IndexModel) {
    return this._baseService.post(AppSetting.DepartmentOfficeMasterPDFListUrl + "?loginUserCode=" + loginUserCode, model);
  }

  AddUpdate(model: OfficeMasterPostModel) {
    return this._baseService.post(AppSetting.OfficeMasterAddUrl, model);
  }

  Detail(id: number) {
    return this._baseService.get(AppSetting.OfficeMasterDetailUrl + id);
  }

  ChangeDeleteStatus(id: number) {
    return this._baseService.get(AppSetting.OfficeDeleteStatusChangeUrl + id);
  }

  ChangeActiveStatus(id: number) {
    return this._baseService.get(AppSetting.OfficeActiveStatusChangeUrl + id);
  }

  GetOfficeListByIds(ids: string) {
    var param = new Dictionary<any>();
    param.Add("ids", ids)
    return this._baseService.get(AppSetting.GetOfficeListByIdsUrl, param);
  }

  GetUserOfficeList(departmentCode: any = 0, districtCode: any = 0, loginUserCode = this.loginUserCode) {
    var param = new Dictionary<any>();
    param.Add("userCode", loginUserCode);
    param.Add("departmentCode", departmentCode);
    param.Add("districtCode", districtCode);
    return this._baseService.get(AppSetting.GetUserOfficeDDlUrl, param);
  }

  GetUserDepartmentList(adminDepartmentCode: any = 0, isNonExistOffice = false, loginUserCode = this.loginUserCode) {
    var param = new Dictionary<any>();
    param.Add("userCode", loginUserCode);
    param.Add("adminDepartmentCode", adminDepartmentCode);
    param.Add("isNonExistOffice", isNonExistOffice);
    return this._baseService.get(AppSetting.GetUserDepartmentDDlUrl, param);
  }


  GetUserDistrictForDepartmentOffice(stateCodes: string, deptCodes: string, isOfficeExist = false) {
    var param = new Dictionary<any>();
    param.Add("userCode", this.loginUserCode);
    param.Add("stateCodes", stateCodes);
    param.Add("deptCodes", deptCodes);
    param.Add("isOfficeExist", isOfficeExist);
    return this._baseService.get(AppSetting.GetUserDistrictForUserOfficeCreationUrl, param);
  }

}