import { isNullOrUndefined } from 'util';
import { GlobalFilterSearchModel } from './../../shared/model/Global-communication-search.model';
import { Component, OnInit, OnDestroy, ViewChild } from "@angular/core";
import { AppComponent } from "src/app/app.component";
import { CommonService } from 'src/app/shared/service/common.service';
import { DashboardService } from 'src/app/shared/service/dashboard.service';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from '../../shared/service/authentication.service';
import { StatusEnum, UserTypeEnum } from '../../shared/enum/fixed-values.enum';
import { FormBuilder } from '@angular/forms';
import { DashBoardFilterModel } from 'src/app/shared/model/dashboard-filter.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { FilterComponent } from './filter/filter.component';
import { MatDialog } from '@angular/material';

import { DashboardHelpDocumentComponent } from "./dashboard-help-document/dashboard-help-document.component";
import { DashBoardModel, DashboardStatusModel, DepartmentCommunicationDisplayStatusCountViewModel, DepartmentStatusCountModel, ToDoListCountModel, VendorCommunicationDisplayStatusCountViewModel, VendorStatusCountModel } from "src/app/shared/model/dashboard.model";
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { CommunicationService } from 'src/app/shared/service/communication.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit, OnDestroy {
  StatusEnum: StatusEnum;
  loginBaseUserType: string;
  filterModel: DashBoardFilterModel = new DashBoardFilterModel();
  dashboardModel: DashBoardModel = new DashBoardModel();
  statsModel: DashboardStatusModel = new DashboardStatusModel();

  loginUserDetail: UserViewModel;
  isDestroy = false;
  isShown: boolean = false;

  isShownCommStatusWiseAgeCount: boolean = false;
  isShownCommTypeWiseAgeCount: boolean = false;
  isShownCommProjectWiseAgeCount: boolean = false;
  isShownCommVendorWiseAgeCount: boolean = false;
  isShownCommTypeWiseAgeCount_NoActionTaken: boolean = false;
  isShownCommDepartmentWiseAgeCount: boolean = false;
  isShownToDoList: boolean = false;
  isShownVendorStatus: boolean = false;
  isShownDepartmentStatus: boolean = false;

  isShownToDo_SMSReceived: boolean = true;
  isShownToDo_RecFromVendor_NoActionTaken: boolean = true;
  isShownToDo_VendorReqForActivation: boolean = true;
  isShownToDo_ProjNotAssignToOfficer: boolean = true;
  isShownToDo_BlacklistedVendor: boolean = true;
  isShownToDo_WorkOrderPendingForAccept: boolean = true;
  isShownToDo_Alert: boolean = true;
  isShownToDo_ProjNotAssignToVendorUser: boolean = true;
  isShownToDo_BlacklistedVendorUser: boolean = true;
  globalSearchModel = this._commonService.modelSetGet(new GlobalFilterSearchModel()) as GlobalFilterSearchModel;
  @ViewChild(FilterComponent, { static: true }) popup: FilterComponent;
  userTypeEnum = UserTypeEnum;
  constructor(private readonly _appComponet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _authService: AuthenticationService,
    private readonly _dashboardService: DashboardService,
    private readonly _communicationService: CommunicationService,
    private readonly fb: FormBuilder,
    private _dialog: MatDialog) {

    if (sessionStorage.getItem("MenuName") == undefined || sessionStorage.getItem("MenuName") != "Dashboard") {
      sessionStorage.setItem("MenuName", 'Dashboard')
    }

    this._appComponet.setPageLayout(" Dashboard", "", "", "", false, true);
    this.loginBaseUserType = _authService.BaseUserType;
    const userTemp = this._authService.GetCurrentUserDetail();
    if (userTemp) {
      this.loginUserDetail = userTemp.UserViewModel;
      this.filterModel.LoginUserCode = this._authService.UserCode;
    }
    this.dashboardShowHide();
    this.BindDashboardStatusData();
  }

  ngOnInit() {
    this._commonService.isResetGlobalFilter.subscribe(res => {
      if (res) {
        this.BindDashboardStatusData();
      }
    });

  }

  toggleShow() {
    this.isShown = !this.isShown;
  }

  BindDashboardStatusData() {

    this.setGlobalFilterCriteria();
    this.bindDashboardStatusData();
    //Vendor and department communication type

    setTimeout(() => {

      this.GetVendorStatusCountData();
      this.GetDepartmentStatuCountData();
    }, 50);

    setTimeout(() => {
      this.GetDepartmentToDoData();
      this.bindUserDashboardData();
    }, 100);

  }


  bindDashboardStatusData() {

    this._dashboardService.GetDepartmentDashboardCountData(this.globalSearchModel).subscribe(
      data => {
        if (data.IsSuccess) {
          let response = <DashboardStatusModel>data.Data;
          this.statsModel.VendorCommunicationStausCount = <VendorCommunicationDisplayStatusCountViewModel>response.VendorCommunicationStausCount;
          this.statsModel.DepartmentCommunicatoinStausCount = <DepartmentCommunicationDisplayStatusCountViewModel>response.DepartmentCommunicatoinStausCount;
          this.statsModel.VendorCommunicationPermission = response.VendorCommunicationPermission;
        }
        else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  GetVendorStatusCountData() {

    if (this.isShownVendorStatus) {

      this._dashboardService.GetVendorStatusCountData().subscribe(
        data => {
          if (data.IsSuccess) {
            let response = <DashboardStatusModel>data.Data;
            this.statsModel.VendorStatusCount = <VendorStatusCountModel>response.VendorStatusCount;

          }
          else {
            this._alertService.error(data.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  GetDepartmentStatuCountData() {
    if (this.isShownDepartmentStatus) {
      this._dashboardService.GetDepartmentStatuCountData().subscribe(
        data => {
          if (data.IsSuccess) {
            let response = <DashboardStatusModel>data.Data;
            this.statsModel.DepartmentStatusCount = <DepartmentStatusCountModel>response.DepartmentStatusCount;

          }
          else {
            this._alertService.error(data.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  GetDepartmentToDoData() {
    if (this.isShownToDoList) {
      this._dashboardService.GetDepartmentToDoData().subscribe(
        data => {
          if (data.IsSuccess) {
            let response = <DashboardStatusModel>data.Data;
            this.statsModel.TodoListCount = <ToDoListCountModel>response.TodoListCount;

          }
          else {
            this._alertService.error(data.Message);
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }



  bindUserDashboardData() {

    this.filterModel.LoginUserCode = this.loginUserDetail.UserCode;

    this._dashboardService.GetUserDashboardData(this.filterModel).subscribe(
      data => {

        if (data.IsSuccess) {
          this.dashboardModel = <DashBoardModel>data.Data;
        }
        else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  ngOnDestroy() {
    this.isDestroy = true;
    this.isShown = false;
  }

  addUpdatefilterDialog() {
    const _dialogRef = this._dialog.open(FilterComponent, {
      width: "1200px",
      // data: IsUpdate ? { Id: this._commonService.IsNullOrEmpty(this.dashbordfilterModel.FromDate) ? null : this.dashbordfilterModel.ToDate } : null,
    });
    _dialogRef.afterClosed().subscribe((result: DashBoardFilterModel) => {
      if (result) {
        this.filterModel = result;
        this.bindUserDashboardData();
      }
    });
  }

  getStatusName(status: string) {

    var _status = status;

    if (status == StatusEnum.ACCEPT) { _status = "PENDING FOR FORWARD TO OFFICER"; }
    else if (status == StatusEnum.REJECT) { _status = "REJECTED"; }
    else if (status == StatusEnum.CLOSE) {
      _status = "CLOSED";
    }
    else if (status == "MOVE") {
      _status = "IN PROCESS WITH OFFICE";
    }
    else if (status == StatusEnum.DISPOSED) {
      _status = "DISPOSED";
    }
    else if (status == StatusEnum.REOPEN) {
      _status = "Re-Open";
    }
    else if (status == "NONASSIGNED") {
      _status = "Non-Assigned";
    }
    else if (status == "NONMOVE") {
      _status = "PENDING FOR FORWARD TO OFFICER";
    }

    else if (this.loginBaseUserType == this.userTypeEnum.VNDR || this.loginBaseUserType == this.userTypeEnum.VNDRUSER) {
      if (status == StatusEnum.RECEIVED) { _status = "PENDING WITH DEPARTMENT"; }
      if (status == StatusEnum.RETURN) { _status = "MISSING INFORMATION"; }
      // if (status == StatusEnum.INPROCESS) { _status = "IN PROCESS WITH OFFICE"}
    }
    else if (this.loginBaseUserType == this.userTypeEnum.HOD || this.loginBaseUserType == this.userTypeEnum.HOO
      || this.loginBaseUserType == this.userTypeEnum.DREC || this.loginBaseUserType == this.userTypeEnum.DOS || this.loginBaseUserType == this.userTypeEnum.HOOADLC || this.loginBaseUserType == this.userTypeEnum.DRECADLC || this.loginBaseUserType == this.userTypeEnum.DOSADLC) {
      if (status == StatusEnum.RECEIVED) { _status = "PENDING FOR PROCESSING"; }
      if (status == StatusEnum.RETURN) { _status = "PENDING WITH VENDORS"; }

    }
    return _status;
  }

  PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "DashBord");
  }


  setGlobalFilterCriteria() {
    if (this._commonService.GetLocalStorage("IsGlobalSearch") != null && JSON.parse(this._commonService.GetLocalStorage("IsGlobalSearch")) == 1 && this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch")) {
      this.globalSearchModel = JSON.parse(this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch")) as GlobalFilterSearchModel;

    } else {
      this.globalSearchModel = this._commonService.modelSetGet(new GlobalFilterSearchModel()) as GlobalFilterSearchModel;
    }

  }
  dashboardShowHide() {

    if (this.loginBaseUserType == this.userTypeEnum.HOD) {
      this.isShownCommStatusWiseAgeCount = true;
      this.isShownCommTypeWiseAgeCount = true;
      this.isShownCommProjectWiseAgeCount = true;
      this.isShownCommVendorWiseAgeCount = true;
      this.isShownToDoList = true;
      this.isShownVendorStatus = true;

      this.isShownToDo_WorkOrderPendingForAccept = false;
      this.isShownToDo_Alert = false;
      this.isShownToDo_ProjNotAssignToVendorUser = false;
      this.isShownToDo_BlacklistedVendorUser = false;
    }
    else if (this.loginBaseUserType == this.userTypeEnum.HOO || this.loginBaseUserType == this.userTypeEnum.HOOADLC) {
      this.isShownCommStatusWiseAgeCount = true;
      this.isShownCommTypeWiseAgeCount = true;
      this.isShownToDoList = true;
      this.isShownVendorStatus = true;

      this.isShownToDo_WorkOrderPendingForAccept = false;
      this.isShownToDo_Alert = false;
      this.isShownToDo_ProjNotAssignToVendorUser = false;
      this.isShownToDo_BlacklistedVendorUser = false;
    }
    else if (this.loginBaseUserType == this.userTypeEnum.DREC || this.loginBaseUserType == this.userTypeEnum.DRECADLC) {
      this.isShownCommStatusWiseAgeCount = true;
      this.isShownCommTypeWiseAgeCount = true;
      this.isShownToDoList = true;
      this.isShownVendorStatus = true;

      this.isShownToDo_VendorReqForActivation = false;
      this.isShownToDo_WorkOrderPendingForAccept = false;
      this.isShownToDo_Alert = false;
      this.isShownToDo_ProjNotAssignToVendorUser = false;
      this.isShownToDo_BlacklistedVendorUser = false;
    }
    else if (this.loginBaseUserType == this.userTypeEnum.DOS || this.loginBaseUserType == this.userTypeEnum.DOSADLC) {
      this.isShownCommStatusWiseAgeCount = true;
      this.isShownCommTypeWiseAgeCount = true;
      this.isShownToDoList = true;
      this.isShownVendorStatus = true;

      this.isShownToDo_VendorReqForActivation = false;
      this.isShownToDo_BlacklistedVendor = false;
      this.isShownToDo_Alert = false;
      this.isShownToDo_ProjNotAssignToVendorUser = false;
      this.isShownToDo_BlacklistedVendorUser = false;
    }
    else if (this.loginBaseUserType == this.userTypeEnum.VNDR) {
      this.isShownCommStatusWiseAgeCount = true;
      this.isShownCommTypeWiseAgeCount_NoActionTaken = true;
      this.isShownCommDepartmentWiseAgeCount = true;
      this.isShownToDoList = true;
      this.isShownDepartmentStatus = true;

      this.isShownToDo_RecFromVendor_NoActionTaken = false;
      this.isShownToDo_VendorReqForActivation = false;
      this.isShownToDo_ProjNotAssignToOfficer = false;
      this.isShownToDo_WorkOrderPendingForAccept = false;
      this.isShownToDo_BlacklistedVendorUser = false;
    }
    else if (this.loginBaseUserType == this.userTypeEnum.VNDRUSER) {
      this.isShownCommStatusWiseAgeCount = true;
      this.isShownCommTypeWiseAgeCount_NoActionTaken = true;
      this.isShownCommDepartmentWiseAgeCount = true;
      this.isShownToDoList = true;
      this.isShownDepartmentStatus = true;

      this.isShownToDo_RecFromVendor_NoActionTaken = false;
      this.isShownToDo_VendorReqForActivation = false;
      this.isShownToDo_ProjNotAssignToOfficer = false;
      this.isShownToDo_BlacklistedVendor = false;
      this.isShownToDo_WorkOrderPendingForAccept = false;
      this.isShownToDo_ProjNotAssignToVendorUser = false;
    }
  }

  public onClearCommunication(){
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.clearCommunication });
    dialogRef.afterClosed().subscribe(result => {
      if (result == true) {
        this._communicationService.clearCommunication().subscribe(response => {
          if (response.IsSuccess == false){
            this._alertService.error(response.Message);

            return;
          }

          this._alertService.success(response.Message);
          location.reload();
        });        
      }
    });
  }

}


