import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSetting } from '../../model/appsetting.model';
import { BaseService } from '../base.service';

@Injectable()
export class OfficerPreferredListService {

  constructor(
    public readonly _baseService: BaseService
  ) { }

  GetList(preferredListValue: number, loginUserCode: number): Observable<any> {
    const url = `${AppSetting.BaseApiUrl}User/PreferredList/GetPreferredList?preferredList=${preferredListValue}&loginUserCode=${loginUserCode}`;
    return this._baseService.get(url);
  }
}
