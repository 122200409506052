import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSetting } from '../../model/appsetting.model';
import { VendorAddRequestModel } from '../../model/preferred-list/vendor-add-request.model';
import { VendorListRequestModel } from '../../model/preferred-list/vendor-list-request.model';
import { BaseService } from '../base.service';

@Injectable()
export class VendorListService {

  constructor(
    private readonly _baseService: BaseService
  ) { }

  GetList(model: VendorListRequestModel): Observable<any> {
    const url = AppSetting.GetAllVendorList;
    return this._baseService.post(url, model);
  }

  AddAllVendor(model: VendorAddRequestModel): Observable<any> {
    const url = AppSetting.AddAllVendor;
    return this._baseService.post(url, model);
  }
}
