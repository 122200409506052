import { PreviousCommunicationListComponent } from './content/communication/previous-communication-list/previous-communication-list.component';
import { DetailStatusMasterComponent } from './content/master/status-master/detail-status-master/detail-status-master.component';
import { AddUpdateStatusMasterComponent } from './content/master/status-master/add-update-status-master/add-update-status-master.component';
import { StatusMasterComponent } from './content/master/status-master/status-master.component';
import { PrintHelperComponent } from './helper/print-helper/print-helper.component';
import { LocateCommunicationDetailComponent } from './content/communication/locate-communication/locate-communication-detail/locate-communication-detail.component';
import { AddUpdateAdditionalPermissionComponent } from './content/master/permission/additional-permission/add-update-additional-permission/add-update-additional-permission.component';
import { ProjectCategoryDetailComponent } from './content/master/project-category/project-category-detail/project-category-detail.component';
import { AddUpdateProjectCategoryComponent } from './content/master/project-category/add-update-project-category/add-update-project-category.component';
import { ProjectCategoryComponent } from './content/master/project-category/project-category.component';
import { ImportExcelDataComponent } from './content/import-excel-data/import-excel-data.component';
import { ComplaintSoftwareComponent } from './content/complaint-software/complaint-software.component';
import { AddUpdatePermissionMasterComponent } from './content/master/permission-master/add-update-permission-master/add-update-permission-master.component';
import { PermissionMasterComponent } from './content/master/permission-master/permission-master.component';
import { DepartmentContactDetailsNewComponent } from './content/master/department-contact-details-new/department-contact-details-new.component';
import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthGuardService } from "./helper/auth-guard.service";
import { DashboardComponent } from "./content/dashboard/dashboard.component";
import { VendorTypeComponent } from "./content/master/vendor-type/vendor-type.component";
import { AddUpdateVendorTypeComponent } from "./content/master/vendor-type/add-update-vendor-type/add-update-vendor-type.component";
import { VendorTypeDetailComponent } from "./content/master/vendor-type/vendor-type-detail/vendor-type-detail.component";
import { CommunicationTypeComponent } from "./content/master/communication-type/communication-type.component";
import { AddUpdateCommunicationTypeComponent } from "./content/master/communication-type/add-update-communication-type/add-update-communication-type.component";
import { CommunicationTypeDetailComponent } from "./content/master/communication-type/communication-type-detail/communication-type-detail.component";
import { CommunicationAttachmentTypeComponent } from "./content/master/communication-attachment-type/communication-attachment-type.component";
import { AddUpdateCommunicationAttachmentTypeComponent } from "./content/master/communication-attachment-type/add-update-communication-attachment-type/add-update-communication-attachment-type.component";
import { CommunicationAttachmentTypeDetailComponent } from "./content/master/communication-attachment-type/communication-attachment-type-detail/communication-attachment-type-detail.component";
import { VendorComponent } from "./content/vendor/vendor.component";
import { VendorDetailComponent } from "./content/vendor/vendor-detail/vendor-detail.component";
import { BankAccountComponent } from "./content/vendor/bank-account/bank-account.component";
import { WorkOrderComponent } from "./content/work-order/work-order.component";
import { AddUpdateWorkOrderComponent } from "./content/work-order/add-update-work-order/add-update-work-order.component";
import { WorkOrderDetailComponent } from "./content/work-order/work-order-detail/work-order-detail.component";
import { CommunicationComponent } from "./content/communication/communication.component";
import { CommunicationDetailComponent } from "./content/communication/communication-detail/communication-detail.component";
import { UserComponent } from "./content/user/user.component";
import { ActionHistoryComponent } from "./content/communication/action-history/action-history.component";
import { LoginComponent } from "./login/login.component";
import { ProjectComponent } from './content/project/project.component';
import { AddUpdateProjectComponent } from './content/project/add-update-project/add-update-project.component';
import { ProjectDetailComponent } from './content/project/project-detail/project-detail.component';
import { InboxComponent } from './content/inbox/inbox.component';
import { OutboxComponent } from './content/outbox/outbox.component';
import { OutboxDetailComponent } from './content/outbox/outbox-detail/outbox-detail.component';
import { InboxDetailComponent } from './content/inbox/inbox-detail/inbox-detail.component';
import { ReasonTypeComponent } from './content/master/reason-type/reason-type.component';
import { AddUpdateReasonTypeComponent } from './content/master/reason-type/add-update-reason-type/add-update-reason-type.component';
import { ReasonTypeDetailComponent } from './content/master/reason-type/reason-type-detail/reason-type-detail.component';
import { UpdateVendorProfileComponent } from './content/vendor/update-vendor-profile/update-vendor-profile.component';
import { VendorUserComponent } from './content/vendor-user/vendor-user.component';
import { ComplaintTypeComponent } from './content/master/complaint-type/complaint-type.component';
import { AddUpdateComplaintTypeComponent } from './content/master/complaint-type/add-update-complaint-type/add-update-complaint-type.component';
import { ComplaintTypeDetailComponent } from './content/master/complaint-type/complaint-type-detail/complaint-type-detail.component';
import { RefundRequestReasonComponent } from './content/master/refund-request-reason/refund-request-reason.component';
import { AddUpdateRefundRequestReasonComponent } from './content/master/refund-request-reason/add-update-refund-request-reason/add-update-refund-request-reason.component';
import { RefundRequestReasonDetailComponent } from './content/master/refund-request-reason/refund-request-reason-detail/refund-request-reason-detail.component';
import { ConcernComponent } from './content/master/concern/concern.component';
import { AddUpdateConcernComponent } from './content/master/concern/add-update-concern/add-update-concern.component';
import { ConcernDetailComponent } from './content/master/concern/concern-detail/concern-detail.component';
import { SuggestionForComponent } from './content/master/suggestion-for/suggestion-for.component';
import { AddUpdateSuggestionForComponent } from './content/master/suggestion-for/add-update-suggestion-for/add-update-suggestion-for.component';
import { SuggestionForDetailComponent } from './content/master/suggestion-for/suggestion-for-detail/suggestion-for-detail.component';
import { DepartmentReportComponent } from './reports/master/department-report/department-report.component';
import { VendorReportComponent } from './reports/master/vendor-report/vendor-report.component';
import { CommunicationTypeReportComponent } from './reports/master/communication-type-report/communication-type-report.component';
import { DepartmentOfficeReportComponent } from './reports/master/department-office-report/department-office-report.component';
import { ReasonTypeReportComponent } from './reports/master/reason-type-report/reason-type-report.component';
import { AttachmentTypeReportComponent } from './reports/master/attachment-type-report/attachment-type-report.component';
import { ComplaintTypeReportComponent } from './reports/master/complaint-type-report/complaint-type-report.component';
import { RefundRequestReasonReportComponent } from './reports/master/refund-request-reason-report/refund-request-reason-report.component';
import { ConcernReportComponent } from './reports/master/concern-report/concern-report.component';
import { SuggestionReportComponent } from './reports/master/suggestion-report/suggestion-report.component';
import { ComplaintReportComponent } from './reports/communication/complaint-report/complaint-report.component';
import { EmdReturnReportComponent } from './reports/communication/emd-return-report/emd-return-report.component';
//Rewant
import { ProjectReportComponent } from './reports/master/project-report/project-report.component';
import { WorkOrderReportComponent } from './reports/master/work-order-report/work-order-report.component';
import { VendorListReportComponent } from './reports/master/vendor-list-report/vendor-list-report.component';
import { GeneralReportComponent } from './reports/communication/general-report/general-report.component';
import { SuggestionsReportComponent } from './reports/communication/suggestions-report/suggestions-report.component';
//import { SuggestionReportComponent } from './reports/communication/suggestion-report/suggestion-report.component';
import { InvoiceReportComponent } from './reports/communication/invoice-report/invoice-report.component';
import { UserReportComponent } from './reports/user/user-report.component';
import { VendorUserReportComponent } from './content/report/vendor-user-report/vendor-user-report.component';
import { NewOldCommunicationReportComponent } from './content/report/new-old-communication-report/new-old-communication-report.component';
import { TemporaryLoginComponent } from './temporary-login/temporary-login.component';
import { AgeWiseCommunicationComponent } from './content/report/age-wise-communication/age-wise-communication.component';
import { UserManualComponent } from './content/master/user-manual/user-manual.component';
import { OfficeStaffReportComponent } from './reports/master/office-staff-report/office-staff-report.component';
import { ProjectMappingComponent } from './content/project-mapping/project-mapping.component';
import { LoginUserCreationComponent } from './content/login-user-creation/login-user-creation.component';
import { MasterDataComponent } from './content/master/master-data/master-data.component';
import { AddUpdateMasterDataComponent } from './content/master/master-data/add-update-master-data/add-update-master-data.component';
import { MasterDataDetailComponent } from './content/master/master-data/master-data-detail/master-data-detail/master-data-detail.component';
import { CommunicationFieldsComponent } from "./content/master/communication-fields/communication-fields/communication-fields.component";
import { AddUpdateCommunicationTransactionComponent } from "./content/communication/add-update-communication-transaction/add-update-communication-transaction.component";
import { CommunicationTransactionDetailComponent } from "./content/communication/communication-transaction-detail/communication-transaction-detail.component";
import { AdminDepartmentComponent } from "./content/master/admin-department/admin-department.component";
import { AddUpdateAdminDepartmentComponent } from "./content/master/admin-department/add-update-admin-department/add-update-admin-department.component";
import { AddUpdateDesignationMasterComponent } from "./content/master/designation-master/add-update-designation-master/add-update-designation-master.component";
import { DesignationMasterComponent } from "./content/master/designation-master/designation-master.component";

import { DetailOfficeMasterComponent } from "./content/master/office-master/detail-office-master/detail-office-master.component";
import { DepartmentMasterNewComponent } from './content/master/department-master-new/department-master-new.component';
import { AddUpdateDepartmentMasterNewComponent } from './content/master/department-master-new/add-update-department-master-new/add-update-department-master-new.component';
import { DetailDepartmentMasterNewComponent } from './content/master/department-master-new/detail-department-master-new/detail-department-master-new.component';
import { AddUpdateDepartmentContactDetailsComponent } from './content/master/department-contact-details-new/add-update-department-contact-details/add-update-department-contact-details.component';
import { AddUpdateUserComponent } from './content/user-creation/add-update-user/add-update-user.component';
import { UserCreationComponent } from './content/user-creation/user-creation.component';
import { OfficeMasterComponent } from "./content/master/office-master/office-master.component";
import { AddUpdateOfficeMasterComponent } from "./content/master/office-master/add-update-office-master/add-update-office-master.component";
import { UserTypeMasterComponent } from './content/master/user-type-master/user-type-master.component';
import { DetailDepartmentContactDetailsComponent } from './content/master/department-contact-details-new/detail-department-contact-details/detail-department-contact-details.component';
import { AddUpdateEmailComponent } from './content/master/notification-email-templates/add-update-email/add-update-email.component';
import { NotificationEmailTemplatesComponent } from './content/master/notification-email-templates/notification-email-templates.component';
import { AddUpdateSmsComponent } from './content/master/notification-sms-templates/add-update-sms/add-update-sms.component';
import { NotificationSmsTemplatesComponent } from './content/master/notification-sms-templates/notification-sms-templates.component';
import { NotificationTemplateTypeComponent } from './content/master/notification-template-type/notification-template-type/notification-template-type.component';
import { UserNotificationComponent } from './content/user/user-notification/user-notification.component';
import { UserDefaultPermissionComponent } from './content/master/permission/user-default-permission/user-default-permission.component';
import { SpecificUserPermissionComponent } from './content/master/permission/specific-user-permission/specific-user-permission.component';
import { AddUpdateComplaintComponent } from './content/complaint-software/add-update-complaint/add-update-complaint.component';
import { MasterReportModel } from './shared/model/master-reports.modal';
import { MasterReportsComponent } from './reports/masterreport/master-reports.component';
import { MasterTypeDataComponent } from './content/master/master-type-data/master-type-data.component';
import { AddUpdateMasterTypeDataComponent } from './content/master/master-type-data/add-update-master-type-data/add-update-master-type-data.component';
import { ProjectSubCategoryDetailComponent } from './content/master/project-sub-category/project-sub-category-detail/project-sub-category-detail.component';
import { AddUpdateProjectSubCategoryComponent } from './content/master/project-sub-category/add-update-project-sub-category/add-update-project-sub-category.component';
import { ProjectSubCategoryComponent } from './content/master/project-sub-category/project-sub-category.component';
import { NotificationComponent } from './content/notification/notification.component';
import { LocateCommunicationComponent } from './content/communication/locate-communication/locate-communication.component';
import { WorkOrderCategoryComponent } from './content/master/work-order-category/work-order-category.component';
import { AddUpdateWorkOrderCategoryComponent } from './content/master/work-order-category/add-update-work-order-category/add-update-work-order-category.component';
import { WorkOrderCategoryDetailComponent } from './content/master/work-order-category/work-order-category-detail/work-order-category-detail.component';
import { AddUpdateWorkOrderSubCategoryComponent } from './content/master/work-order-sub-category/add-update-work-order-sub-category/add-update-work-order-sub-category.component';
import { WorkOrderSubCategoryDetailComponent } from './content/master/work-order-sub-category/work-order-sub-category-detail/work-order-sub-category-detail.component';
import { WorkOrderSubCategoryComponent } from './content/master/work-order-sub-category/work-order-sub-category.component';
import { AdditionalPermissionComponent } from './content/master/permission/additional-permission/addtional-permission.component';
import { GlobalCommunicationSearchComponent } from './global-communication-search/global-communication-search.component';
import { GeneralEntryCategoryMasterComponent } from './content/master/GeneralEntryMaster/general-entry-category-master/general-entry-category-master.component';
import { AddUpdateGeneralEntryCategoryMasterComponent } from './content/master/GeneralEntryMaster/general-entry-category-master/add-update-general-entry-category-master/add-update-general-entry-category-master.component';
import { GeneralEntrySubCategoryMasterComponent } from './content/master/GeneralEntryMaster/general-entry-sub-category-master/general-entry-sub-category-master.component';
import { AddUpdateGeneralEntrySubCategoryMasterComponent } from './content/master/GeneralEntryMaster/general-entry-sub-category-master/add-update-general-entry-sub-category-master/add-update-general-entry-sub-category-master.component';
import { GeneralEntryComponent } from './content/general-entry/general-entry.component';
import { AddUpdateGeneralEntryComponent } from './content/general-entry/add-update-general-entry/add-update-general-entry.component';
import { DetailGeneralEntryComponent } from './content/general-entry/detail-general-entry/detail-general-entry.component';
import { AddUpdateB2GUserManualComponent } from './content/master/b2-guser-manual/add-update-b2-guser-manual/add-update-b2-guser-manual.component';
import { B2GUserManualComponent } from './content/master/b2-guser-manual/b2-guser-manual.component';
import { DashboardCommunicationListComponent } from './content/dashboard/dashboard-communication-list/dashboard-communication-list.component';
import { UpdateProfileComponent } from './content/user-creation/update-profile/update-profile.component';
import { SMSTemplateMappingComponent } from './content/master/notification-sms-templates/smstemplate-mapping/smstemplate-mapping.component';
import { AddUpdateSMSTemplateMappingDialogComponent } from './content/master/notification-sms-templates/smstemplate-mapping/add-update-smstemplate-mapping-dialog/add-update-smstemplate-mapping-dialog.component';
import { StatisticalReportsComponent } from './content/report/statistical-reports/statistical-reports.component';
import { ComplaintSoftwareDetailComponent } from './content/complaint-software/complaint-software-detail/complaint-software-detail.component';
import { UserActivityLogComponent } from './content/master/user-activity-log/user-activity-log.component';
import { ProjectGroupComponent } from './content/project/project-group/project-group.component';
import { ProjectGroupDetailComponent } from './content/project/project-group/project-group-detail/project-group-detail.component';
import { AddUpdateProjectGroupComponent } from './content/project/project-group/add-update-project-group/add-update-project-group.component';
import { PreferenceListComponent } from './content/B2G/preference-list/preference-list.component';
import { LogReportComponent } from './reports/log-report/log-report.component';
import { LookupReportComponent } from './reports/lookup-report/lookup-report.component';
import { AddUpdatePreferenceComponent } from './content/B2G/preference-list/add-update-preference/add-update-preference.component';
import { AddCCForCommunicationComponent } from './content/communication/add-update-communication-transaction/add-ccfor-communication/add-ccfor-communication.component';
import { DashboardDrillDownComponent } from './content/dashboard/dashboard-drill-down/dashboard-drill-down.component';
import { StatusComponent } from './content/complaint-software/Masters/status/status.component';
import { EntryTypeComponent } from './content/complaint-software/Masters/entry-type/entry-type.component';
import { DistrictComponent } from './content/master/district/district.component';
import { DistrictDetailComponent } from './content/master/district/district-detail/district-detail.component';
import { AddUpdateDistrictComponent } from './content/master/district/add-update-district/add-update-district.component';
import { LocateUserComponent } from './content/user-creation/locate-user/locate-user.component';
import { ContactUsComponent } from './content/contactus/contact-us/contact-us.component';
import { ApplicationDataUtilityComponent } from './content/Utility/application-data-utility/application-data-utility.component';
import { StatisticalReportDrillDownComponent } from './content/report/statistical-reports/statistical-report-drill-down/statistical-report-drill-down.component';
import { VendorCommunicationPermissionComponent } from './content/master/vendor-communication-permission/vendor-communication-permission.component';
import { VendorPermissionDelegationComponent } from './content/communication/vendor-permission-delegation/vendor-permission-delegation.component';
import { ChiefMinisterProfileComponent } from './content/master/chiefminister-profile/chiefminister-profile.component';
import { AddUpdateChiefMinisterProfileComponent } from './content/master/chiefminister-profile/add-update-chiefminister-profile/add-update-chiefminister-profile.component';
import { PreferredListComponent } from './content/preferred-list/preferred-list.component';
import { SecondaryDashboardComponent } from './secondary-dashboard/secondary-dashboard.component';
import { ProjectWiseCommunicationReportComponent } from './reports/communication/project-wise-communication-report/project-wise-communication-report.component';
import { MeetingComponent } from './external/meeting/meeting.component';
import { MeetingAddUpdateComponent } from './external/meeting/meeting-add-update/meeting-add-update.component';
//import { ProjectComponent } from './content/project/project.component';


const routes: Routes = [
  {
    path: "",
    //  component: DashboardComponent,
    pathMatch: "full",
    canActivate: [AuthGuardService],
    redirectTo: "dashboard"
  },

  {
    path: "print",

    pathMatch: "full",

    redirectTo: "print"
  },
  {
    path: "print",
    component: PrintHelperComponent
  },
  //Normal build  uncomment this line
  //{ path: "login", component: LoginComponent, canActivate: [AuthGuardService] },//17/12/2020
  { path: "login/emitra", component: LoginComponent },
  { path: "login", component: LoginComponent },

  { path: "templogin", component: TemporaryLoginComponent, pathMatch: "full" },
  {
    path: "dashboard",
    component: SecondaryDashboardComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "Dashboard",
    component: DashboardComponent,
    canActivate: [AuthGuardService],
  }, {
    path: "communications-list/:typeEnum",
    component: CommunicationComponent,
    canActivate: [AuthGuardService],
  }, {
    path: "communications-list/add-update-communication-transaction/:typeEnum/:Id",
    component: AddUpdateCommunicationTransactionComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "communications-list/communication-detail/:id",
    component: CommunicationTransactionDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "communications-list/communication-detail/:id/:redirectFrom",
    component: CommunicationTransactionDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "communications-list/communication-detail/:id/:redirectFrom/:routerStatusParams",
    component: CommunicationTransactionDetailComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "communications-list/communication-detail/:id/:redirectFrom/:type/:para1/:para2/:para3/:para4",
    component: CommunicationTransactionDetailComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "communications-list/action-history/:typeEnum/:id",
    component: ActionHistoryComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "communications-list/action-history/:typeEnum/:id/:from/:status/:isVendorCommunication",
    component: ActionHistoryComponent,
    canActivate: [AuthGuardService],
  },


  {
    path: "communications/search",
    component: LocateCommunicationComponent,
    //canActivate: [AuthGuardService],
  },

  {
    path: "communications/search-detail/:id",
    component: LocateCommunicationDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "communications/search-detail/:id/:redirectFrom",
    component: LocateCommunicationDetailComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "communications/previous-communication-list/:typeEnum/:id",
    component: PreviousCommunicationListComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "dashboard/communication-drilldown/:type/:para1/:para2/:para3/:para4",
    component: DashboardDrillDownComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "reports/department-report",
    component: DepartmentReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/department-report/:type",
    component: DepartmentReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/vendor-report",
    component: VendorReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/communication-type-report",
    component: CommunicationTypeReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/attachment-type-report",
    component: AttachmentTypeReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  //added by pravesh as on 25022021
  {
    path: "reports/master-reports/:id",
    component: MasterReportsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/lookup-report",
    component: LookupReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/department-office-report",
    component: DepartmentOfficeReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/office-staff-report",
    component: OfficeStaffReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/reason-type-report",
    component: ReasonTypeReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/complaint-type-report",
    component: ComplaintTypeReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/refund-request-reason-report",
    component: RefundRequestReasonReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/concern-report",
    component: ConcernReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/suggestion-report",
    component: SuggestionReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/complaint-report",
    component: ComplaintReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/emd-return-report",
    component: EmdReturnReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/general-report",
    component: GeneralReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/suggestions-report",
    component: SuggestionsReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/invoice-report",
    component: InvoiceReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/project-report",
    component: ProjectReportComponent, //Rewant
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/work-order-report",
    component: WorkOrderReportComponent, //Rewant
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/vendor-list-report",
    component: VendorListReportComponent, //Rewant
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/user-report",
    component: UserReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },
  {
    path: "reports/project-wise-communication-report",
    component: ProjectWiseCommunicationReportComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "master/help-document",
    component: UserManualComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/vendor-type",
    component: VendorTypeComponent,
    canActivate: [AuthGuardService],
  },


  {
    path: "master/vendor-type/add",
    component: AddUpdateVendorTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/vendor-type/update/:id",
    component: AddUpdateVendorTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/vendor-type/detail/:id",
    component: VendorTypeDetailComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "master/communication-attachment-type",
    component: CommunicationAttachmentTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/communication-attachment-type/add",
    component: AddUpdateCommunicationAttachmentTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/communication-attachment-type/update/:id",
    component: AddUpdateCommunicationAttachmentTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/communication-attachment-type/detail/:id",
    component: CommunicationAttachmentTypeDetailComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "master/communication-type",
    component: CommunicationTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/communication-type/add",
    component: AddUpdateCommunicationTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/communication-type/update/:id",
    component: AddUpdateCommunicationTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/communication-type/detail/:id",
    component: CommunicationTypeDetailComponent,
    canActivate: [AuthGuardService],
  },

  //
  {
    path: "master/reason-type",
    component: ReasonTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/reason-type/add",
    component: AddUpdateReasonTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/reason-type/update/:id",
    component: AddUpdateReasonTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/reason-type/detail/:id",
    component: ReasonTypeDetailComponent,
    canActivate: [AuthGuardService],
  },
  //

  { path: "user", component: UserComponent, canActivate: [AuthGuardService] },

  { path: "projectmapping", component: ProjectMappingComponent, canActivate: [AuthGuardService] },

  { path: "adminForloginCreation", component: LoginUserCreationComponent, canActivate: [AuthGuardService] },

  {
    path: "vendor",
    component: VendorComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "vendor/detail/:id",
    component: VendorDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "vendor/bank-account/:id",
    component: BankAccountComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "vendor/update-vendor-profile/:id",
    component: UpdateVendorProfileComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "work-order",
    component: WorkOrderComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "work-order/add",
    component: AddUpdateWorkOrderComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "work-order/update/:id",
    component: AddUpdateWorkOrderComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "work-order/detail/:id",
    component: WorkOrderDetailComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "communication",
    component: CommunicationComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "communication/detail/:id",
    component: CommunicationDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "communication/detail/:id/:cid",
    component: CommunicationTransactionDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "communication/action-history/:id/:from",
    component: ActionHistoryComponent,
    canActivate: [AuthGuardService],
  },



  {
    path: "project",
    component: ProjectComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "project/add",
    component: AddUpdateProjectComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "project/update/:id",
    component: AddUpdateProjectComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "project/detail/:id",
    component: ProjectDetailComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "inbox",
    component: InboxComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "inbox/detail/:id",
    component: InboxDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "outbox",
    component: OutboxComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "outbox/detail/:id",
    component: OutboxDetailComponent,
    canActivate: [AuthGuardService],
  },

  //#region  department-master
  {
    path: "master/department-master",
    component: DepartmentMasterNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/department-master/detail/:id",
    component: DetailDepartmentMasterNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/department-master/add",
    component: AddUpdateDepartmentMasterNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/department-master/update/:id",
    component: AddUpdateDepartmentMasterNewComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //#region Department contact detail section
  {
    path: "master/department-contact-details",
    component: DepartmentContactDetailsNewComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/department-contact-details/detail/:id",
    component: DetailDepartmentContactDetailsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/department-contact-details/add",
    component: AddUpdateDepartmentContactDetailsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/department-contact-details/update/:id",
    component: AddUpdateDepartmentContactDetailsComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //#region  district-master
  {
    path: "master/district",
    component: DistrictComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/district/detail/:id",
    component: DistrictDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/district/add",
    component: AddUpdateDistrictComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/district/update/:id",
    component: AddUpdateDistrictComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //#region  chiefMinisterProfile-master
  {
    path: "master/chief-minister-profile",
    component: ChiefMinisterProfileComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/chief-minister-profile/edit/:id",
    component: AddUpdateChiefMinisterProfileComponent,
    canActivate: [AuthGuardService],
  },

  //#endregion


  {
    path: "vendor-user",
    component: VendorUserComponent,
    canActivate: [AuthGuardService],
  },


  {
    path: "master/complaint-type",
    component: ComplaintTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/complaint-type/add",
    component: AddUpdateComplaintTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/complaint-type/update/:id",
    component: AddUpdateComplaintTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/complaint-type/detail/:id",
    component: ComplaintTypeDetailComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "master/refund-request-reason",
    component: RefundRequestReasonComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/refund-request-reason/add",
    component: AddUpdateRefundRequestReasonComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/refund-request-reason/update/:id",
    component: AddUpdateRefundRequestReasonComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/refund-request-reason/detail/:id",
    component: RefundRequestReasonDetailComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "master/concern",
    component: ConcernComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/concern/add",
    component: AddUpdateConcernComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/concern/update/:id",
    component: AddUpdateConcernComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/concern/detail/:id",
    component: ConcernDetailComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "master/suggestion-for",
    component: SuggestionForComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/suggestion-for/add",
    component: AddUpdateSuggestionForComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/suggestion-for/update/:id",
    component: AddUpdateSuggestionForComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/suggestion-for/detail/:id",
    component: SuggestionForDetailComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "master/project",
    component: ProjectReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/project/add",
    component: ProjectReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/project/update/:id",
    component: ProjectReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/project/detail/:id",
    component: ProjectReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/master-data",
    component: MasterDataComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/master-data/add",
    component: AddUpdateMasterDataComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/master-data/update/:id",
    component: AddUpdateMasterDataComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/master-data/detail/:id",
    component: MasterDataDetailComponent,
    canActivate: [AuthGuardService],
  },
  // Reports Start
  {
    path: "vendor-user-report",
    component: VendorUserReportComponent,
    canActivate: [AuthGuardService],
  }
  , {
    path: "new-communication-report/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  }
  , {
    path: "communicationreport",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  }
  , {
    path: "old-communication-report/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  }
  , {
    path: "new-communication-return-report/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "new-communication-reject-report/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  }
  ,
  {
    path: "new-communication-accept-report/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  }
  ,
  //added by pravesh
  {
    path: "old-communication-reject-report/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "old-communication-accept-report/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  }
  ,
  {
    path: "new-communication-disposed-report/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  }
  ,
  {
    path: "old-communication-disposed-report/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "old-communication-return-report/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  }
  ,
  {
    path: "move-to-department-office-communication/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "old-communication-return-report/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "forward-communication-list/:status",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "pending-communication-list",
    component: NewOldCommunicationReportComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "agewise-communication-list",
    component: AgeWiseCommunicationComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "statistical-report",
    component: StatisticalReportsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "statistical-report/drill-down",
    component: StatisticalReportDrillDownComponent,
    canActivate: [AuthGuardService]
  },
  // Reports End
  //Communication Type Fields
  {
    path: "master/communication-type/communication-fields/:id",
    component: CommunicationFieldsComponent,
    canActivate: [AuthGuardService],
  },

  //Dynamic Component
  {
    path: "communication/add-update-communication-transaction/:typeCode/:Id", component: AddUpdateCommunicationTransactionComponent,
    canActivate: [AuthGuardService],
  },

  //Dynamic Component

  {
    path: "master/admin-department",
    component: AdminDepartmentComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/admin-department/add",
    component: AddUpdateAdminDepartmentComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: "master/admin-department/update/:id",
    component: AddUpdateAdminDepartmentComponent,
    // canActivate: [AuthGuardService],
  },

  {
    path: "master/designation-master",
    component: DesignationMasterComponent,
    //  canActivate: [AuthGuardService],
  },
  {
    path: "master/designation-master/add",
    component: AddUpdateDesignationMasterComponent,
    //  canActivate: [AuthGuardService],
  },
  {
    path: "master/designation-master/update/:id",
    component: AddUpdateDesignationMasterComponent,
    // canActivate: [AuthGuardService],
  },

  {
    path: "communication/:status",
    component: CommunicationComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "dashboard-communication/:status",
    component: DashboardCommunicationListComponent,
    //canActivate: [AuthGuardService],
  },

  {
    path: "dashboard-communication/:status/:isVendorCommunication",
    component: DashboardCommunicationListComponent,
    //canActivate: [AuthGuardService],
  },

  {
    path: "master/office-master",
    component: OfficeMasterComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/office-master/create",
    component: AddUpdateOfficeMasterComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/office-master/update/:id",
    component: AddUpdateOfficeMasterComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/office-master/detail/:id",
    component: DetailOfficeMasterComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "locate-user",
    component: LocateUserComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: "user-master/add",
    component: AddUpdateUserComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: "user-master/update/:id",
    component: AddUpdateUserComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: "user-master",
    component: UserCreationComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: "profile-update",
    component: UpdateProfileComponent,
    //canActivate: [AuthGuardService],
  },
  {
    path: "master/user-type",
    component: UserTypeMasterComponent,
    canActivate: [AuthGuardService],
  }
  ,

  //#region notification-template-type
  {
    path: "master/notification-template-type",
    component: NotificationTemplateTypeComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //#region notification-email-templates
  {
    path: "master/notification-email-templates",
    component: NotificationEmailTemplatesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/notification-email-templates/add",
    component: AddUpdateEmailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/notification-email-templates/update/:id",
    component: AddUpdateEmailComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //#region notification-sms-templates
  {
    path: "master/notification-sms-templates",
    component: NotificationSmsTemplatesComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/notification-sms-templates/add",
    component: AddUpdateSmsComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/notification-sms-templates/update/:id",
    component: AddUpdateSmsComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //#region sms-template-mapping
  {
    path: "master/sms-template-mapping",
    component: SMSTemplateMappingComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/sms-template-mapping/create",
    component: AddUpdateSMSTemplateMappingDialogComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/sms-template-mapping/update/:id",
    component: AddUpdateSMSTemplateMappingDialogComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion


  //#region user-notification
  {
    path: "user/user-notification",
    component: UserNotificationComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //#region Permission master
  {
    path: "master/permission-master",
    component: PermissionMasterComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/permission-master/add",
    component: AddUpdatePermissionMasterComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/permission-master/update/:PermissionCode",
    component: AddUpdatePermissionMasterComponent,
    canActivate: [AuthGuardService],
  },

  {
    path: "master/permission/user-default-permission",
    component: UserDefaultPermissionComponent,
  },
  {
    path: "master/permission/user-specific-permission",
    component: SpecificUserPermissionComponent,
  },

  {
    path: "master/permission/user-additional-permission",
    component: AdditionalPermissionComponent,
  },

  {
    path: "master/permission/user-additional-permission/add",
    component: AddUpdateAdditionalPermissionComponent,
  },


  {
    path: "master/permission/user-additional-permission/update/:id",
    component: AddUpdateAdditionalPermissionComponent,
  },

  //#endregion

  //#region Suggestion-Feedback
  {
    path: "complaint-software",
    component: ComplaintSoftwareComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "complaint-software/create",
    component: AddUpdateComplaintComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "complaint-software/update:/id",
    component: AddUpdateComplaintComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "complaint-software/detail/:id",
    component: ComplaintSoftwareDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'complaint-software/master/entrytype',
    component: EntryTypeComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: 'complaint-software/master/status',
    component: StatusComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion


  //#region Master Type
  {
    path: "master/master-type",
    component: MasterTypeDataComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/master-type/create",
    component: AddUpdateMasterTypeDataComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/master-type/update:/id",
    component: AddUpdateMasterTypeDataComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //#region Project Group
  {
    path: "master/project-group",
    component: ProjectGroupComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/project-group/detail/:id",
    component: ProjectGroupDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/project-group/add",
    component: AddUpdateProjectGroupComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/project-group/update/:id",
    component: AddUpdateProjectGroupComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //#region Project Category
  {
    path: "master/project-category",
    component: ProjectCategoryComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/project-category/detail/:id",
    component: ProjectCategoryDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/project-category/add",
    component: AddUpdateProjectCategoryComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/project-category/update/:id",
    component: AddUpdateProjectCategoryComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //#region Project Sub Category
  {
    path: "master/project-sub-category",
    component: ProjectSubCategoryComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/project-sub-category/detail/:id",
    component: ProjectSubCategoryDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/project-sub-category/add",
    component: AddUpdateProjectSubCategoryComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/project-sub-category/update/:id",
    component: AddUpdateProjectSubCategoryComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //#region Workorder category
  {
    path: "master/work-order-category",
    component: WorkOrderCategoryComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/work-order-category/detail/:id",
    component: WorkOrderCategoryDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/work-order-category/add",
    component: AddUpdateWorkOrderCategoryComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/work-order-category/update/:id",
    component: AddUpdateWorkOrderCategoryComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //#region Workorder sub category
  {
    path: "master/work-order-sub-category",
    component: WorkOrderSubCategoryComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/work-order-sub-category/detail/:id",
    component: WorkOrderSubCategoryDetailComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/work-order-sub-category/add",
    component: AddUpdateWorkOrderSubCategoryComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/work-order-sub-category/update/:id",
    component: AddUpdateWorkOrderSubCategoryComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion


  {
    path: "notification-list",
    component: NotificationComponent,
  },

  {
    path: "global-filter-search",
    component: GlobalCommunicationSearchComponent,
  },




  //#region Import Excel Data
  {
    path: "import-excel-data",
    component: ImportExcelDataComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion

  //// Start - If component in module
  // { path: '', redirectTo: 'dashboard', pathMatch: 'full', canActivate: [AuthGuardService] },
  // { path: 'dashboard', loadChildren: () => DashboardComponent, canActivate: [AuthGuardService] },
  //// End - If component in module

  {
    path: "master/general-entry-category",
    component: GeneralEntryCategoryMasterComponent,
  },
  {
    path: "master/general-entry-category/add",
    component: AddUpdateGeneralEntryCategoryMasterComponent,
  },
  {
    path: "master/general-entry-category/update/:id",
    component: AddUpdateGeneralEntryCategoryMasterComponent,
  },
  {
    path: "master/general-entry-sub-category",
    component: GeneralEntrySubCategoryMasterComponent,
  },
  {
    path: "master/general-entry-sub-category/add",
    component: AddUpdateGeneralEntrySubCategoryMasterComponent,
  },
  {
    path: "master/general-entry-sub-category/update/:id",
    component: AddUpdateGeneralEntrySubCategoryMasterComponent,
  },
  {
    path: "general-entry",
    component: GeneralEntryComponent,
  },

  {
    path: "general-entry/detail/:id",
    component: DetailGeneralEntryComponent,
  },
  {
    path: "general-entry/add",
    component: AddUpdateGeneralEntryComponent,
  },
  {
    path: "general-entry/update/:id",
    component: AddUpdateGeneralEntryComponent,
  },
  {
    path: "general-entry/update-comment/:id",
    component: AddUpdateGeneralEntryComponent,
  },

  {
    path: "master/b2g-user-manual",
    component: B2GUserManualComponent,
  },
  {
    path: "master/b2g-user-manual/add",
    component: AddUpdateB2GUserManualComponent,
  },
  {
    path: "master/b2g-user-manual/update/:id",
    component: AddUpdateB2GUserManualComponent,
  },
  {
    path: "reports/log-report",
    component: LogReportComponent, //Vinita
    canActivate: [AuthGuardService],
  },

  {
    path: "print",
    component: PrintHelperComponent
  },

  //
  {
    path: "master/status-master",
    component: StatusMasterComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/status-master/add",
    component: AddUpdateStatusMasterComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/status-master/update/:id",
    component: AddUpdateStatusMasterComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/status-master/detail/:id",
    component: DetailStatusMasterComponent,
    canActivate: [AuthGuardService],
  },
  {
    path: "master/vendor-permission/vendor/detail/:id",
    component: VendorDetailComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "master/vendor-permission",
    component: VendorCommunicationPermissionComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "master/dashboard/vendor-permission/:status",
    component: VendorCommunicationPermissionComponent,
    canActivate: [AuthGuardService]
  },

  //#region User-Activity-Log
  {
    path: "user-activity-log",
    component: UserActivityLogComponent,
    canActivate: [AuthGuardService],
  },
  //#endregion
  //#region Preference List
  {
    path: "preferencelist",
    component: PreferenceListComponent,
    //canActivate: [AuthGuardService],
  },
  {
    path: "preferencelist/create",
    component: AddUpdatePreferenceComponent,
    // canActivate: [AuthGuardService],
  },
  {
    path: "ccforcommunication",
    component: AddCCForCommunicationComponent,
    // canActivate: [AuthGuardService],
  },
  //#endregion

  //#region ContactUs
  {
    path: "contactus",
    component: ContactUsComponent,
    canActivate: [AuthGuardService],
  },

  //#region Utility
  {
    path: "utility/application-data-utility",
    component: ApplicationDataUtilityComponent,
    canActivate: [AuthGuardService],
  },


  //#endregion

  //#region  Vendor Permission Delegation
  {
    path: "communications/vendor-permission-delegation",
    component: VendorPermissionDelegationComponent,
    canActivate: [AuthGuardService]
  },
  //#endregion

  //#region  Preferred List
  {
    path: "preferred-list",
    component: PreferredListComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: "preferred-list/add",
    component: PreferredListComponent,
    canActivate: [AuthGuardService]
  },

  {
    path: "external",
    loadChildren: () => import('./external/external.module').then(m => m.ExternalModule),
    canActivate: [AuthGuardService]
  },

  //#endregion

  // {
  //   path: "project",
  //   component: ProjectComponent,
  //   canActivate: [AuthGuardService],
  // },


];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule],
})
export class AppRoutingModule { }
