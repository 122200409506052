import { UserViewModel } from './../../shared/model/user.model';
import { isNullOrUndefined } from 'util';
import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator } from "@angular/material";
import { GlobalFilterModel, IndexModel, PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DDLModel, FilterDDlModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { ProjectSearchModel, ProjectModel } from 'src/app/shared/model/project.model';
import { ProjectService } from 'src/app/shared/service/project.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { CustomFilterDialogComponent } from 'src/app/shared/component/custom-filter-selector/custom-filter-dialog/custom-filter-dialog.component';
import { FormControl } from '@angular/forms';
import { ClearProjectPopupComponent } from './clear-project-popup/clear-project-popup.component';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss']
})
export class ProjectComponent implements OnInit {

  model: ProjectModel[];
  dataSource: any;
  totalRecords: number;
  loginUserCode: number;
  loginBaseUserType: string;
  displayedColumns: string[] = [];
  columnsToDisplay: string[] = [];
  baseUserType: string;
  userTypeEnum = UserTypeEnum;
  @Input() control = new FormControl();
  public isFormVisible: boolean = false;


  searchModel = this._commonService.modelSetGet(new ProjectSearchModel()) as ProjectSearchModel;
  isShow = true;
  ddlList = new DDLModel();
  loginUserDetail: UserViewModel;
  filterDDlModel: any[];
  minDate = new Date();

  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "ProjectName", Text: "Project Name" },
    { Value: "VendorCompany", Text: "Vendor Company" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Permission: PermissionModel = this._authService.GetPagePermission("project", "project/add", "project/detail", "project/update", "project/delete");

  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    public readonly _projectService: ProjectService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    private readonly _dialogRef: MatDialog,
  ) {
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    this.loginBaseUserType = this._authService.BaseUserType;
    this.GetDDLList();
    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;
    this.setIsFormVisible();

    this.displayedColumns = ["index", "ProjectName", "VendorCompany", "DepartmentName", "CreatedOn"];
    if (this.loginBaseUserType == UserTypeEnum.VNDR) {
      this.displayedColumns.push("IsActive");
    }
    this.displayedColumns.push("IsOICAssigned");
    this.displayedColumns.push("Status");

    this.displayedColumns.push("Action");
    this.columnsToDisplay = this.displayedColumns.slice();

    if (_projectService.canUserTypeCreateProject() && this.Permission.AddPageAccess) {
      this.appComponnet.setPageLayout("Project :", "add", "Create", "project/add");
    }
    else {
      this.appComponnet.setPageLayout("Project :", "", "", "");
    }
  }

  ngOnInit() {
    this.searchModel.indexModel.OrderBy = "CreateDate";
    this.searchModel.indexModel.OrderByAsc = AppSetting.orderByDsc;

    this.getList();
  }

  getList() {
    this._projectService.GetProjectBySearch(this._commonService.modelSetGet(this.searchModel, true)).subscribe(data => {
      if (data.IsSuccess) {
        this.model = <ProjectModel[]>data.Data.Data;

        this.dataSource = new MatTableDataSource<ProjectModel>(this.model);
        this.totalRecords = data.Data.TotalRecords;
        if (!this.searchModel.indexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      } else {
        this._alertService.error(data.Message);
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  updateActiveStatus(id) {

    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._projectService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this.getList();
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._projectService.ChangeDeleteStatus(id).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._commonService.ScrollingTop();
            this.getList();
          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
    });
  }

  sortData(event) {
    this.searchModel.indexModel.OrderBy = event.active;
    this.searchModel.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.searchModel.indexModel.Page = event.pageIndex + 1;
    this.searchModel.indexModel.PageSize = event.pageSize;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {
    this.searchModel.indexModel.Search = searchValue.search;
    this.getList();
  }

  ClearSearch() {
    this.searchModel.indexModel = new IndexModel();
    this.searchModel.indexModel.OrderBy = "CreateDate";
    this.searchModel.indexModel.OrderByAsc = AppSetting.orderByDsc;
    this.getList();
  }

  //#region Adv search
  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.ProjectAdvSearchDropdownKeys, 0, this.loginUserDetail.UserCode).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList = <DDLModel>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  GetDistrictByDepartment() {
    if (!isNullOrUndefined(this.searchModel.DepartmentCode)) {
      this.ddlList.ddlDistrict = [];
      this._commonService.GetDDL(AppSetting.ddlDistrict, this.searchModel.DepartmentCode).subscribe(data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlDistrict = ddl.ddlDistrict;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
    else {
      this.ddlList.ddlDistrict = null;
    }
  }

  GetOfficeByDepartment() {
    if (!isNullOrUndefined(this.searchModel.DepartmentCode)) {
      this.ddlList.ddlOfficeByLoginUserId = [];
      this.searchModel.DistrictCode = this.searchModel.DistrictCode != undefined && Number(this.searchModel.DistrictCode) > 0 ? this.searchModel.DistrictCode : (this.loginUserDetail != undefined && this.loginUserDetail.DistrictCode != undefined && this.loginUserDetail.DistrictCode.length > 0 ? this.loginUserDetail.DistrictCode[0] : undefined);
      this._commonService.GetDDL(AppSetting.ddlOfficeByLoginUserId, this.searchModel.DepartmentCode, this.searchModel.DistrictCode).subscribe((data) => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlOfficeByLoginUserId = ddl.ddlOfficeByLoginUserId;
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    } else {
      this.ddlList.ddlOfficeByLoginUserId = null;
    }
  }

  getFilterdDDL(filterFor, value, filterFrom) {
    const item = new FilterDDlModel();
    item.FilterFor = filterFor;
    item.Value = value;
    item.FilterFrom = filterFrom;
    this.filterDDlModel = [];
    this.filterDDlModel.push(item);
    this._commonService.GetFilterdDDL(this.filterDDlModel).subscribe(data => {
      if (data.IsSuccess) {
        if (item.FilterFor == "ddlProjectSubCategory") {
          this.ddlList.ddlProjectSubCategory = data.Data.ddlProjectSubCategory;
        }
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  Reset() {
    this.searchModel = new ProjectSearchModel();
    this.GetDDLList();
    this.getList();
  }
  private setIsFormVisible() {
    let userDetail = this._authService.LoggedInUserDetail.UserViewModel;
    if (userDetail.UserType == UserTypeEnum.SADM) {
      this.isFormVisible = true
    }
  }

  public openDialog() {
    const dialogRef = this._dialogRef.open(ClearProjectPopupComponent);
  }

  //#endregion

}
