import { IndexModel } from "../common.model";

export class DepartmentOfficeFilterSearchModel extends IndexModel {

    public DepartmentCode: number = 0;

    public DistrictCode: number = 0;

    public OfficeCode: number = 0;

}