import { Component, OnInit } from "@angular/core";
import { MatDialogRef } from "@angular/material";
import { BehaviorSubject, Observable } from "rxjs";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { DDLModel, DdlItemModel } from "src/app/shared/model/commonddl.model";
import { SearchCriteriaModel, SearchCriteriaUIManagementModel, SearchCriteriaDepartmentFilterModel, SearchCriteriaProjectFilterModel, SearchCriteriaOfficeFilterModel, SearchCriteriaDistrictFilterModel, SearchCriteriaWorkOrderFilterModel, SearchCriteriaVendorFilterModel, SearchCriteriaUserFilterModel } from "src/app/shared/model/report/search-criteria.model";
import { UserTypeModel } from "src/app/shared/model/user-type.model";
import { UserViewModel } from "src/app/shared/model/user.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { AuthenticationService } from "src/app/shared/service/authentication.service";
import { CommonService } from "src/app/shared/service/common.service";
import { UserTypeService } from "src/app/shared/service/user-type.service";
import { UserService } from "src/app/shared/service/user.service";

@Component({
  selector: "app-report-search-criteria-dialog",
  templateUrl: "./report-search-criteria-dialog.component.html",
  styleUrls: ["./report-search-criteria-dialog.component.scss"],
})

export class ReportSearchCriteriaDialogComponent implements OnInit {
  //#region <Variables>
  searchModel = new SearchCriteriaModel();
  searchUIManagementModel = new SearchCriteriaUIManagementModel();
  ddlList = new DDLModel();
  loginData: UserViewModel;
  ddlAdminDepartment: DdlItemModel[] = [];
  ddlDepartment: DdlItemModel[] = [];
  ddlProject: DdlItemModel[] = [];
  ddlProjectOIC: DdlItemModel[] = [];
  ddlWorkOrder: DdlItemModel[] = [];
  ddlDistrict: DdlItemModel[] = [];
  ddlVendor: DdlItemModel[] = [];
  ddlOffice: DdlItemModel[] = [];
  ddlUser: DdlItemModel[] = [];
  ddlUserType: DdlItemModel[] = [];
  ddlCommunicationType: DdlItemModel[] = [];

  ddlAdminDepartmentItems: { [index: string]: string } = {};
  ddlDepartmentItems: { [index: string]: string } = {};
  ddlProjectItems: { [index: string]: string } = {};
  ddlProjectOICItems: { [index: string]: string } = {};
  ddlWorkOrderItems: { [index: string]: string } = {};
  ddlDistrictItems: { [index: string]: string } = {};
  ddlVendorItems: { [index: string]: string } = {};
  ddlOfficeItems: { [index: string]: string } = {};
  ddlUserItems: { [index: string]: string } = {};
  ddlUserTypeItems: { [index: string]: string } = {};
  ddlCommunicationTypeItem: { [index: string]: string } = {};

  // isSearchClick: boolean = false;
  // isDepartmentLock: boolean = false;
  // isOfficeLock: boolean = false;
  // isVendorLock: boolean = false;
  //#endregion <Variables>

  //#region <Constructor>
  constructor(
    public _dialogRef: MatDialogRef<ReportSearchCriteriaDialogComponent>,
    private readonly _userService: UserService,
    public readonly _commonService: CommonService,
    private readonly _userTypeService: UserTypeService,
    private readonly _authService: AuthenticationService,
    private readonly _alertService: AlertService //  @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loginData = this._authService.GetCurrentUserDetail().UserViewModel;

    if (this._commonService.GetLocalStorage("GlobalReportSearch")) {
      this.searchModel = JSON.parse(this._commonService.GetLocalStorage("GlobalReportSearch"));
      this.searchUIManagementModel.isSearchClick = true;
    }


  }

  //#endregion <Constructor>

  //#region <Method>

  ngOnInit() {

    this.getAdminDepartment();
    this.GetDepartmentList();

    // this.GetDDLList();
    this.getDownLevelUserType();
    //this.populateDepartments();
  }


  selectFromDate(date: string) {
    this._commonService.setFromDate(date);
  }

  selectToDate(date: string) {
    this._commonService.setToDate(date);
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.SearchCriteriaDDLKey).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
          if (this.ddlList.ddlCommunicationType) {
            this.ddlList.ddlCommunicationType.forEach((obj) => {
              this.ddlCommunicationTypeItem[obj.Value] = obj.Text;
            });
          }
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  getAdminDepartment() {
    this.ddlAdminDepartment = [];
    this._userService.GetUserAdminDepartmentList().subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlAdminDepartment = <DdlItemModel[]>data.Data;
          if (this.ddlAdminDepartment) {
            this.ddlAdminDepartment.forEach((obj) => {
              this.ddlAdminDepartmentItems[obj.Value] = obj.Text;
            });
          }
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  onAdminDepartmentChange() {
    if (!this.searchUIManagementModel.isDepartmentLock) {

      this.GetDepartmentList();
      this.searchUIManagementModel.DepartmentColor = "info-color-7";
    }
  }

  // populateDepartments() {
  //   
  //   this.searchModel.Departments = [];
  //   this.ddlDepartment.forEach(department => {

  //     if (this.searchModel.AdminDepartments.length > 0) {
  //       this.searchModel.Departments.push(department.Value);
  //     }
  //   });
  // }


  onDepartmentChange() {
    this.GetProjectList();
    this.searchUIManagementModel.ProjectColor = "info-color-1";
    if (!this.searchUIManagementModel.isOfficeLock) {
      this.GetOfficeList();
      this.searchUIManagementModel.OfficeColor = "info-color-1";
    }
    this.GetDistrictList();
    this.searchUIManagementModel.DistrictColor = "info-color-1";
    if (!this.searchUIManagementModel.isVendorLock) {
      this.GetVendorList();
      this.searchUIManagementModel.VendorColor = "info-color-1";
    }
    this.GetUserList();
    this.searchUIManagementModel.UserColor = "info-color-1";
  }

  onDistrictChange() {
    this.GetProjectList();
    this.searchUIManagementModel.ProjectColor = "info-color-2";
    if (!this.searchUIManagementModel.isOfficeLock) {
      this.GetOfficeList();
      this.searchUIManagementModel.OfficeColor = "info-color-2";
    }
    if (!this.searchUIManagementModel.isVendorLock) {
      this.GetVendorList();
      this.searchUIManagementModel.VendorColor = "info-color-2";
    }
    this.GetUserList();
    this.searchUIManagementModel.UserColor = "info-color-2";
  }

  onOfficeChange() {
    if (!this.searchUIManagementModel.isDepartmentLock) {
      this.GetDepartmentList();
      this.searchUIManagementModel.DepartmentColor = "info-color-3";
    }
    this.GetProjectList();
    this.searchUIManagementModel.ProjectColor = "info-color-3";
    this.GetDistrictList();
    this.searchUIManagementModel.DistrictColor = "info-color-3";
    this.GetWorkOrderList();
    this.searchUIManagementModel.WorkTypeColor = "info-color-3";
    if (!this.searchUIManagementModel.isVendorLock) {
      this.GetVendorList();
      this.searchUIManagementModel.VendorColor = "info-color-3";
    }
    this.GetUserList();
    this.searchUIManagementModel.UserColor = "info-color-3";
  }

  onProjectChange() {
    if (!this.searchUIManagementModel.isDepartmentLock) {
      this.GetDepartmentList();
      this.searchUIManagementModel.DepartmentColor = "info-color-4";
    }
    this.GetWorkOrderList();
    this.searchUIManagementModel.WorkTypeColor = "info-color-4";
    if (!this.searchUIManagementModel.isVendorLock) {
      this.GetVendorList();
      this.searchUIManagementModel.VendorColor = "info-color-4";
    }
    this.GetProjectOICList();
    this.searchUIManagementModel.ProjectOICColor = "info-color-4";
  }

  onVendorChange() {
    if (!this.searchUIManagementModel.isDepartmentLock) {
      this.GetDepartmentList();
      this.searchUIManagementModel.DepartmentColor = "info-color-5";
    }
    this.GetProjectList();
    this.searchUIManagementModel.ProjectColor = "info-color-5";
    if (!this.searchUIManagementModel.isOfficeLock) {
      this.GetOfficeList();
      this.searchUIManagementModel.OfficeColor = "info-color-5";
    }
  }

  GetDepartmentList() {

    var filterModel = new SearchCriteriaDepartmentFilterModel(
      String(this.searchModel.AdminDepartments || ""),
      String(this.searchModel.Offices || ""),
      String(this.searchModel.Vendors || ""),
      String(this.searchModel.Projects || "")
    );
    this.ddlDepartment = [];
    this._commonService.GetSearchCriteriaDepartmentList(filterModel).subscribe(
      (data) => {

        if (data.IsSuccess) {
          this.ddlDepartment = <DdlItemModel[]>data.Data;
          if (this.ddlDepartment) {
            this.ddlDepartment.forEach((obj) => {
              this.ddlDepartmentItems[obj.Value] = obj.Text;
            });
          }
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetProjectList() {

    var filterModel = new SearchCriteriaProjectFilterModel(
      String(this.searchModel.Offices || ""),
      String(this.searchModel.Departments || ""),
      String(this.searchModel.Districts || ""),
      String(this.searchModel.Vendors || "")
    );
    this.ddlProject = [];
    this._commonService.GetSearchCriteriaProjectList(filterModel).subscribe(
      (data) => {

        if (data.IsSuccess) {
          this.ddlProject = <DdlItemModel[]>data.Data;
          if (this.ddlProject) {
            this.ddlProject.forEach((obj) => {
              this.ddlProjectItems[obj.Value] = obj.Text;
            });
          }
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetOfficeList() {

    var filterModel = new SearchCriteriaOfficeFilterModel(
      String(this.searchModel.Departments || ""),
      String(this.searchModel.Districts || ""),
      String(this.searchModel.Vendors || "")
    );
    this.ddlOffice = [];
    this._commonService.GetSearchCriteriaOfficeList(filterModel).subscribe(
      (data) => {

        if (data.IsSuccess) {
          this.ddlOffice = <DdlItemModel[]>data.Data;
          if (this.ddlOffice) {
            this.ddlOffice.forEach((obj) => {
              this.ddlOfficeItems[obj.Value] = obj.Text;
            });
          }
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDistrictList() {

    var filterModel = new SearchCriteriaDistrictFilterModel(
      String(this.searchModel.Departments || ""),
      String(this.searchModel.Offices || "")
    );
    this.ddlDistrict = [];
    this._commonService.GetSearchCriteriaDistrictList(filterModel).subscribe(
      (data) => {

        if (data.IsSuccess) {
          this.ddlDistrict = <DdlItemModel[]>data.Data;
          if (this.ddlDistrict) {
            this.ddlDistrict.forEach((obj) => {
              this.ddlDistrictItems[obj.Value] = obj.Text;
            });
          }
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetWorkOrderList() {

    var filterModel = new SearchCriteriaWorkOrderFilterModel(
      String(this.searchModel.Projects || ""),
      String(this.searchModel.Offices || "")
    );
    this.ddlWorkOrder = [];
    this._commonService.GetSearchCriteriaWorkOrderList(filterModel).subscribe(
      (data) => {

        if (data.IsSuccess) {
          this.ddlWorkOrder = <DdlItemModel[]>data.Data;
          if (this.ddlWorkOrder) {
            this.ddlWorkOrder.forEach((obj) => {
              this.ddlWorkOrderItems[obj.Value] = obj.Text;
            });
          }
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetVendorList() {

    var filterModel = new SearchCriteriaVendorFilterModel(
      String(this.searchModel.Departments || ""),
      String(this.searchModel.Districts || ""),
      String(this.searchModel.Projects || ""),
      String(this.searchModel.Offices || "")
    );
    this.ddlVendor = [];
    this._commonService.GetSearchCriteriaVendorList(filterModel).subscribe(
      (data) => {

        if (data.IsSuccess) {
          this.ddlVendor = <DdlItemModel[]>data.Data;
          if (this.ddlVendor) {
            this.ddlVendor.forEach((obj) => {
              this.ddlVendorItems[obj.Value] = obj.Text;
            });
          }
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetUserList(idtype = false) {

    if (idtype) {
      this.searchUIManagementModel.UserColor = "info-color-8";
    }

    var filterModel = new SearchCriteriaUserFilterModel(
      String(this.searchModel.Departments || ""),
      String(this.searchModel.Districts || ""),
      String(this.searchModel.UserTypes || ""),
      String(this.searchModel.Offices || "")
    );
    this.ddlUser = [];
    this._commonService.GetSearchCriteriaUserList(filterModel).subscribe(
      (data) => {

        if (data.IsSuccess) {
          this.ddlUser = <DdlItemModel[]>data.Data;
          if (this.ddlUser) {
            this.ddlUser.forEach((obj) => {
              this.ddlUserItems[obj.Value] = obj.Text;
            });
          }
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetProjectOICList() {

    this.ddlProjectOIC = [];
    this._commonService
      .GetSearchCriteriaProjectOICList(String(this.searchModel.Projects || ""))
      .subscribe(
        (data) => {

          if (data.IsSuccess) {
            this.ddlProjectOIC = <DdlItemModel[]>data.Data;
            if (this.ddlProjectOIC) {
              this.ddlProjectOIC.forEach((obj) => {
                this.ddlProjectOICItems[obj.Value] = obj.Text;
              });
            }
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }

  GetCommunicationTypeList() {

    this.ddlCommunicationType = [];
    this.searchUIManagementModel.CommunicationTypeColor = "info-color-6";
    this._commonService
      .GetSearchCriteriaCommunicationTypeList(this.searchModel.CommunicationInitiatedBy != 'All' ? this.searchModel.CommunicationInitiatedBy : "")
      .subscribe(
        (data) => {

          if (data.IsSuccess) {
            this.ddlCommunicationType = <DdlItemModel[]>data.Data;
            if (this.ddlCommunicationType) {
              this.ddlCommunicationType.forEach((obj) => {
                this.ddlCommunicationTypeItem[obj.Value] = obj.Text;
              });
            }
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }

  getDownLevelUserType() {
    this.ddlUserType = [];
    this._userTypeService
      .GetDownLevelUserType(this.loginData.UserType, true)
      .subscribe(
        (data) => {
          if (data.IsSuccess) {
            var userTypes = <UserTypeModel[]>data.Data;
            if (userTypes) {
              userTypes = userTypes;
              userTypes.forEach((element) => {
                this.ddlUserType.push({
                  Value: String(element.UserTypeCode),
                  Text: element.UserTypeTitle,
                });
              });
            }
            if (this.ddlUserType) {
              this.ddlUserType.forEach((obj) => {
                this.ddlUserTypeItems[obj.Value] = obj.Text;
              });
            }
          }
        },
        (error) => {
          this._alertService.error(error.message);
        }
      );
  }

  CloseDialog(): void {
    this._dialogRef.close(this.searchModel);
  }

  departmentLockClick() {
    this.searchUIManagementModel.isDepartmentLock = !this.searchUIManagementModel.isDepartmentLock;
  }

  officeClick() {
    this.searchUIManagementModel.isOfficeLock = !this.searchUIManagementModel.isOfficeLock;
  }

  vendorClick() {
    this.searchUIManagementModel.isVendorLock = !this.searchUIManagementModel.isVendorLock;
  }

  SaveData() {
    this.searchUIManagementModel.isSearchClick = true;

    const fromDate = this._commonService.fromDateSubject.getValue();
    const toDate = this._commonService.toDateSubject.getValue();

    this.searchModel.FromDate = fromDate;
    this.searchModel.ToDate = toDate;

    this.GetData();

    this._commonService.SetLocalStorage(
      "GlobalReportSearch",
      JSON.stringify(this.searchModel)
    );
  }

  clearSearch() {
    this.searchModel = new SearchCriteriaModel();
    this.searchUIManagementModel = new SearchCriteriaUIManagementModel();
    this.ddlDepartment = [];
    this.ddlCommunicationType = [];
    this.ddlProject = [];
    this.ddlProjectOIC = [];
    this.ddlWorkOrder = [];
    this.ddlDistrict = [];
    this.ddlVendor = [];
    this.ddlOffice = [];
    this.ddlUser = [];
    this.searchUIManagementModel.isSearchClick = false;
    this.searchUIManagementModel.isDepartmentLock = false;
    this.searchUIManagementModel.isOfficeLock = false;
    this.searchUIManagementModel.isVendorLock = false;
    this._commonService.RemoveLocalStorage("GlobalReportSearch");
  }

  GetData() {
    
    const fromDate = this._commonService.fromDateSubject.getValue();
    const toDate = this._commonService.toDateSubject.getValue();

    this.searchModel.FromDate = fromDate;
    this.searchModel.ToDate = toDate;


    if (this.searchModel.AdminDepartments) {
      this.searchModel.AdminDepartmentNames = '';
      this.searchModel.AdminDepartments.forEach((element) => {
        if (!this.searchModel.AdminDepartmentNames) {
          this.searchModel.AdminDepartmentNames +=
            this.ddlAdminDepartmentItems[element];
        } else {
          this.searchModel.AdminDepartmentNames =
            this.searchModel.AdminDepartmentNames +
            ", " +
            this.ddlAdminDepartmentItems[element];
        }
      });
    }
    if (this.searchModel.Departments) {
      this.searchModel.DepartmentNames = '';
      this.searchModel.Departments.forEach((element) => {
        if (!this.searchModel.DepartmentNames) {
          this.searchModel.DepartmentNames += this.ddlDepartmentItems[element];
        } else {
          this.searchModel.DepartmentNames =
            this.searchModel.DepartmentNames +
            ", " +
            this.ddlDepartmentItems[element];
        }
      });
    }
    if (this.searchModel.Departments) {
      this.searchModel.DepartmentNames = '';
      this.searchModel.Departments.forEach((element) => {
        if (!this.searchModel.DepartmentNames) {
          this.searchModel.DepartmentNames += this.ddlDepartmentItems[element];
        } else {
          this.searchModel.DepartmentNames =
            this.searchModel.DepartmentNames +
            ", " +
            this.ddlDepartmentItems[element];
        }
      });
    }
    if (this.searchModel.CommunicationTypes) {
      this.searchModel.CommunicationTypeNames = '';
      this.searchModel.CommunicationTypes.forEach((element) => {
        if (!this.searchModel.CommunicationTypeNames) {
          this.searchModel.CommunicationTypeNames +=
            this.ddlCommunicationTypeItem[element];
        } else {
          this.searchModel.CommunicationTypeNames =
            this.searchModel.CommunicationTypeNames +
            ", " +
            this.ddlCommunicationTypeItem[element];
        }
      });
    }
    if (this.searchModel.Projects) {
      this.searchModel.ProjectNames = '';
      this.searchModel.Projects.forEach((element) => {
        if (!this.searchModel.ProjectNames) {
          this.searchModel.ProjectNames += this.ddlProjectItems[element];
        } else {
          this.searchModel.ProjectNames =
            this.searchModel.ProjectNames +
            ", " +
            this.ddlProjectItems[element];
        }
      });
    }
    if (this.searchModel.ProjectsOIC) {
      this.searchModel.ProjectsOICName = '';
      this.searchModel.ProjectsOIC.forEach((element) => {
        if (!this.searchModel.ProjectsOICName) {
          this.searchModel.ProjectsOICName += this.ddlProjectOICItems[element];
        } else {
          this.searchModel.ProjectsOICName =
            this.searchModel.ProjectsOICName +
            ", " +
            this.ddlProjectOICItems[element];
        }
      });
    }
    if (this.searchModel.WorkOrderCodes) {
      this.searchModel.WorkOrderNames = '';
      this.searchModel.WorkOrderCodes.forEach((element) => {
        if (!this.searchModel.WorkOrderNames) {
          this.searchModel.WorkOrderNames += this.ddlWorkOrderItems[element];
        } else {
          this.searchModel.WorkOrderNames =
            this.searchModel.WorkOrderNames +
            ", " +
            this.ddlWorkOrderItems[element];
        }
      });
    }
    if (this.searchModel.Districts) {
      this.searchModel.DistrictNames = '';
      this.searchModel.Districts.forEach((element) => {
        if (!this.searchModel.DistrictNames) {
          this.searchModel.DistrictNames += this.ddlDistrictItems[element];
        } else {
          this.searchModel.DistrictNames =
            this.searchModel.DistrictNames +
            ", " +
            this.ddlDistrictItems[element];
        }
      });
    }
    if (this.searchModel.Vendors) {
      this.searchModel.VendorNames = '';
      this.searchModel.Vendors.forEach((element) => {
        if (!this.searchModel.VendorNames) {
          this.searchModel.VendorNames += this.ddlVendorItems[element];
        } else {
          this.searchModel.VendorNames =
            this.searchModel.VendorNames + ", " + this.ddlVendorItems[element];
        }
      });
    }
    if (this.searchModel.Offices) {
      this.searchModel.OfficeNames = '';
      this.searchModel.Offices.forEach((element) => {
        if (!this.searchModel.OfficeNames) {
          this.searchModel.OfficeNames += this.ddlOfficeItems[element];
        } else {
          this.searchModel.OfficeNames =
            this.searchModel.OfficeNames + ", " + this.ddlOfficeItems[element];
        }
      });
    }
    if (this.searchModel.Users) {
      this.searchModel.UserNames = '';
      this.searchModel.Users.forEach((element) => {
        if (!this.searchModel.UserNames) {
          this.searchModel.UserNames += this.ddlUserItems[element];
        } else {
          this.searchModel.UserNames =
            this.searchModel.UserNames + ", " + this.ddlUserItems[element];
        }
      });
    }
    if (this.searchModel.UserTypes) {
      this.searchModel.UserTypeNames = '';
      this.searchModel.UserTypes.forEach((element) => {
        if (!this.searchModel.UserTypeNames) {
          this.searchModel.UserTypeNames += this.ddlUserTypeItems[element];
        } else {
          this.searchModel.UserTypeNames =
            this.searchModel.UserTypeNames +
            ", " +
            this.ddlUserTypeItems[element];
        }
      });
    }
    if (this.searchModel.CommunicationInitiatedBy) {
      if (this.searchModel.CommunicationInitiatedBy == "DREC") {
        this.searchModel.CommunicationInitiatedByName = "Department";
      } else if (this.searchModel.CommunicationInitiatedBy == "VNDR") {
        this.searchModel.CommunicationInitiatedByName = "Vendor";
      } else {
        this.searchModel.CommunicationInitiatedByName = "All";
      }
    }
    //
  }

  //#endregion <Method>
}
