import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { UserTypeEnum } from './../../../../shared/enum/fixed-values.enum';
import { UserService } from './../../../../shared/service/user.service';
import { DdlItemModel, DDLModel } from './../../../../shared/model/commonddl.model';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { ProjectGroupModel } from 'src/app/shared/model/master/project-group.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ProjectGroupService } from 'src/app/shared/service/master/project-group.service';
import { isNullOrUndefined } from 'util';
import { StateEnum } from 'src/app/shared/enum/fixed-values.enum';

@Component({
  selector: 'app-add-update-project-group',
  templateUrl: './add-update-project-group.component.html',
  styleUrls: ['./add-update-project-group.component.scss']
})

export class AddUpdateProjectGroupComponent implements OnInit {

  loginUserDetail: UserViewModel;
  fromGroup: FormGroup;
  model = new ProjectGroupModel();
  recordId: number = 0;
  loginUserCode: number;
  submitBtn = true;
  ddlList = new DDLModel();
  userTypeEnum = UserTypeEnum

  constructor(private readonly fb: FormBuilder,
    private readonly _appComponent: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _projectGroupService: ProjectGroupService,
    private readonly _authService: AuthenticationService,
    private readonly _userService: UserService
  ) {
    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      this.recordId = this._route.snapshot.params.id;
    }
    var pageHeading = (this.recordId == 0 ? "Add" : "Update") + " Project Group :";
    this._appComponent.setPageLayout(pageHeading, "keyboard_backspace", "Back To List", "master/project-group");
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    this.GetDDLList();
  }

  ngOnInit() {
    this.formGroupInit();
    if (this.recordId > 0) {
      this.getDetail();
    }
  }

  formGroupInit() {
    this.fromGroup = this.fb.group({
      DepartmentCode: [null, Validators.required],
      DistrictCode: [null, Validators.required],
      OfficeCode: [null, Validators.required],
      ProjectGroupTitle: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      ProjectGroupTitleHindi: [null, Validators.compose([Validators.required, Validators.maxLength(100)])],
      IsApplicabletoOffice: [null],
    });
  }

  getDetail() {
    this._projectGroupService.Detail(this.recordId).subscribe(data => {
      if (data.IsSuccess) {
        this.model = <ProjectGroupModel>data.Data;
        if (this.model.DepartmentCode) {
          this.model.DepartmentCode = this.model.DepartmentCode.toString();
        }

        if (this.model.OfficeCode) {
          this.model.OfficeCode = this.model.OfficeCode.toString();
        }

        if (this.model.DistrictCode) {
          this.model.DistrictCode = this.model.DistrictCode.toString();
        }
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  saveClick() {
    this.submitBtn = false;
    this.fromGroup.markAllAsTouched();
    if (this.fromGroup.valid) {

      if (this.recordId == 0)
        this.model.CreatedBy = this.loginUserDetail.UserCode;
      else
        this.model.ModifiedBy = this.loginUserDetail.UserCode;

      this._projectGroupService.AddUpdate(this.model).subscribe(data => {
        if (data) {
          if (data.IsSuccess) {
            this._alertService.success(this.recordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
            this._router.navigate(["master/project-group"]);
          } else {
            this.submitBtn = true;
            this._commonService.ScrollingTop();
            this._alertService.error(this.recordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
          }
        } else {
          this.submitBtn = true;
          this._commonService.ScrollingTop();
          this._alertService.error(GlobalMessagesModel.InternalError);
        }
      }, error => {
        this.submitBtn = true;
        this._commonService.ScrollingTop();
        this._alertService.error(error.error.ResponseMessage);
      });
    }
    else {
      this.submitBtn = true;
    }
  }

  IsProjectGroupExist() {
    this._projectGroupService.IsProjectGroupExist(this.model.ProjectGroupTitle.trim(), this.model.ProjectGroupCode, this.model.OfficeCode as number).subscribe(result => {
      if (result.IsSuccess) {
        let UserName = <boolean>result.Data
        if (UserName == true) {
          this.model.ProjectGroupTitle = null;
          this._alertService.error("Project Group is already exist!");
        }
      }
    }, error => {
      this._commonService.ScrollingTop();
      this._alertService.error(error.message);
    });
  }


  GetDDLList() {
    let ddlKeyName = ((this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDR || this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDRUSER) ? AppSetting.ProjectDropDownKeys : AppSetting.ProjectDropDownDEPTKeys);
    this._commonService.GetAllDDL(ddlKeyName, 0, this.loginUserDetail.UserCode).subscribe((data) => {
      if (data.IsSuccess) {
        let ddlList = <DDLModel>data.Data;
        this.ddlList.ddlOfficeDepartment = ddlList.ddlOfficeDepartment;
        this.ddlList.ddlProjectCategory = ddlList.ddlProjectCategory;
        this.ddlList.ddlLoggedUserVendor = ddlList.ddlLoggedUserVendor;
        this.ddlList.ddlProjectGroup = ddlList.ddlProjectGroup;

        if (this.recordId == 0) {
          this.changeFieldForUser();
        }
      }
    }, (error) => {
      this._alertService.error(error.message);
    });
  }

  changeFieldForUser() {
    const VendorControl = this.fromGroup.get("VendorCode");
    if (this.loginUserDetail.BaseUserType == this.userTypeEnum.DREC || this.loginUserDetail.BaseUserType == this.userTypeEnum.HOO
      || this.loginUserDetail.BaseUserType == this.userTypeEnum.DOS || this.loginUserDetail.BaseUserType == this.userTypeEnum.HOD
      || this.loginUserDetail.BaseUserType == this.userTypeEnum.DRECADLC || this.loginUserDetail.BaseUserType == this.userTypeEnum.HOOADLC
      || this.loginUserDetail.BaseUserType == this.userTypeEnum.DOSADLC || this.loginUserDetail.BaseUserType == UserTypeEnum.VNDR
      || this.loginUserDetail.BaseUserType == UserTypeEnum.VNDRUSER) {

      if (this.recordId == 0) {
        if (this.loginUserDetail.DepartmentCodes && this.loginUserDetail.DepartmentCodes.split(',').length > 0)
          this.model.DepartmentCode = String(this.loginUserDetail.DepartmentCodes.split(',')[0]);
        if (this.loginUserDetail.DistrictCodes && this.loginUserDetail.DistrictCodes.split(',').length > 0)
          this.model.DistrictCode = String(this.loginUserDetail.DistrictCodes.split(',')[0]);
        if (this.loginUserDetail.OfficeCodes && this.loginUserDetail.OfficeCodes.split(',').length > 0)
          this.model.OfficeCode = String(this.loginUserDetail.OfficeCodes.split(',')[0]);
      }
    }

    if (this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDR || this.loginUserDetail.BaseUserType == this.userTypeEnum.VNDRUSER) {
      VendorControl.setValidators(null);
      this.model.VendorCode = String(this.loginUserDetail.UserCode);
    } else {
      VendorControl.setValidators([Validators.required]);
    }

    VendorControl.updateValueAndValidity();
  }


  onChangeDepartment() {
    this.ddlList.ddlOfficeByLoginUserId = [];
    this.ddlList.ddlOfficeHavingStaffByLoginUserId = [];
    this.GetDistrictByDepartment();
  }

  GetDistrictByDepartment() {
    if (this.model.DepartmentCode) {
      let stateCode = String(StateEnum.Rajasthan);
      this._userService.GetUserDistrictList(stateCode, false, this.model.DepartmentCode.toString()).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlDistrict = <DdlItemModel[]>data.Data;
          if (this.ddlList.ddlDistrict
            && this.ddlList.ddlDistrict.findIndex(x => x.Value == this.model.DistrictCode) == -1) {
            this.model.DistrictCode = undefined;
          } else {
            this.GetOfficeByDistrict();

          }
        }
      },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
    else {
      this.ddlList.ddlDistrict = [];
    }
  }

  GetOfficeByDistrict() {

    if (this.model.DepartmentCode) {
      let districtCode = this.model.DistrictCode ? this.model.DistrictCode : 0;
      this._userService.GetUserOfficeList(this.model.DepartmentCode, districtCode).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlOfficeByLoginUserId = <DdlItemModel[]>data.Data;

          // #CheckSelectedValueExist
          if (this.ddlList.ddlOfficeByLoginUserId
            && this.ddlList.ddlOfficeByLoginUserId.findIndex(x => x.Value == this.model.OfficeCode) == -1) {
            this.model.OfficeCode = undefined;
          }
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    } else {
      this.ddlList.ddlOfficeByLoginUserId = [];
    }
  }

  GetOfficeHavingStaffByDistrict() {

    if (this.model.DepartmentCode) {
      let districtCode = this.model.DistrictCode ? this.model.DistrictCode : 0;
      this._userService.GetUserOfficeHavingStaffList(this.model.DepartmentCode, districtCode).subscribe(data => {
        if (data.IsSuccess) {
          this.ddlList.ddlOfficeHavingStaffByLoginUserId = <DdlItemModel[]>data.Data;

          // #CheckSelectedValueExist
          if (this.ddlList.ddlOfficeHavingStaffByLoginUserId
            && this.ddlList.ddlOfficeHavingStaffByLoginUserId.findIndex(x => x.Value == this.model.OfficeCode) == -1) {
            this.model.OfficeCode = undefined;
          }
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    } else {
      this.ddlList.ddlOfficeHavingStaffByLoginUserId = [];
    }
  }

}
