import { Component, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material";
import { AppComponent } from "src/app/app.component";
import { DataSourceEnum, RowAndColumnValueEnum } from "src/app/shared/enum/statistical-report.enum";
import { AppSetting } from "src/app/shared/model/appsetting.model";
import { DdlItemModel, DDLModel } from "src/app/shared/model/commonddl.model";
import { SearchCriteriaModel, StatisticalFilterModel } from "src/app/shared/model/report/search-criteria.model";
import { DynamicColumn, StatisticalReportModel } from "src/app/shared/model/report/statistical-report.model";
import { AlertService } from "src/app/shared/service/alert.service";
import { CommonService } from "src/app/shared/service/common.service";
import { GlobalReportService } from "src/app/shared/service/report/global-report.service";
import { StatisticalReportService } from "src/app/shared/service/report/statistical-report.service";
import { ReportSearchCriteriaDialogComponent } from "../report-search-criteria-dialog/report-search-criteria-dialog.component";

@Component({
  selector: "app-statistical-reports",
  templateUrl: "./statistical-reports.component.html",
  styleUrls: ["./statistical-reports.component.scss"],
})

export class StatisticalReportsComponent implements OnInit {

  //#region <Variables>
  searchModel = new StatisticalFilterModel();
  advSearchModel = new SearchCriteriaModel();
  ddlList = new DDLModel();
  resultDataModel: any;
  dynamicColumn: DynamicColumn[] = [];
  public columnsToDisplay: string[] = [];
  public columnSum: any;
  ddlRowValue: DdlItemModel[] = [];
  ddlColumnValue: DdlItemModel[] = [];
  //#endregion <Variables>

  //#region <Constructor>
  constructor(
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _statisticalReportService: StatisticalReportService,
    private _dialog: MatDialog,
    private readonly _alertService: AlertService,
    private _globalReportService: GlobalReportService
  ) {
    this.appComponnet.setPageLayout("Statistical Reports :", "", "", "");
    this._globalReportService.GetData().subscribe((result: any) => {
      this.advSearchModel = result;
    })
  }

  //#endregion <Constructor>

  //#region <Method>
  ngOnInit() {
    this.GetDDLList();
    // this.GetStatisticalReportData();
  }

  GetStatisticalReportData() {

    this.resultDataModel = null;
    this.dynamicColumn = [];
    if (this._commonService.GetLocalStorage("GlobalReportSearch")) {
      this.advSearchModel = JSON.parse(this._commonService.GetLocalStorage("GlobalReportSearch"));

      this.searchModel.AdminDepartments = String(this.advSearchModel.AdminDepartments || '');
      this.searchModel.Departments = String(this.advSearchModel.Departments || '');
      this.searchModel.Districts = String(this.advSearchModel.Districts || '');
      this.searchModel.Offices = String(this.advSearchModel.Offices || '');
      this.searchModel.Vendors = String(this.advSearchModel.Vendors || '');
      this.searchModel.Projects = String(this.advSearchModel.Projects || '');
      this.searchModel.ProjectsOIC = String(this.advSearchModel.ProjectsOIC || '');
      this.searchModel.UserTypes = String(this.advSearchModel.UserTypes || '');
      this.searchModel.Users = String(this.advSearchModel.Users || '');
      this.searchModel.WorkOrderCodes = String(this.advSearchModel.WorkOrderCodes || '');
      this.searchModel.CommunicationTypes = String(this.advSearchModel.CommunicationTypes || '');
      this.searchModel.Status = String(this.advSearchModel.Status || '');
      this.searchModel.FromDate = String(this.advSearchModel.FromDate || '');
      this.searchModel.ToDate = String(this.advSearchModel.ToDate || '');
    }


    this._statisticalReportService.GetStatisticalReportData(this.searchModel).subscribe(
      (data) => {

        if (data.IsSuccess) {
          this.columnsToDisplay.length = 0;
          this.dynamicColumn.length = 0;
          var dataModel = <StatisticalReportModel>data.Data;
          this.resultDataModel = JSON.parse(dataModel.JsonData);
          var columnDefs = Object.keys(JSON.parse(dataModel.JsonData)[0]);
          columnDefs.forEach((element) => {
            var item = new DynamicColumn(element.replace(/_/g, ' '), element);
            this.dynamicColumn.push(item);
            this.columnsToDisplay.push(
              item.headerName
            );
          });
          this.GetSumOfColumns();
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.StatisticalReportDDLKey).subscribe(
      (data) => {
        if (data.IsSuccess) {
          this.ddlList = <DDLModel>data.Data;
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );
  }

  OpenDialog(Id) {
    const _dialogRef = this._dialog.open(ReportSearchCriteriaDialogComponent, {
      width: "1000px",
      disableClose: true,
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
      }
    });
  }


  onBtPrint() {
    const api = this.resultDataModel;
    //  setPrinterFriendly(api);

    print();

  }

  public GoToDrillDownComponent(row: string, column: string) {

  }

  private GetSumOfColumns() {
    let tempObject = {};
    for (let i = 1; i < this.columnsToDisplay.length; i++) {
      tempObject = { ...tempObject, [this.columnsToDisplay[i]]: this.resultDataModel.reduce((sum, current) => current[this.columnsToDisplay[i]] + sum, 0) }
    }

    this.columnSum = tempObject;
  }


  GetRowAndCols() {
    let dataSourceId = this.searchModel.DataSource;

    if (!dataSourceId) {
      this.ddlRowValue = this.ddlColumnValue = [];
      return;
    }

    let allRows = this.ddlList.ddlRowValueEnum;
    let allColumns = this.ddlList.ddlColumnValue;

    if (dataSourceId == DataSourceEnum.Project) {
      this.ddlRowValue = allRows.filter(x => x.Value == RowAndColumnValueEnum.OIC || x.Value == RowAndColumnValueEnum.Office || x.Value == RowAndColumnValueEnum.Department || x.Value == RowAndColumnValueEnum.Vendor);
      this.ddlColumnValue = allColumns.filter(x => x.Value == RowAndColumnValueEnum.OIC || x.Value == RowAndColumnValueEnum.District || x.Value == RowAndColumnValueEnum.Department || x.Value == RowAndColumnValueEnum.Vendor);
    }
    else if (dataSourceId == DataSourceEnum.WorkOrderNumber) {
      this.ddlRowValue = allRows.filter(x => x.Value == RowAndColumnValueEnum.OIC || x.Value == RowAndColumnValueEnum.Office || x.Value == RowAndColumnValueEnum.Department || x.Value == RowAndColumnValueEnum.Vendor);
      this.ddlColumnValue = allColumns.filter(x => x.Value == RowAndColumnValueEnum.OIC || x.Value == RowAndColumnValueEnum.Vendor || x.Value == RowAndColumnValueEnum.District || x.Value == RowAndColumnValueEnum.Department);
    }
    else if (dataSourceId == DataSourceEnum.CommunicationData) {
      this.ddlRowValue = allRows.filter(x => x.Value == RowAndColumnValueEnum.OIC || x.Value == RowAndColumnValueEnum.Office || x.Value == RowAndColumnValueEnum.Department || x.Value == RowAndColumnValueEnum.Vendor || x.Value == RowAndColumnValueEnum.District || x.Value == RowAndColumnValueEnum.Communication || x.Value == RowAndColumnValueEnum.CommunicationStatus);
      this.ddlColumnValue = allColumns.filter(x => x.Value == RowAndColumnValueEnum.CommunicationStatus || x.Value == RowAndColumnValueEnum.Vendor || x.Value == RowAndColumnValueEnum.District || x.Value == RowAndColumnValueEnum.Department || x.Value == RowAndColumnValueEnum.Office);
    }
    else if (dataSourceId == DataSourceEnum.DeptUserData) {
      this.ddlRowValue = allRows.filter(x => x.Value == RowAndColumnValueEnum.Department || x.Value == RowAndColumnValueEnum.District);
      this.ddlColumnValue = allColumns.filter(x => x.Value == RowAndColumnValueEnum.Department || x.Value == RowAndColumnValueEnum.District);
    }
    else if (dataSourceId == DataSourceEnum.WorkOrderAmount) {
      this.ddlRowValue = allRows.filter(x => x.Value == RowAndColumnValueEnum.Department || x.Value == RowAndColumnValueEnum.Office || x.Value == RowAndColumnValueEnum.Vendor);
      this.ddlColumnValue = allColumns.filter(x => x.Value == RowAndColumnValueEnum.Department || x.Value == RowAndColumnValueEnum.District || x.Value == RowAndColumnValueEnum.OIC || x.Value == RowAndColumnValueEnum.Vendor);
    }
    else if (dataSourceId == DataSourceEnum.Office) {
      this.ddlRowValue = allRows.filter(x => x.Value == RowAndColumnValueEnum.Department || x.Value == RowAndColumnValueEnum.District);
      this.ddlColumnValue = allColumns.filter(x => x.Value == RowAndColumnValueEnum.Department || x.Value == RowAndColumnValueEnum.District);
    }
  }

  //#endregion <Method>
}
