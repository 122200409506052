import { UserViewModel } from './../../../shared/model/user.model';
import { DDLModel } from './../../../shared/model/commonddl.model';
import { isNullOrUndefined } from 'util';
import { ProjectGroupSearchModel } from './../../../shared/model/master/project-group.model';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatDialog, MatTableDataSource } from '@angular/material';
import { Router } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { IndexModel, SearchModel, PermissionModel, GlobalFilterModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel } from 'src/app/shared/model/commonddl.model';
import { ProjectGroupModel } from 'src/app/shared/model/master/project-group.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ProjectGroupService } from 'src/app/shared/service/master/project-group.service';

@Component({
  selector: 'app-project-group',
  templateUrl: './project-group.component.html',
  styleUrls: ['./project-group.component.scss']
})

export class ProjectGroupComponent implements OnInit {

  model: ProjectGroupModel[];
  dataSource: any;
  totalRecords: number;
  defaultValue: SearchModel;
  //indexModel = this._commonService.modelSetGet(new IndexModel()) as IndexModel;
  searchModel = this._commonService.modelSetGet(new ProjectGroupSearchModel()) as ProjectGroupSearchModel;
  isShow = true;
  ddlList = new DDLModel();
  loginBaseUserType: string;
  loginUserDetail: UserViewModel;

  displayedColumns: string[] = ["index", "ProjectGroupTitle", "ProjectGroupTitleHindi", "OfficeName", "IsApplicabletoDepartment", "CreatedBy", "ModifiedBy", "IsActive", "Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "ProjectGroupTitle", Text: "Project Group" },
    { Value: "ProjectGroupTitleHindi", Text: "Project Group(Hindi)" },
  ];

  searchColumns: ColumnHeaderModel[] = [
    { Value: "ProjectGroupTitle", Text: "Project Group" },
    { Value: "ProjectGroupTitleHindi", Text: "Project Group(Hindi)" },
    { Value: "DepartmentTitle", Text: "Department" },
    { Value: "OfficeName", Text: "Office" },
    { Value: "DistrictTitle", Text: "District" },
    // { Value: "IsApplicabletoDepartment", Text: "Is Applicable to Department" }
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  Permission: PermissionModel = this._authService.GetPagePermission("/master/project-group", "/master/project-group/add", "/master/project-group/detail", "/master/project-group/update", "/master/project-group/delete");

  constructor(private readonly _appComponent: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _projectGroupService: ProjectGroupService,
    private readonly _router: Router,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService
  ) {
    this.loginUserDetail = this._authService.GetCurrentUserDetail().UserViewModel;
    this.loginBaseUserType = this._authService.BaseUserType;
    this.GetDDLList();
    if (this.Permission.AddPageAccess)
      this._appComponent.setPageLayout("Project Group :", "add", "Create", "/master/project-group/add");
    else
      this._appComponent.setPageLayout("Project Group :");
  }

  ngOnInit() {

    this.searchModel.indexModel.OrderBy = "CreatedOn";
    this.searchModel.indexModel.OrderByAsc = AppSetting.orderByDsc;
    this.getList();
  }

  getList() {
    this.searchModel.indexModel.PageSize = 101;
    //this._projectGroupService.GetList(this._commonService.modelSetGet(this.indexModel, true)).subscribe(data => {
    this._projectGroupService.GetProjectGroupBySearch(this._commonService.modelSetGet(this.searchModel, true)).subscribe(data => {
      if (data.IsSuccess) {
        this.model = <ProjectGroupModel[]>data.Data.Data;
        this.dataSource = new MatTableDataSource<ProjectGroupModel>(this.model);
        this.totalRecords = data.Data.TotalRecords;
        if (!this.searchModel.indexModel.IsPostBack) {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        }
      } else {
        this._alertService.error(data.Message);
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmDeleted });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._projectGroupService.ChangeDeleteStatus(id).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this._commonService.ScrollingTop();
            this.getList();
          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
    });
  }

  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, { width: "50%", data: GlobalMessagesModel.ConfirmStatusChanged });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._projectGroupService.ChangeActiveStatus(id).subscribe(data => {
          if (data.IsSuccess) {
            this._alertService.success(data.Message);
            this.getList();
          }
          else {
            this._alertService.error(data.Message);
          }
        }, error => {
          this._alertService.error(error.message);
        });
      }
    });
  }

  sortData(event) {
    this.searchModel.indexModel.OrderBy = event.active;
    this.searchModel.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.searchModel.indexModel.Page = event.pageIndex + 1;
    this.searchModel.indexModel.PageSize = event.pageSize;
    this.searchModel.indexModel.IsPostBack = true;
    this.getList();
  }

  SearchByKeyword(searchValue: GlobalFilterModel) {

    this.searchModel.indexModel.Search = searchValue.search;
    this.searchModel.indexModel.AdvanceSearchModel = { ActiveStatus: searchValue.ActiveStatus }

    this.getList();
  }

  ClearSearch() {
    this.searchModel.indexModel = new IndexModel();
    this.searchModel.indexModel.OrderBy = "CreatedOn";
    this.searchModel.indexModel.OrderByAsc = AppSetting.orderByDsc;
    this.getList();
  }

  toggleDisplay() {
    this.isShow = !this.isShow;
  }

  GetOfficeByDepartment() {
    if (!isNullOrUndefined(this.searchModel.DepartmentCode)) {
      this.ddlList.ddlOfficeByLoginUserId = [];
      this.searchModel.DistrictCode = this.searchModel.DistrictCode != undefined && Number(this.searchModel.DistrictCode) > 0 ? this.searchModel.DistrictCode : (this.loginUserDetail != undefined && this.loginUserDetail.DistrictCode != undefined && this.loginUserDetail.DistrictCode.length > 0 ? this.loginUserDetail.DistrictCode[0] : undefined);
      this._commonService.GetDDL(AppSetting.ddlOfficeByLoginUserId, this.searchModel.DepartmentCode, this.searchModel.DistrictCode).subscribe((data) => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlOfficeByLoginUserId = ddl.ddlOfficeByLoginUserId;
        }
      }, (error) => {
        this._alertService.error(error.message);
      });
    } else {
      this.ddlList.ddlOfficeByLoginUserId = null;
    }
  }


  GetDistrictByDepartment() {
    if (!isNullOrUndefined(this.searchModel.DepartmentCode)) {
      this.ddlList.ddlDistrict = [];
      this._commonService.GetDDL(AppSetting.ddlDistrict, this.searchModel.DepartmentCode).subscribe(data => {
        if (data.IsSuccess) {
          var ddl = <DDLModel>data.Data;
          this.ddlList.ddlDistrict = ddl.ddlDistrict;
        }
      }, error => {
        this._alertService.error(error.message);
      });
    }
    else {
      this.ddlList.ddlDistrict = null;
    }
  }

  GetDDLList() {
    this._commonService.GetAllDDL(AppSetting.ProjectAdvSearchDropdownKeys, 0, this.loginUserDetail.UserCode).subscribe(data => {
      if (data.IsSuccess) {
        this.ddlList = <DDLModel>data.Data;
      }
    }, error => {
      this._alertService.error(error.message);
    });
  }

  Reset() {
    this.searchModel = new ProjectGroupSearchModel();
    this.GetDDLList();
    this.getList();
  }

}
