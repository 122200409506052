import { IndexModel } from "./common.model";

export class ProjectModel {
    ProjectCode: number;
    DepartmentCode: number | string = "0";
    DepartmentName: string;
    ProjectName: string;
    PIC_Name: string;
    PIC_Email: string;
    PIC_Designation: string;
    PIC_MobileNo: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    CreatedOn: Date | null;
    ModifiedBy: number | null;
    ModifiedOn: Date | null;
    UserName: string;
    VendorCompany: string;
    CreatedByName: string;
    CreatedByUserType: string;
    IsAssigned: boolean;
    IsAccepted: boolean;
    AcceptedBy: number | null;
    AcceptedByName: string;
    AssignedProjectOfficerName: string;
    SSOID: string;
    Email: string;
    Mobile: string;
    DistrictCode: number | string = "0";
    OfficeCode: number | string = "0";
    ProjectGroupCode: number | string;
    ProjectCategoryCode: number | string;
    ProjectSubCategoryCode: number | string | null;
    TendorID: string;
    TendorRefNumber: string;
    SubProjectDescription: string;
    ApproachAndObjective: string;
    OutCome: string;
    Audience: string;
    TechnologyAndInfra: string;
    Outlook: string;
    ImpactZone: string;
    AwardPhoto: string;
    Awards: string;
    Partners: string;
    Logo:string;
    URL: string;
    UploadOnDoitcWebsite: number;
    HelplineNumber: string;
    //LogoFileName: string;
    TendorRefDate: string | null;
    FirmRefNumber: string;
    FirmRefDate: string | null;
    Remarks: string;
    E_FileNo: string;
    E_FileDate: string | null;
    F_DIDNo: string;
    F_DIDDate: string | null;
    ExpectedEndDate: string | null;
    EstimatedCost: string;
    TotalExpenditureTillDate: string | null; 
    ProjectMilestone: string;
    Attchement: string;
    Description: string;
    DistrictTitle: string;
    OfficeName: string;
    GroupTitle: string;
    Category: string;
    SubCategory: string;
    DocumentList: ProjectDocumentModel[] = [];
    DeletedDocumentCodes: string;
    IsOICAssigned: number;
    IsDraft: boolean;
    IsFinalSubmit: boolean;
    UserCode: number | string;
    PIC_DesignationName: string;
    VendorCode: number | string;
    ContactPersonName: string;
    ProjectGroup: string;
    UBNNumber: string;
    UBNDate: string | null;
    IsApplicableToAllVendor: boolean;
    ProjectStartDate: string | null;

    constructor() {
        this.ProjectCode = 0;
        this.IsApplicableToAllVendor = false;
    }
}

export class ProjectDocumentModel {
    DocumentCode: number;
    WorkOrderCode: number;
    DocName: string;
    DocPath: string;
    Extension: string;
}

export class ProjectViewModel {
    ProjectCode: number;
    DepartmentCode: number;
    DepartmentName: string;
    ProjectName: string;
    PIC_Name: string;
    PIC_Email: string;
    PIC_Designation: string;
    PIC_MobileNo: string;
    IsActive: boolean;
    IsDeleted: boolean;
    CreatedBy: number | null;
    ModifiedBy: number | null;
    CreatedByName: string;
    CreatedOn: string | null;

    ModifiedOn: string | null;
    VendorCompany: string;
    IsAssigned: boolean | null;
    IsAccepted: boolean;
    AcceptedBy: number | null;
    AcceptedByName: string;
    DistrictCode: number | null;
    OfficeCode: number | null;
    ProjectGroupCode: number | null;
    ProjectCategoryCode: number | null;
    ProjectSubCategoryCode: number | null;
    TendorID: string;
    TendorRefNumber: string;
    TendorRefDate: string | null;
    SubProjectDescription: string;
    ApproachAndObjective: string;
    OutCome: string;
    Audience: string;
    TechnologyAndInfra: string;
    Outlook: string;
    ImpactZone: string;
    AwardPhoto: string;
    Partners: string;
    HelplineNo: string;
    //LogoFileName: string;
    Logo:string;
    URL: string;
    UploadOnDoitcWebsite: number;
    FirmRefNumber: string;
    FirmRefDate: string | null;
    Remarks: string;
    E_FileNo: string;
    E_FileDate: string | null;
    F_DIDNo: string;
    F_DIDDate: string | null;
    ExpectedEndDate: string | null;
    EstimatedCost : string;
    TotalExpenditureTillDate: string | null; 
    ProjectMilestone: string;
    Attchement: string;
    Description: string;

    DistrictTitle: string;
    OfficeName: string;
    GroupTitle: string;
    Category: string;
    SubCategory: string;
    DocumentList: ProjectDocumentModel[];
    DeletedDocumentCodes: string;
    IsOICAssigned: number;
    AssignedProjectOfficerName: string;
    SSOID: string;
    Email: string;
    Mobile: string;
    IsDraft: boolean;
    IsFinalSubmit: boolean;
    AssignedProjectUserDetail: AssignedProjectUserDetailViewModel[];
    DepartmentNameHindi: string;
    AssignToUserCode: string | number | null;
    AssignDate: string | null;
    AssignByUserCode: string | number | null;
    AssignByUserName: string;
    OIC_Name: string;
    OIC_Designation: string;
    ContactPersonName: string;
    UBNNumber: string;
    UBNDate: string | null;
    ProjectStartDate: string | null;
}

export class AssignedProjectUserDetailViewModel {
    UserProjectLookupCode: number;
    UserCode: number | null;
    ProjectCode: number | null;
    AssignDate: string | Date;
    IsActive: boolean;
    AssignByUserCode: number | null;
    AssignOn: Date | null;
    ToAssignDate: string | null;
    ProjectName: string;
    AssignToUserName: string;
    AssignToBaseUserTypeCode: number;
    AssignToBaseUserType: string;
    AssignByUserName: string;
}

export class ProjectSearchModel {
    constructor() {
        this.indexModel = new IndexModel();
        this.DepartmentCode = 0;
        this.DistrictCode = 0;
        this.OfficeCode = 0;
        this.ProjectCategoryCode = 0;
        this.ProjectSubCategoryCode = 0;
        this.IsActive = -1;
        this.IsUsedForCommunicate = -1;
        this.IsOICAssigned = "-1";
    }
    LoginUserCode: number;
    DepartmentCode: number | string;
    DistrictCode: number | string;
    OfficeCode: number | string;
    ProjectGroupCode: number | string;
    ProjectCategoryCode: number | string;
    ProjectSubCategoryCode: number | string;
    TendorID: string;
    TendorRefDate: string;
    IsActive: number;
    IsUsedForCommunicate: number;
    FromDate: string;
    ToDate: string;
    //AcceptRejectFromDate: string;
    FromAssignedDate: string;
    ToAssignedDate: string;
    VendorCode: number | string;
    IsOICAssigned: number | string;
    SearchText: string;
    UBNDate: string;
    indexModel: IndexModel;
}

export class ProjectClearModel {
    constructor() {

        this.DepartmentCode = 0;
        this.VendorCode = 0;
    }
    DepartmentCode: number | string;
    VendorCode: number | string;
}

// export class ProjectSearchModel {
//     constructor() {
//         this.indexModel = new IndexModel();
//     }
//     LoginUserCode: number;
//     DepartmentCode: number;
//     DistrictCode: number|string| null;
//     OfficeCode: number;
//     ProjectCategoryCode: number | null;
//     ProjectSubCategoryCode: number | null;
//     TendorID: string;
//     TendorRefDate: string | null;
//     ActiveView: number = 1;
//     IsUsedForCommunicate: number = -1;
//     fromDate: string | null;
//     toDate: string | null;
//     indexModel: IndexModel;
// }
