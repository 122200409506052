import { PermissionModel } from "./../../shared/model/common.model";
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSort, MatPaginator, MatDialogConfig } from "@angular/material";
import { ColumnHeaderModel, DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { ConfirmationDialogComponent } from 'src/app/confirmation-dialog/confirmation-dialog.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { CommunicationModel } from 'src/app/shared/model/communication.model';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { isNullOrUndefined } from 'util';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { UserTypeEnum, StatusEnum, CommunicationInitiateByEnum } from 'src/app/shared/enum/fixed-values.enum';
import { ActionDialogComponent } from './action-dialog/action-dialog.component';
import { TitleCasePipe } from '@angular/common';
import { CommunicationTypeService } from 'src/app/shared/service/master/communication-type.service';
import { CommunicationUserTypeViewModel } from 'src/app/shared/model/master/communication-type.model';
import { CommunicationReOpenDialogComponent } from "./communication-re-open-dialog/communication-re-open-dialog.component";
import { GlobalCommunicationSearchComponent } from 'src/app/global-communication-search/global-communication-search.component';
import { GlobalFilterSearchModel } from 'src/app/shared/model/Global-communication-search.model';
import { UserManualDialogComponent } from "src/app/header/user-manual-dialog/user-manual-dialog.component";
import { B2GUserManualService } from "src/app/shared/service/master/b2-guser-manual.service";
import { UserService } from "src/app/shared/service/user.service";
import { ClearCommunicationPopUpComponent } from "./clear-communication-pop-up/clear-communication-pop-up.component";

@Component({
  selector: 'app-communication',
  templateUrl: './communication.component.html',
  styleUrls: ['./communication.component.scss'],
  providers: [TitleCasePipe]
})

export class CommunicationComponent implements OnInit {
  model: CommunicationModel[] = [];
  isShowFilter = false;
  loginUserCode: number;
  loginBaseUserType: string;
  userTypeEnum = UserTypeEnum;
  communicationInitiateByEnum = CommunicationInitiateByEnum;
  dataSource: any;
  totalRecords: number;
  ddlStatus: DdlItemModel[] = [];
  status: string = '';
  search: string = '';
  statusPara: string;
  communicationTypeCode: string;
  communicationTypeEnum: string = "";
  communicationTypeTitle: string = "";
  moduleCode: string;
  communicationStatusEnum = StatusEnum;
  IsAddEdit = false;
  public isFormVisible: boolean = false;
  Permission: PermissionModel = this._authService.GetPagePermission("/communications-list", "/communications-list/create", "/communications-list/detail", "/communications-list/update", "/communications-list/delete");
  isShow = true;
  displayedColumns: string[] = ["index", "Subject", "DepartmentName", "OfficeName", "CommunicationType", "RefNo", "CurrentStatus", "LastActionDate", "IsActive", "Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [

    { Value: "Subject", Text: "Subject" },
    { Value: "DepartmentName", Text: "Department" },
    { Value: "OfficeName", Text: "Office" },
    { Value: "CommunicationType", Text: "Communication Type" },
    { Value: "RefNo", Text: "References No." },
    { Value: "CurrentStatus", Text: "Status" },

  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  globalSearchModel = this._commonService.modelSetGet(new GlobalFilterSearchModel()) as GlobalFilterSearchModel;
  isShowUserManual = true;
  constructor(
    private readonly appComponnet: AppComponent,
    private readonly _alertService: AlertService,
    private readonly _router: Router,
    private _route: ActivatedRoute,
    private readonly _communicationService: CommunicationService,
    private readonly _dialog: MatDialog,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService, public titleCasePipe: TitleCasePipe,
    private readonly _communicationTypeService: CommunicationTypeService,
    private readonly _b2gUserManualMasterService: B2GUserManualService,
    private readonly _userService: UserService,
    private readonly _dialogRef: MatDialog,

  ) {
    this.globalSearchModel.indexModel.OrderBy = "CreatedOn";
    this.globalSearchModel.indexModel.OrderByAsc = 0;
    this.model = [];
    if (!isNullOrUndefined(this._route.snapshot.queryParamMap.get("status"))) {
      this.status = this._route.snapshot.queryParamMap.get("status");
      this.globalSearchModel.Status = this.status;
    }

    if (!isNullOrUndefined(this._route.snapshot.params.typeEnum)) {
      this.communicationTypeEnum = this._route.snapshot.params.typeEnum;
      this.communicationTypeTitle = this.titleCasePipe.transform(this.communicationTypeEnum.split("_")[1]);
    }

    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;

    if (this.loginBaseUserType != this.userTypeEnum.VNDR && this.loginBaseUserType != this.userTypeEnum.VNDRUSER && this.status == this.communicationStatusEnum.SENT) {
      this.statusPara = this.communicationStatusEnum.RECEIVED;
    }
    else {
      this.statusPara = this.status;
    }
    this.setIsFormVisible();
    //  this.globalSearchModel.indexModel

  }



  ngOnInit() {

    this._route.paramMap.subscribe((params: ParamMap) => {
      this.model = [];
      if (!isNullOrUndefined(this._route.snapshot.params.typeEnum)) {
        this.communicationTypeEnum = this._route.snapshot.params.typeEnum;
        this.communicationTypeTitle = this.titleCasePipe.transform(this.communicationTypeEnum.split("_")[1]);
      }
      if (!isNullOrUndefined(this._route.snapshot.queryParamMap.get("status"))) {
        this.status = this._route.snapshot.queryParamMap.get("status");
        this.globalSearchModel.Status = this.status;
      }

      this.checkAddEditRecord();
      this.bindStatus();
      this.setGlobalFilterCriteria();

      this.GetGlobalSearchData();
      this.GetCountForUserManualUrl();
    });

  }

  GetGlobalSearchData() {

    this.globalSearchModel.CommunicationTypeEnum = this.communicationTypeEnum;
    this.globalSearchModel = this._commonService.modelSetGet(this.globalSearchModel, true)
    this._communicationService.GetAllGlobalListSearch(this.globalSearchModel).subscribe(
      (data) => {

        if (data.IsSuccess) {

          this.model = <CommunicationModel[]>data.Data.Data ? data.Data.Data : [];

          if (this.model.length > 0) {
            this.communicationTypeTitle = this.model[0].CommunicationType;
            // this.setLayout();
          }

          this.model.forEach(element => {
            if (element.CurrentStatus != this.communicationStatusEnum.SENT) {
              element.IsView_BtnActionHistory = true;
            }
            if ((element.CurrentStatus == this.communicationStatusEnum.RETURN && element.IsCreatedByVendor == 1) || element.CurrentStatus == this.communicationStatusEnum.SENT) {
              element.IsEditDelete_BtnActionHistory = true;
            }

            if (element.CurrentStatus == this.communicationStatusEnum.DISPOSED && (this.loginBaseUserType == this.userTypeEnum.VNDR || this.loginBaseUserType == this.userTypeEnum.VNDRUSER)) {
              element.IsReopen_BtnAction = true;
            }
            else {
              element.IsReopen_BtnAction = false;
            }

            if (this.loginBaseUserType == this.userTypeEnum.VNDR || this.loginBaseUserType == this.userTypeEnum.VNDRUSER) {
              element.IsView_BtnAction = false;
            }
            else if (element.CurrentStatus == this.communicationStatusEnum.RECEIVED) {
              element.IsView_BtnAction = true;
            }
          });

          this.dataSource = new MatTableDataSource<CommunicationModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.globalSearchModel.indexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }

        } else {
          this._alertService.error(data.Message);
        }
      },
      (error) => {
        this._alertService.error(error.message);
      }
    );

    // this._commonService.RemoveLocalStorage("IsGlobalSearch");
    // this._commonService.RemoveLocalStorage("GlobalCommunicationFilterSearch");
  }


  setGlobalFilterCriteria() {

    if (this._commonService.GetLocalStorage("IsGlobalSearch") != null && JSON.parse(this._commonService.GetLocalStorage("IsGlobalSearch")) == 1 && this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch")) {
      this.globalSearchModel = JSON.parse(this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch")) as GlobalFilterSearchModel;
      if (!isNullOrUndefined(this._route.snapshot.params.typeEnum)) {
        this.communicationTypeEnum = this._route.snapshot.params.typeEnum;
        this.communicationTypeTitle = this.titleCasePipe.transform(this.communicationTypeEnum.split("_")[1]);
      }
      if (!isNullOrUndefined(this._route.snapshot.queryParamMap.get("status"))) {
        this.status = this._route.snapshot.queryParamMap.get("status");
        this.globalSearchModel.Status = this.status;
      }


    }
    else {
      this.globalSearchModel = this._commonService.modelSetGet(new GlobalFilterSearchModel())
      if (!isNullOrUndefined(this._route.snapshot.params.typeEnum)) {
        this.communicationTypeEnum = this._route.snapshot.params.typeEnum;
        this.communicationTypeTitle = this.titleCasePipe.transform(this.communicationTypeEnum.split("_")[1]);
      }
      if (!isNullOrUndefined(this._route.snapshot.queryParamMap.get("status"))) {
        this.status = this._route.snapshot.queryParamMap.get("status");
        this.globalSearchModel.Status = this.status;
      }

    }
  }
  bindStatus() {
    var item4: DdlItemModel = new DdlItemModel();
    item4.Text = this.communicationStatusEnum.RETURN;
    item4.Value = this.communicationStatusEnum.RETURN;
    this.ddlStatus.push(item4);

    var item2: DdlItemModel = new DdlItemModel();
    item2.Text = this.communicationStatusEnum.ACCEPT;
    item2.Value = this.communicationStatusEnum.ACCEPT;
    this.ddlStatus.push(item2);

    var item3: DdlItemModel = new DdlItemModel();
    item3.Text = this.communicationStatusEnum.REJECT;
    item3.Value = this.communicationStatusEnum.REJECT;
    this.ddlStatus.push(item3);

    var item1: DdlItemModel = new DdlItemModel();
    item1.Text = (this.loginBaseUserType == this.userTypeEnum.VNDR) ? this.communicationStatusEnum.SENT : this.communicationStatusEnum.RECEIVED;
    item1.Value = this.communicationStatusEnum.SENT;
    this.ddlStatus.push(item1);
  }


  updateActiveStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      //  width: "50%", 
      data: GlobalMessagesModel.ConfirmStatusChanged
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._communicationService.ChangeActiveStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              if (this._commonService.GetLocalStorage("IsGlobalSearch") != null && JSON.parse(this._commonService.GetLocalStorage("IsGlobalSearch")) == 1 && this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch")) {
                this.GetGlobalSearchData();
              }
              else {

                this.GetGlobalSearchData();
              }
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  updateDeleteStatus(id) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      // width: "50%", 
      data: GlobalMessagesModel.ConfirmDeleted
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this._communicationService.ChangeDeleteStatus(id).subscribe(
          data => {
            if (data.IsSuccess) {
              this._alertService.success(data.Message);
              this._commonService.ScrollingTop();
              if (this._commonService.GetLocalStorage("IsGlobalSearch") != null && JSON.parse(this._commonService.GetLocalStorage("IsGlobalSearch")) == 1 && this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch")) {
                this.GetGlobalSearchData();
              }
              else {

                this.GetGlobalSearchData();
              }
            }
          },
          error => {
            this._alertService.error(error.message);
          }
        );
      }
    });
  }

  sortData(event) {
    this.globalSearchModel.indexModel.OrderBy = event.active;
    this.globalSearchModel.indexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.globalSearchModel.indexModel.IsPostBack = true;

    // if (this._commonService.GetLocalStorage("IsGlobalSearch") != null && JSON.parse(this._commonService.GetLocalStorage("IsGlobalSearch")) == 1 && this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch")) {
    //   this.GetGlobalSearchData();
    // }
    // else {

    this.GetGlobalSearchData();
    //  }
  }

  onPaginateChange(event) {
    this.globalSearchModel.indexModel.Page = event.pageIndex + 1;
    this.globalSearchModel.indexModel.PageSize = event.pageSize;
    this.globalSearchModel.indexModel.IsPostBack = true;
    // if (this._commonService.GetLocalStorage("IsGlobalSearch") != null && JSON.parse(this._commonService.GetLocalStorage("IsGlobalSearch")) == 1 && this._commonService.GetLocalStorage("GlobalCommunicationFilterSearch")) {
    //   this.GetGlobalSearchData();
    // }
    // else {

    this.GetGlobalSearchData();
    // }
  }


  proceedToAction(Id, Status, CanReturn) {
    const _dialogRef = this._dialog.open(ActionDialogComponent, {
      width: "75%",
      height: "90%",
      data: { id: Id, status: Status, canReturn: CanReturn },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.GetGlobalSearchData();
      }
    });
  }

  onClearclick() {
    this.search = '';
    this.status = '';
    this.GetGlobalSearchData();
  }

  clearsearch() {
    this.globalSearchModel = this._commonService.modelSetGet(
      new GlobalFilterSearchModel(),
      false,
    );
    // this.globalSearchModel.indexModel = new IndexModel();
    this._commonService.RemoveLocalStorage("IsGlobalSearch");
    this._commonService.RemoveLocalStorage("GlobalCommunicationFilterSearch");
    this.GetGlobalSearchData();
  }
  openPageManual(value) {
    const dialogRef = this._dialog.open(UserManualDialogComponent, {
      width: "90%",
      data: { MasterType: 0, CommunicationType: this.communicationTypeEnum.split("_")[0], isReturn: value }
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result != null) {
        this.isShowUserManual = result;
      }
    });
  }
  GetCountForUserManualUrl() {

    if (this.communicationTypeEnum != undefined && this.communicationTypeEnum.length > 0) {
      this._b2gUserManualMasterService.GetCountForUserManualUrl(this.communicationTypeEnum.split("_")[0]).subscribe(
        data => {
          if (data.IsSuccess) {

            let Count = <Number>data.Data;

            this.isShowUserManual = Number(Count) > 0 ? true : false;
          }
        },
        error => {
          this._alertService.error(error.message);
        }
      );
    }
  }

  redirecttoCreatePage() {
    switch (this.communicationTypeCode) {
      case "1":
        this._router.navigate(["/communication/emdreturn/add"]);
        break;
      case "2":
        this._router.navigate(["/communication/invoice/add"]);
        break;
      case "1002":
        this._router.navigate(["/communication/complaint/add"]);
        break;
      case "1003":
        this._router.navigate(["/communication/general/add"]);
        break;
      case "1004":
        this._router.navigate(["/communication/suggestion/add"]);
        break;
      default:
        this._router.navigate(["communications-list/add-update-communication-transaction/" + "letter" + "/0"]);
        break;
    }
  }

  setLayout() {

    if (this.communicationTypeTitle != undefined && this.communicationTypeTitle.length > 0) {
      if (this.Permission.AddPageAccess && this.IsAddEdit) {
        this.appComponnet.setPageLayout((this.communicationTypeTitle != undefined ? this.communicationTypeTitle : "") + " Communication :", "add", "Create", "/communications-list/add-update-communication-transaction/" + this.communicationTypeEnum + "/0", false, false);
      }
      else {
        this.appComponnet.setPageLayout((this.communicationTypeTitle != undefined ? this.communicationTypeTitle : "") + " Communication List :", "", "", "", false, false);
      }
    }
    else {
      this.appComponnet.setPageLayout("Communication List :", "", "", "", false, false);
    }
  }
  checkAddEditRecord() {

    if (this.communicationTypeEnum != undefined && this.communicationTypeEnum.length > 0) {
      this._communicationTypeService.GetUserTypeByCommunicationType(this.communicationTypeEnum).subscribe(
        response => {
          if (response.IsSuccess) {

            this.IsAddEdit = false;
            let resItem: CommunicationUserTypeViewModel[] = response.Data;

            let Item: CommunicationUserTypeViewModel = resItem.filter(item => (item.UserType == this.loginBaseUserType) || (item.UserType == this.communicationInitiateByEnum.D2D || item.UserType == this.communicationInitiateByEnum.D2V
              && (this.loginBaseUserType == this.userTypeEnum.HOO || this.loginBaseUserType == this.userTypeEnum.HOD || this.loginBaseUserType == this.userTypeEnum.DOS || this.loginBaseUserType == this.userTypeEnum.DOSADLC || this.loginBaseUserType == this.userTypeEnum.HOOADLC || this.loginBaseUserType == this.userTypeEnum.DRECADLC || this.loginBaseUserType == this.userTypeEnum.DREC))
              || (item.UserType == this.communicationInitiateByEnum.V2D && (this.loginBaseUserType == this.userTypeEnum.VNDRUSER || this.loginBaseUserType == this.userTypeEnum.VNDR)))[0];

            this.communicationTypeTitle = resItem != null ? resItem[0].CommunicationType : this.communicationTypeTitle;

            if (Item != undefined && Item.UserType == this.loginBaseUserType) {
              this.IsAddEdit = true;

            } else if (Item != undefined && (Item.UserType == this.communicationInitiateByEnum.D2D || Item.UserType == this.communicationInitiateByEnum.D2V)
              && (this.loginBaseUserType == this.userTypeEnum.HOO || this.loginBaseUserType == this.userTypeEnum.HOD || this.loginBaseUserType == this.userTypeEnum.DOS || this.loginBaseUserType == this.userTypeEnum.DOSADLC || this.loginBaseUserType == this.userTypeEnum.HOOADLC || this.loginBaseUserType == this.userTypeEnum.DRECADLC || this.loginBaseUserType == this.userTypeEnum.DREC)) {
              this.IsAddEdit = true;
            }

            else if (Item != undefined && Item.UserType == this.userTypeEnum.VNDR && this.loginBaseUserType == this.userTypeEnum.VNDRUSER) {
              this.IsAddEdit = true;
            }

            this.setLayout();

          }

        }, error => { }
      );
    } else {
      this.setLayout();
    }
  }

  ReOpenCommunciation(code, commTypeEmun) {
    const dialogRef = this._dialog.open(ConfirmationDialogComponent,
      {
        //  width: "75%",
        height: "50%",
        data: GlobalMessagesModel.ReopenCommunication
      });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //  this._router.navigate(['/communications-list/add-update-communication-transaction/'+commTypeEmun+'/'+code]);  

        this.openReopnDialogBox(code, commTypeEmun);

      }
    });
  }

  openReopnDialogBox(code, commTypeEmun) {
    const _dialogRef = this._dialog.open(CommunicationReOpenDialogComponent, {
      width: "75%",
      height: "95%",
      data: { Id: code, CommTypeEmun: commTypeEmun },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result: boolean) => {
      if (result) {
        this.GetGlobalSearchData();
      }
    });
  }

  openCommunicationSearchModal() {
    const _dialogRef = this._dialog.open(GlobalCommunicationSearchComponent, {
      width: "75%",
      height: "95%",
      data: { IsShowCommunicationType: false, CommunicationType: this.communicationTypeEnum, IsShowSearchButton: true },
      disableClose: true
    });
    _dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.isShow = !this.isShow;
        this.globalSearchModel = result;
        this.GetGlobalSearchData();
      }
    });

  }

  onSearchData(modelSearch) {
    this.globalSearchModel = modelSearch;
    this.GetGlobalSearchData();
  }

  public openDialog() {

    const dialogRef = this._dialogRef.open(ClearCommunicationPopUpComponent);
  }

  private setIsFormVisible() {
    let userDetail = this._authService.LoggedInUserDetail.UserViewModel;
    if (userDetail.UserType == UserTypeEnum.SADM) {
      this.isFormVisible = true
    }
  }


  //#endregion


}