import { TitleCasePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { AppComponent } from 'src/app/app.component';
import { StatusEnum, UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { AppSetting } from 'src/app/shared/model/appsetting.model';
import { CommunicationSearchPostModel, CommunicationSearchViewModel } from 'src/app/shared/model/change-communication-department-office.model';
import { PermissionModel } from 'src/app/shared/model/common.model';
import { ColumnHeaderModel, DdlItemModel, DDLModel } from 'src/app/shared/model/commonddl.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { CommunicationService } from 'src/app/shared/service/communication.service';
import { UserService } from 'src/app/shared/service/user.service';

@Component({
  selector: 'app-locate-communication',
  templateUrl: './locate-communication.component.html',
  styleUrls: ['./locate-communication.component.scss'],
  providers: [TitleCasePipe]
})
export class LocateCommunicationComponent implements OnInit {

  model: CommunicationSearchViewModel[];
  searchModel = this._commonService.modelSetGet(new CommunicationSearchPostModel()) as CommunicationSearchPostModel;
  loginUserCode: number;
  loginBaseUserType: string;
  userTypeEnum = UserTypeEnum;
  isShowFilter = false;
  dataSource: any;
  totalRecords: number;
  ddlList = new DDLModel();
  communicationStatusEnum = StatusEnum;
  IsAddEdit = false;
  Permission: PermissionModel = this._authService.GetPagePermission("communications/search", "", "communications-list/detail", "", "", "communications/search-detail");

  displayedColumns: string[] = ["index", "DepartmentName", "Vendor", "OfficeName", "CommunicationType", "RefNo", "CurrentStatus", "CreatedOn", "IsActive", "Action"];
  columnsToDisplay: string[] = this.displayedColumns.slice();
  ViewdisplayedColumns: ColumnHeaderModel[] = [
    { Value: "DepartmentName", Text: "Department" },
    { Value: "Vendor", Text: "Vendor" },
    { Value: "OfficeName", Text: "Office" },
    { Value: "CommunicationType", Text: "Communication Type" },
    { Value: "RefNo", Text: "References No." },
    { Value: "CurrentStatus", Text: "Status" },
  ];

  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;

  constructor(
    private readonly appComponent: AppComponent,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private readonly _communicationService: CommunicationService,
    public readonly _commonService: CommonService,
    private readonly _authService: AuthenticationService,
    private readonly _userService: UserService,
  ) {
    this.appComponent.setPageLayout("Locate Communication:", "", "", "");
    this.loginUserCode = this._authService.UserCode;
    this.loginBaseUserType = this._authService.BaseUserType;
  }

  ngOnInit() {
    this.GetDepartment();
    this.GetVendorList();
    this.getList();
  }

  getList() {
    this.searchModel.LoginUserCode = this.loginUserCode;
    this._communicationService.GetAllBySearch(this._commonService.modelSetGet(this.searchModel, true)).subscribe(
      data => {
        if (data.IsSuccess) {

          this.model = <CommunicationSearchViewModel[]>data.Data.Data ? data.Data.Data : [];
          this.dataSource = new MatTableDataSource<CommunicationSearchViewModel>(this.model);
          this.totalRecords = data.Data.TotalRecords;
          if (!this.searchModel.IndexModel.IsPostBack) {
            this.dataSource.paginator = this.paginator;
            this.dataSource.sort = this.sort;
          }
        } else {
          this._alertService.error(data.Message);
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  sortData(event) {
    this.searchModel.IndexModel.OrderBy = event.active;
    this.searchModel.IndexModel.OrderByAsc = event.direction == AppSetting.orderByDscAsc ? AppSetting.orderByAsc : AppSetting.orderByDsc;
    this.searchModel.IndexModel.IsPostBack = true;
    this.getList();
  }

  onPaginateChange(event) {
    this.searchModel.IndexModel.Page = event.pageIndex + 1;
    this.searchModel.IndexModel.PageSize = event.pageSize;
    this.searchModel.IndexModel.IsPostBack = true;
    this.getList();
  }

  GetDepartment() {

    this._userService.GetUserDepartmentList().subscribe(
      data => {

        if (data.IsSuccess) {

          this.ddlList.ddlDepartment = <DdlItemModel[]>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }


  GetVendorList() {
    this._commonService.GetAllDDL(AppSetting.VendorLocateCommunicationDdl).subscribe(
      response => {
        if (response.IsSuccess) {
          let data = <DDLModel>response.Data;
          this.ddlList.ddlVendor = data.ddlVendor;

        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  onClearSearch() {
    this.searchModel = new CommunicationSearchPostModel();
    this.getList();
  }


}
