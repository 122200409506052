export const environment = {
    production: false,
    isLiveServer: true,
    LoginByPass: false,
    Password: "123456", // for temporary Login
    encPassword: '67129a5f00cac68507dd52c94c646a93',
    SSOPortalLogin: false,
    //ApiBaseUrl: "https://b2gcmoapi.24livehost.com/",
    ApiBaseUrl: "https://b2gcmoapi.projectstatus.in/",
    ApplicationBaseUrl: "https://B2GCMO.projectstatus.in/",
    // ReadApiBaseUrl: "https://ebusinessfrontwebapi.rajasthan.gov.in/",
    //ReadApiBaseUrl: "https://b2gcmoapi.24livehost.com/",
    ReadApiBaseUrl: "https://b2gcmoapi.projectstatus.in/",
};