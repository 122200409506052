import { Component, Inject, OnInit } from '@angular/core';
import { ActivatedRoute } from "@angular/router";
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { AppComponent } from 'src/app/app.component';
import { AlertService } from 'src/app/shared/service/alert.service';
import { isNullOrUndefined } from 'util';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material';
import { StatusEnum, UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { CommonService } from 'src/app/shared/service/common.service';
import { ActionModel } from '../../../shared/model/action.model';
import { ActionService } from '../../../shared/service/action.service';
import { DatePipe } from '@angular/common';
import { InternalTransferCommunicationHistoryViewModel } from 'src/app/shared/model/internal-transfer-communication-history.model';

@Component({
  selector: 'app-action-history',
  templateUrl: './action-history.component.html',
  styleUrls: ['./action-history.component.scss'],
  providers: [DatePipe]
})

export class ActionHistoryComponent implements OnInit {
  loginUserCode: number;
  loginBaseUserType: string;
  modelList: ActionModel[] = [];
  internalTransHistoryList: InternalTransferCommunicationHistoryViewModel[] = [];
  recordId: number = 0;
  pageFrom: string;
  communicationTypeEnum: string;
  isShowInternalTransfer = false;
  userTypeEnum = UserTypeEnum;
  routerStatusParams: string;
  routerIsCreatedByVendorParams: string;
  result: string;

  constructor(
    private readonly appComponnet: AppComponent,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private _route: ActivatedRoute,
    private readonly _dialog: MatDialog,
    private readonly _actionService: ActionService,
    private readonly _authService: AuthenticationService,

  ) {

    this.loginUserCode = _authService.UserCode;
    this.loginBaseUserType = _authService.BaseUserType;

    if (this.loginBaseUserType == this.userTypeEnum.HOO || this.loginBaseUserType == this.userTypeEnum.DREC || this.loginBaseUserType == this.userTypeEnum.HOD || this.loginBaseUserType == this.userTypeEnum.DOS || this.loginBaseUserType == this.userTypeEnum.HOOADLC || this.loginBaseUserType == this.userTypeEnum.DRECADLC || this.loginBaseUserType == this.userTypeEnum.DOSADLC) {
      this.isShowInternalTransfer = true;
    } else {
      this.isShowInternalTransfer = false;
    }

    if (!isNullOrUndefined(this._route.snapshot.params.id)) {
      //this.recordId = this._route.snapshot.params.id;

      this.recordId = this._route.snapshot.queryParams.id ? this._route.snapshot.queryParams.id : this._route.snapshot.params.id;
    }

    if (!isNullOrUndefined(this._route.snapshot.params.from)) {
      this.pageFrom = this._route.snapshot.params.from;
    }

    if (!isNullOrUndefined(this._route.snapshot.params.status)) {
      this.routerStatusParams = this._route.snapshot.params.status;
    }

    if (!isNullOrUndefined(this._route.snapshot.params.isVendorCommunication)) {
      this.routerIsCreatedByVendorParams = this._route.snapshot.params.isVendorCommunication;
    }

    // if (!isNullOrUndefined(this._route.snapshot.params.Commu)) {
    //   this.pageFrom = this._route.snapshot.params.from;
    // }

    if (!isNullOrUndefined(this._route.snapshot.params.typeEnum)) {
      this.communicationTypeEnum = this._route.snapshot.params.typeEnum;
    }

    this.appComponnet.setPageLayout("Action History :", "keyboard_backspace", "Back To List", "communications-list/" + this.communicationTypeEnum);
    if (this.pageFrom) {

      switch (this.pageFrom.toLocaleLowerCase()) {
        case "inbox":
          this.appComponnet.setPageLayout("Action History :", "keyboard_backspace", "Back To Inbox List", "/inbox");
          break;
        case "outbox":
          this.appComponnet.setPageLayout("Action History :", "keyboard_backspace", "Back To Outbox List", "/outbox");
          break;
        case "dashboard":
          this.appComponnet.setPageLayout("Action History :", "keyboard_backspace", "Back To Dashboard Communication List", "dashboard-communication/" + this.routerStatusParams + "/" + this.routerIsCreatedByVendorParams);
          break;
        default:
          this.appComponnet.setPageLayout("Action History :", "keyboard_backspace", "Back To List", "communications-list/" + this.communicationTypeEnum);
          break;
      }
    }
  }

  ngOnInit() {
    if (this.recordId != 0) {
      this.getActionHistory();
      this.getInternalTransferHistory()
    }
  }

  getActionHistory() {
    this._actionService.GetList(this.recordId, this.loginUserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.modelList = <ActionModel[]>data.Data;

          let hasInProcessRecord = false;
          if (this.loginBaseUserType == UserTypeEnum.VNDR || this.loginBaseUserType == UserTypeEnum.VNDRUSER) {
            if (this.modelList && this.modelList.length > 0) {
              this.modelList = this.modelList.filter(function (item) {
                if (!(item.FromBaseUserType == UserTypeEnum.VNDR || item.FromBaseUserType == UserTypeEnum.VNDRUSER || item.ToBaseUserType == UserTypeEnum.VNDR || item.ToBaseUserType == UserTypeEnum.VNDRUSER)) {
                  if (hasInProcessRecord == false) {
                    item.Status = StatusEnum.INPROCESS;
                    item.ToUser = null;
                    item.ToUserTypeTitle = null;
                    item.Reply = null;
                    hasInProcessRecord = true;
                    return true;
                  }
                  else {
                    return false;
                  }
                }
                else {
                  return true;
                }
              }
              );
            }
          }
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  getInternalTransferHistory() {
    this._actionService.GetInternalTransferHistoryList(this.recordId, this.loginUserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.internalTransHistoryList = <InternalTransferCommunicationHistoryViewModel[]>data.Data;
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  CallPrint(printContent) {
    this._commonService.printHTMLContent(printContent);
  }

}