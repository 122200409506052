import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DynamicFieldConfig } from 'src/app/shared/model/dynamic-field-config.model';

@Component({
  selector: 'app-text-info',
  templateUrl: './text-info.component.html',
  styleUrls: ['./text-info.component.scss']
})
export class TextInfoComponent implements OnInit {

  field: DynamicFieldConfig;
  group: FormGroup;
  fields: DynamicFieldConfig[];
  
  constructor() { }

  ngOnInit() {
  }

}
