import { Component, OnInit, ViewChild } from '@angular/core';
import { CommonService } from 'src/app/shared/service/common.service';
import { PermissionModel } from 'src/app/shared/model/common.model';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { ChiefMinisterProfileService } from 'src/app/shared/service/master/chiefminister-profile.service';
import { ChiefMinisterProfileListResponseModel } from 'src/app/shared/model/master/chief-minister-profile/chief-minister-profile-list-response.model';
import { AppComponent } from 'src/app/app.component';

@Component({
  selector: 'app-chiefminister-profile',
  templateUrl: './chiefminister-profile.component.html',
  styleUrls: ['./chiefminister-profile.component.scss'],
  providers: [ChiefMinisterProfileService]
})
export class ChiefMinisterProfileComponent implements OnInit {

  public dataSource: ChiefMinisterProfileListResponseModel[] = [];
  public displayedColumns: string[] = ['index', 'IsCodeOfConduct', 'CodeOfConductFromDate', 'CodeOfConductToDate', 'Action'];
  public Permission: PermissionModel = this._authService.GetPagePermission("master/chief-minister-profile", "", "", "master/chief-minister-profile/edit");

  constructor(
    public readonly _commonService: CommonService,
    private readonly _appComponnet: AppComponent,
    private readonly _authService: AuthenticationService,
    private readonly _chiefMinisterProfileService: ChiefMinisterProfileService,
  ) {
    this._appComponnet.setPageLayout("Chief Minister Profile :");
  }

  public ngOnInit() {
    this.GetList();
  }

  public PagePrint(printContent) {
    this._commonService.printHTMLContent(printContent, "Chief Minister Profile");
  }

  public onPaginateChange(event) {
    this.GetList();
  }

  public GetList() {
    this._chiefMinisterProfileService.get().subscribe({
      next: response => {
        if (response.IsSuccess == true) {
          this.dataSource = response.Data;
        }
      },
      error: error => {
      }
    });
  }



}
