import { DatePipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { StatusEnum, UserTypeEnum } from 'src/app/shared/enum/fixed-values.enum';
import { ActionModel } from 'src/app/shared/model/action.model';
import { CommonLayoutModel } from 'src/app/shared/model/commonddl.model';
import { InternalTransferCommunicationHistoryViewModel } from 'src/app/shared/model/internal-transfer-communication-history.model';
import { ActionService } from 'src/app/shared/service/action.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';

@Component({
  selector: 'app-action-history-popup',
  templateUrl: './action-history-popup.component.html',
  styleUrls: ['./action-history-popup.component.scss'],
  providers: [DatePipe]
})
export class ActionHistoryPopupComponent implements OnInit {

  loginUserCode: number;
  loginBaseUserType: string;
  recordId: number = 0;
  userTypeEnum = UserTypeEnum;
  viewModel = {
    modelList: [] as ActionModel[],
    internalTransHistoryList: [] as InternalTransferCommunicationHistoryViewModel[],
    isShowInternalTransfer: false,
  };

  constructor(
    private readonly _actionService: ActionService,
    private readonly _authService: AuthenticationService,
    public readonly _commonService: CommonService,
    public readonly _dialogRef: MatDialogRef<ActionHistoryPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {

    if (data) {
      this.recordId = data.communicationCode;
    }
    this.loginUserCode = _authService.UserCode;
    this.loginBaseUserType = _authService.BaseUserType;

    if (this.loginBaseUserType == this.userTypeEnum.HOO || this.loginBaseUserType == this.userTypeEnum.DREC || this.loginBaseUserType == this.userTypeEnum.HOD || this.loginBaseUserType == this.userTypeEnum.DOS || this.loginBaseUserType == this.userTypeEnum.HOOADLC || this.loginBaseUserType == this.userTypeEnum.DRECADLC || this.loginBaseUserType == this.userTypeEnum.DOSADLC) {
      this.viewModel.isShowInternalTransfer = true;
    } else {
      this.viewModel.isShowInternalTransfer = false;
    }
  }

  ngOnInit() {
    if (this.recordId > 0) {
      this.getActionHistory();
      this.getInternalTransferHistory()
    }
  }

  //#region Events
  public onNoClick(): void {
    this._dialogRef.close();
  }
  //#endregion Events

  //#region Private Method
  private getActionHistory() {
    
    this._actionService.GetList(this.recordId, this.loginUserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.viewModel.modelList = <ActionModel[]>data.Data;

          let hasInProcessRecord = false;
          if (this.loginBaseUserType == UserTypeEnum.VNDR || this.loginBaseUserType == UserTypeEnum.VNDRUSER) {
            if (this.viewModel.modelList && this.viewModel.modelList.length > 0) {
              this.viewModel.modelList = this.viewModel.modelList.filter(function (item) {
                if (!(item.FromBaseUserType == UserTypeEnum.VNDR || item.FromBaseUserType == UserTypeEnum.VNDRUSER || item.ToBaseUserType == UserTypeEnum.VNDR || item.ToBaseUserType == UserTypeEnum.VNDRUSER)) {
                  if (hasInProcessRecord == false) {
                    item.Status = StatusEnum.INPROCESS;
                    item.ToUser = null;
                    item.ToUserTypeTitle = null;
                    item.Reply = null;
                    hasInProcessRecord = true;
                    return true;
                  }
                  else {
                    return false;
                  }
                }
                else {
                  return true;
                }
              }
              );
            }
          }
        }
      },
      error => {
      }
    );
  }

  private getInternalTransferHistory() {
    this._actionService.GetInternalTransferHistoryList(this.recordId, this.loginUserCode).subscribe(
      data => {
        if (data.IsSuccess) {
          this.viewModel.internalTransHistoryList = <InternalTransferCommunicationHistoryViewModel[]>data.Data;
        }
      },
      error => {
      }
    );
  }
  //#region Private Method


}
