import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { AppComponent } from 'src/app/app.component';
import { GlobalMessagesModel } from 'src/app/shared/model/common-messages.model';
import { UserViewModel } from 'src/app/shared/model/user.model';
import { VendorModel } from 'src/app/shared/model/vendor.model';
import { AlertService } from 'src/app/shared/service/alert.service';
import { AuthenticationService } from 'src/app/shared/service/authentication.service';
import { CommonService } from 'src/app/shared/service/common.service';
import { VendorService } from 'src/app/shared/service/vendor.service';

@Component({
  selector: 'app-update-vendor-profile-dialog',
  templateUrl: './update-vendor-profile-dialog.component.html',
  styleUrls: ['./update-vendor-profile-dialog.component.scss']
})
export class UpdateVendorProfileDialogComponent implements OnInit {

  constructor(
    private readonly _vendorService: VendorService,
    public readonly _commonService: CommonService,
    private readonly _alertService: AlertService,
    private readonly _authService: AuthenticationService,
    private readonly fb: FormBuilder,
    public _dialogRef: MatDialogRef<UpdateVendorProfileDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.loginUserDetail = this._authService.LoggedInUserDetail.UserViewModel;
    this.RecordId = this.loginUserDetail.VendorCode;
    this.model = new VendorModel();
   }

  public frmGrp: FormGroup;
  public loginUserDetail: UserViewModel;
  public model: VendorModel;
  public RecordId: number = 0;

  ngOnInit() {
    this.formGroupInit();
    if (this.RecordId != 0) {
      this.getDetail();
    }
  }

  public closeDialog(): void {
    this._dialogRef.close();
  }

  public keyPressAlphaNumeric(event) {
    var inp = String.fromCharCode(event.keyCode);
    if (/[a-zA-Z0-9]/.test(inp)) {
      return true;
    } else {
      event.preventDefault();
      return false;
    }
  }

  public saveClick() {
    this.frmGrp.markAllAsTouched();
    if (this.frmGrp.valid) {
      // if (this.RecordId == 0) {
      //   this.model.CreatedBy = this.loginUserDetail.UserCode;
      // }
      // else
      if (this.RecordId != 0) {
        this.model.ModifiedBy = this.model.UserCode;// this.loginUserDetail.UserCode;
        this.model.DocumentList = null;
      }
      if (this.model.AadharNo) {
        this.model.AadharNo = this._commonService.convertText(this.model.AadharNo);
      }
      if (this.model.PANNo) {
        this.model.PANNo = this._commonService.convertText(this.model.PANNo);
      }

      this._vendorService.AddUpdate(this.RecordId, this.model).subscribe(
        data => {
          if (data) {
            if (data.IsSuccess) {
              this._alertService.success(this.RecordId == 0 ? GlobalMessagesModel.saveSuccess : GlobalMessagesModel.updateSuccess);
              this.closeDialog();
            } else {
              this._alertService.error(this.RecordId == 0 ? GlobalMessagesModel.saveError : GlobalMessagesModel.updateError);
              this.closeDialog();
            }
          } else {
            this._alertService.error(GlobalMessagesModel.InternalError, true);
          }
        },
        error => {
          this._alertService.error(GlobalMessagesModel.InternalError, true);
          this.closeDialog();
        }
      );
    }
  }
  
  private getDetail() {
    this._vendorService.Detail(this.RecordId).subscribe(
      data => {
        if (data.IsSuccess) {
          this.model = <VendorModel>data.Data;
          if (this.model.UserCode == this._authService.UserCode) {
            //this._appComponent.setPageLayout("Edit Profile :", "", "Back to Profile", "vendor/bank-account/"+this.RecordId);
          }
          if (this.model.PANNo) {
            this.model.PANNo = this._commonService.convertText(this.model.PANNo, false);
          }
          if (this.model.AadharNo) {
            this.model.AadharNo = this._commonService.convertText(this.model.AadharNo, false);
          }
          this.RecordId = this.model.VendorCode;
          this.model.ContactPersonName = this.model.ContactPersonName;
          this.model.MobileNo1 = this.model.MobileNo1.toString();
          this.model.MobileNo2 = this.model.MobileNo2.toString();
          this.model.Email = this.model.Email.toString();
        }
      },
      error => {
        this._alertService.error(error.message);
      }
    );
  }

  private formGroupInit() {
    const reg = '^[A-Za-z]{5}[0-9]{4}[A-Za-z]$';

    this.frmGrp = this.fb.group({
      ContactPersonName: [null, Validators.required],
      MobileNo1: [null, Validators.required],
      MobileNo2: [null],
      Email: [null, Validators.required],
      PANNo: [null, Validators.compose([Validators.required, Validators.maxLength(10), Validators.pattern(reg)])],
      TANNo: [null, Validators.maxLength(10)],
      AadharNo: [null, Validators.compose([Validators.required, Validators.minLength(12), Validators.maxLength(12)])],
      GSTINNo: [null, Validators.compose([Validators.required, Validators.maxLength(15)])],
      BRNNo: [null, Validators.compose([Validators.minLength(16),Validators.maxLength(16)])],
    });
  }

}
