export class GetUserListForAdditionalPermissionRequestModel {

    public UserTypeCode: number = 0;

    public DepartmentCode: number = 0;

    public DistrictCode: number = 0;

    public OfficeCode: number = 0;

}